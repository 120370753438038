import { object, string } from "yup";
import { COMMON__INPUT__NAME, COMMON__INPUT__CODE } from "../../../translations/constants";

export const CELLAR_SCHEMA = object({
  name: string().required(),
  code: string().required(),
  description: string(),
});

export const CREATE_CELLAR_ERROR_FIELD = {
  name: COMMON__INPUT__NAME,
  code: COMMON__INPUT__CODE,
};

export const CELLAR_EDIT_SCHEMA = object({
  name: string().required(),
  description: string(),
});

export const EDIT_CELLAR_ERROR_FIELD = {
  name: COMMON__INPUT__NAME,
  code: COMMON__INPUT__CODE,
};

export const ADMIN_TABLE_ROWS_CELLARS = 6;
