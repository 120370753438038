import React, { useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { useParams, useSearchParams } from "react-router-dom";

import { PHENEOLOGICAL_STATE_LINE__HEADER__TITLE, PHENOLOGICAL_STATE__CHANGE_ERROR } from "../../../../../../../translations/constants";
import styles from "./phenologicalStateLineComponent.module.css";
import { ADD_ICON } from "../../../../../../../images/icons/addIcon";
import { EDIT_ICON } from "../../../../../../../images/icons/editIcon";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import SelectComponent from "../../../../../../common/atoms/select/SelectComponent";
import { INPUT_VARIANT_SECONDARY } from "../../../../../../common/atoms/input/inputConstants";
import { SELECT_FIELD_CODE } from "../parcelTitleComponent/parcelTitleConstants";
import { FIELD_ID, HTTP_CODE_409 } from "../../../../../../../utils/constants";
import { updateFenStateService } from "../../../../../../../services/commonservices";
import { formatTimestampToDate } from "../../../../../../../utils/utils";
import { showMessage } from "../../../../../../common/NotificationSnack";

let selectedDay;

const PhenologicalStateLineComponent = ({ phenologicalStateData, gridDays, fenStates, getFenState }) => {
  const intl = useIntl();
  const { id } = useParams();

  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [displayFenCard, setDisplayFenCard] = useState(false);
  const [selectedFenState, setSelectedFenState] = useState(undefined);
  const [getFen, setGetFne] = useState(false);

  const [searchParams] = useSearchParams();

  const [isOverflowing, setIsOverflowing] = useState(false);
  const divRef = useRef(null);

  const [showError, setShowError] = useState(false);

  let minWidthToIncludeImg = 80;

  useEffect(() => {
    const handleResize = () => {
      const container = divRef.current;
      if (container) {
        setIsOverflowing(container.clientWidth >= minWidthToIncludeImg);
      }
    };

    window.addEventListener('resize', handleResize);

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          if (divRef.current) {
            handleResize();
            break;
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [minWidthToIncludeImg]);

  useEffect(() => {
    if (getFen) {
      const fromDate = searchParams.get("from_date");
      const toDate = searchParams.get("to_date");
      getFenState(fromDate, toDate);
      setGetFne(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFen]);

  const handleDisplayFenCard = (value, item, day) => {
    selectedDay = day;

    if (!!item) {
      const fen = fenStates.find((state) => state.code === item.code)?.id;
      setSelectedFenState(fen);
    }
    setDisplayFenCard(value);
  };

  const handleSetSelectedFenState = (e) => {
    setShowError(false);
    setSelectedFenState(+e.target.value);
    updateFenState(+e.target.value);
  };

  const [fenInput, setFenInput] = useState({
    id: 15,
    data: fenStates,
    label: intl.formatMessage({ id: PHENEOLOGICAL_STATE_LINE__HEADER__TITLE }),
    fieldLabel: SELECT_FIELD_CODE,
    fieldLValue: FIELD_ID,
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetSelectedFenState,
    initialOption: true,
    defaultValue: fenStates.find((state) => state.id === selectedFenState)?.code || "",
    initialValue: fenStates.find((state) => state.id === selectedFenState)?.code || "",
    initialText: fenStates.find((state) => state.id === selectedFenState)?.code || "",
  });

  useEffect(() => {
    const item = fenStates.find((state) => state.id === selectedFenState);
    setFenInput({ ...fenInput, defaultValue: item?.code, initialValue: item?.code, initialText: item?.code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFenState]);

  useEffect(() => {
    setFenInput({ ...fenInput, data: fenStates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fenStates]);

  const updateFenStateCallback = () => {
    setGetFne(true);
    setSelectedFenState(undefined);
    setDisplayFenCard(false);
    selectedDay = undefined;
  };

  const updateFenStateErrorCallback = (error) => {
    if (error.response.status === HTTP_CODE_409) {
     setShowError(true);
    }
  }

  useEffect( () => {
    showError && showMessage(intl.formatMessage({ id: PHENOLOGICAL_STATE__CHANGE_ERROR }, { state: fenInput.defaultValue }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError])

  const updateFenState = (fen) => {
    updateFenStateService(id, fen, selectedDay, updateFenStateCallback, updateFenStateErrorCallback);
  };

  return (
    <div className={styles.lineContainer}>
      <div className={styles.titleContainer}>
        <b>
          <span>{intl.formatMessage({ id: PHENEOLOGICAL_STATE_LINE__HEADER__TITLE })}</span>
        </b>
      </div>
      {gridDays.map((day, index) => {
        const phenState = phenologicalStateData.find(
          (state) => formatTimestampToDate(state.date) === formatTimestampToDate(day),
        );
        return (
          <div className={styles.fenContainer} key={day} ref={divRef}>
            {!!phenState ? (
              <div
                key={day + index}
                className={clsx(styles.lineItemsContainer, styles.hoverIcon)}
                onClick={() => handleDisplayFenCard(displayFenCard === index ? null : index, phenState, day)}
                onMouseEnter={() => setDisplayTooltip(day)}
                onMouseLeave={() => setDisplayTooltip(null)}
              >
                <div className={styles.lineItemContainer}>
                  {isOverflowing ? (
                    <div className={styles.imgContainer}>
                      <img
                        src={`${process.env.PUBLIC_URL}/phenological_images/${phenState.code}.jpg`}
                        alt={phenState.name}
                        style={{ borderRadius: "10px", width: "100%", height: "100%" }}
                      />
                    </div>
                  ) : null}
                  <div className={styles.itemContainer}>
                    <TypographyComponent text={phenState?.code} variant={TEXT.VARIANT_H3} />
                    <span>{EDIT_ICON}</span>
                  </div>
                </div>
                {displayTooltip === day && <span className={styles.tooltip}>{phenState?.name}</span>}
              </div>
            ) : (
              <div
                key={day + index}
                className={clsx(styles.lineItemsContainer, styles.hoverIcon)}
                onClick={() => handleDisplayFenCard(displayFenCard === index ? null : index, undefined, day)}
              >
                {ADD_ICON}
              </div>
            )}

            {displayFenCard === index && (
              <div className={styles.fenCard}>
                <SelectComponent
                  showDescription={true}
                  data={fenInput.data}
                  label={fenInput.label}
                  fieldLabel={fenInput.fieldLabel}
                  fieldLValue={fenInput.fieldLValue}
                  variant={fenInput.variant}
                  handleOnchange={fenInput.onChange}
                  initialOption={fenInput.initialOption}
                  initialValue={fenInput.initialValue}
                  defaultValue={fenInput.defaultValue}
                  initialText={fenInput.initialText}
                  verticalPlacementLabel
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default PhenologicalStateLineComponent;
