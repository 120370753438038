import React, { useEffect, useState } from "react";

import {
  COMMON__ACTION_ACCEPT,
  COMMON__ERROR_MESSAGE_PARCEL_ALREADY_EXISTS,
  COMMON__INPUT__CODE,
  COMMON__INPUT__NAME,
  COMMON__PARCEL,
  COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
  CREATE_PARCEL_FORM__INPUT__COUNCIL,
  CREATE_PARCEL_FORM__INPUT__VARIETY,
  CREATE_PARCEL_FORM__INPUT__WEATHER_STATION,
  CREATE_PARCEL_FORM__INPUT__ZONE,
  CREATE_PARCEL_FORM__TITLE,
  EDIT_PARCEL_FORM__TITLE,
  PARCELS_MANAGEMENT__TITLE,
  PARCEL_FORM__PARCEL_CREATED_MODAL_TEXT,
  PARCEL_FORM__PARCEL_CREATED_MODAL_TITLE,
} from "../../../translations/constants";
import CreateParcelFormComponent from "./components/createParcelForm/CreateParcelFormComponent";
import {
  deletePlotService,
  getPlotsService,
  postCreatePlotService,
  updatePlotService,
} from "../../../services/commonservices";
import {
  COORDINATES_DEGREES,
  COORDINATES_MINUTES,
  COORDINATES_SECONDS,
} from "../../common/atoms/coordinatesInput/coordinatesInputConstants";
import { coordinatesToDecimal, decimalToCoordinates, filterObject } from "../../../utils/utils";
import { ADMIN_TABLE_ROWS_PARCELS, CREATE_PLOT_ERROR_FIELD, PLOT_SCHEMA } from "./manageParcelsConstants";
import { ERROR_TYPE_OPTIONALITY, ERROR_TYPE_REQUIRED, HTTP_CODE_409, ROLES_PARCEL_ADMIN } from "../../../utils/constants";
import withAuthorization from "../../common/auth/withAuthorization";
import AdministrationPageComponent from "../AdministrationPageComponent";
import { useIntl } from "react-intl";
import useCustomIntl from "../../../hook/cutomHook";
import TypographyComponent from "../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../common/atoms/typography/typographyConstants";
import ModalComponent from "../../common/molecules/modal/ModalComponent";

const ManageParcelsComponent = () => {
  const intl = useIntl();
  const { formatMessage } = useCustomIntl();

  const [parcels, setParcels] = useState([]);

  const [createParcel, setCreateParcel] = useState(true);
  const [parcelId, setParcelId] = useState(null);

  const [displayCreateModal, setDisplayCreateModal] = useState(false);
  const [displayParcelCreatedModal, setDisplayParcelCreatedModal] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [key, setKey] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationParameters, setPaginationParameters] = useState({
    limit: ADMIN_TABLE_ROWS_PARCELS,
    offset: 0,
  });
  const [orderParameters, setOrderParemeters] = useState(null);
  const [totalRows, setTotalRows] = useState(0);

  const handleOpenCreateModal = () => {
    setDisplayCreateModal(!displayCreateModal);
    setCreateParcel(true);
    if (displayCreateModal) {
      setName("");
      setCellar("");
      setCode("");
      setDescription("");

      setVariety("");
      setConduction("");
      setMaintenance("");
      setDensity(undefined);
      setYear(undefined);
      setIrrigation("");

      setZone("");
      setLocation("");
      setSurface(undefined);
      setOrientation("");

      setProvince("");
      setCouncil(undefined);
      setStation(undefined);
      setLatitude({
        [COORDINATES_DEGREES]: undefined,
        [COORDINATES_MINUTES]: undefined,
        [COORDINATES_SECONDS]: undefined,
      });
      setLongitude({
        [COORDINATES_DEGREES]: undefined,
        [COORDINATES_MINUTES]: undefined,
        [COORDINATES_SECONDS]: undefined,
      });
      setAltitude(undefined);
      /*setReference("");*/
    }
  };

  /* BLOQUE 1 */
  const [name, setName] = useState("");
  const [cellar, setCellar] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const handleSetCellar = (e) => {
    setCellar(e.target.value);
  };

  const handleSetCode = (e) => {
    setCode(e.target.value);
  };

  const handleSetDescription = (e) => {
    setDescription(e.target.value);
  };

  /* BLOQUE 2 */
  const [variety, setVariety] = useState("");
  const [conduction, setConduction] = useState("");
  const [maintenance, setMaintenance] = useState("");
  const [density, setDensity] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [irrigation, setIrrigation] = useState("");

  const handleSetVariety = (e) => {
    setVariety(e.target.value);
  };

  const handleSetConduction = (e) => {
    setConduction(e.target.value);
  };

  const handleSetMaintenance = (e) => {
    setMaintenance(e.target.value);
  };

  const handleSetDensity = (e) => {
    setDensity(+e.target.value);
  };

  const handleSetYear = (e) => {
    setYear(+e.target.value);
  };

  const handleSetIrrigation = (e) => {
    setIrrigation(e.target.value);
  };

  /* BLOQUE 3 */
  const [zone, setZone] = useState("");
  const [location, setLocation] = useState("");
  const [surface, setSurface] = useState(undefined);
  const [orientation, setOrientation] = useState("");

  const handleSetZone = (e) => {
    setZone(e.target.value);
  };

  const handleSetLocation = (e) => {
    setLocation(e.target.value);
  };

  const handleSetSurface = (e) => {
    setSurface(e.target.value);
  };

  const handleSetOrientation = (e) => {
    setOrientation(e.target.value);
  };

  /* BLOQUE 4 */
  const [province, setProvince] = useState("");
  const [council, setCouncil] = useState("");
  const [station, setStation] = useState(undefined);

  const [latitude, setLatitude] = useState({
    [COORDINATES_DEGREES]: undefined,
    [COORDINATES_MINUTES]: undefined,
    [COORDINATES_SECONDS]: undefined,
  });
  const [longitude, setLongitude] = useState({
    [COORDINATES_DEGREES]: undefined,
    [COORDINATES_MINUTES]: undefined,
    [COORDINATES_SECONDS]: undefined,
  });
  const [altitude, setAltitude] = useState(undefined);

  /*const [reference, setReference] = useState("");*/

  const handleSetProvince = (e) => {
    setProvince(e.target.value);
  };

  const handleSetCouncil = (e) => {
    setCouncil(e.target.value);
  };

  const handleSetStation = (e) => {
    setStation(e.target.value);
  };

  const handleSetLatitude = (value) => {
    setLatitude(value);
  };

  const handleSetLongitude = (value) => {
    setLongitude(value);
  };

  const handleSetAltitude = (e) => {
    setAltitude(+e.target.value);
  };

  const handleCloseParcelCreatedModal = (e) => {
    setDisplayParcelCreatedModal(!displayParcelCreatedModal);
  }

  /*const handleSetReference = (e) => {
    setReference(e.target.value);
  };*/

  const createParcelCallback = () => {
    handleOpenCreateModal();
    handleGetParcels();
    setDisplayParcelCreatedModal(true);
  };

  const createParcelErrorCallback = (error) => {
    if (error.response.status === HTTP_CODE_409) {
      setErrorMessage(`*${intl.formatMessage({ id: COMMON__ERROR_MESSAGE_PARCEL_ALREADY_EXISTS })}`);
      setDisplayError(true);
    }
  };

  const handleCreateParcel = () => {
    const body = {
      name,
      description,
      cellar_id: cellar,
      zone,
      location,
      area: surface,
      orientation,
      variety,
      conduction,
      land_maintenance: maintenance,
      density: density,
      year: year,
      irrigation,
      council_id: +council || undefined,
      meteo_id: +station || undefined,
      code,
      geolocation: {
        latitude: coordinatesToDecimal(latitude),
        longitude: coordinatesToDecimal(longitude),
        altitude: altitude,
      },
      /*ref_cat: reference,*/
    };

    const formattedBody = filterObject(body);

    PLOT_SCHEMA.validate(formattedBody)
      .then(() => {
        postCreatePlotService(formattedBody, createParcelCallback, createParcelErrorCallback);
        setDisplayError(false);
        setErrorMessage("");
      })
      .catch((error) => {
        if (error.type === ERROR_TYPE_REQUIRED || error.type === ERROR_TYPE_OPTIONALITY) {
          setErrorMessage(
            `*${intl.formatMessage(
              {
                id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
              },
              { field: intl.formatMessage({ id: CREATE_PLOT_ERROR_FIELD[error.path] }).toLowerCase() },
            )}`,
          );
          setDisplayError(true);
        }
      });
  };

  const updateParcelErrorCallback = (error) => {
    console.error(error);
  };

  const updateParcelCallback = () => {
    handleGetParcels();
    handleOpenCreateModal();
  };

  const handleUpdateParcel = () => {
    const body = {
      name: name,
      description,
      cellar_id: cellar,
      variety,
      council_id: +council,
      meteo_id: +station,
    };
    const formattedBody = filterObject(body);

    updatePlotService(parcelId, formattedBody, updateParcelCallback, updateParcelErrorCallback);
  };

  const getParcels = () =>
    new Promise((resolve) => {
      getPlotsService({ ...paginationParameters, ...orderParameters }, (result) => {
        return resolve({ parcels: result.data, totalResults: result.headers["pagination-total-results"] });
      });
    });

  const handleOrderParameters = (order) => {
    setOrderParemeters(order);
  };

  useEffect(() => {
    getParcels().then((result) => {
      setParcels(result.parcels);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderParameters]);

  useEffect(() => {
    setPaginationParameters({ limit: ADMIN_TABLE_ROWS_PARCELS, offset: ADMIN_TABLE_ROWS_PARCELS * (page - 1) });
    if (!!key) {
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (!!key) {
      handleGetParcels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const handleGetParcels = () => {
    getParcels().then((result) => {
      setParcels(result.parcels);
      setTotalRows(parseInt(result.totalResults));
    });
  };

  useEffect(() => {
    handleGetParcels();
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (value) => {
    const newPage = value ? page + 1 : page - 1;
    setPage(newPage);
  };

  const handleEditParcel = (row) => {
    setParcelId(row.id);
    setName(row.name || "");
    setCellar(row.cellar_id || "");
    setCode(row.code || "");
    setDescription(row.description || "");
    setVariety(row.variety || "");
    setConduction(row.conduction || "");
    setMaintenance(row.maintenance || "");
    setDensity(row.density || undefined);
    setYear(row.year || undefined);
    setIrrigation(row.irrigation || "");
    setZone(row.zone || "");
    setLocation(row.location || "");
    setSurface(row.surface || undefined);
    setOrientation(row.orientation || "");
    setProvince(row.region || "");
    setCouncil(row.council_id || undefined);
    setStation(row.meteo_id || undefined);
    setLatitude(
      row.geolocation?.latitude
        ? decimalToCoordinates(row.geolocation?.latitude)
        : {
            [COORDINATES_DEGREES]: undefined,
            [COORDINATES_MINUTES]: undefined,
            [COORDINATES_SECONDS]: undefined,
          },
    );
    setLongitude(
      row.geolocation?.longitude
        ? decimalToCoordinates(row.geolocation?.longitude)
        : {
            [COORDINATES_DEGREES]: undefined,
            [COORDINATES_MINUTES]: undefined,
            [COORDINATES_SECONDS]: undefined,
          },
    );
    setAltitude(row.geolocation?.altitude || undefined);
    /*setReference(row.ref_cat || "");*/

    setCreateParcel(false);
    setDisplayCreateModal(!displayCreateModal);
    setDisplayError(false);
    setErrorMessage("");
  };

  const deleteParcelCallback = () => {
    handleGetParcels();
  };

  const handleDeleteParcel = (row) => {
    deletePlotService(row.id, deleteParcelCallback);
  };

  const columns = [
    {
      title: formatMessage(COMMON__INPUT__NAME),
      width: "minmax(75px,0.75fr)",
      field: "name",
      fieldOrder: "name_order",
    },
    {
      title: formatMessage(COMMON__INPUT__CODE),
      width: "minmax(100px,1fr)",
      field: "code",
      fieldOrder: "code_order",
    },
    {
      title: formatMessage(CREATE_PARCEL_FORM__INPUT__ZONE),
      width: "minmax(100px,1fr)",
      field: "zone",
      fieldOrder: "zone_order",
    },
    {
      title: formatMessage(CREATE_PARCEL_FORM__INPUT__VARIETY),
      width: "minmax(75px,0.75fr)",
      field: "variety",
      fieldOrder: "variety_order",
    },
    {
      title: formatMessage(CREATE_PARCEL_FORM__INPUT__COUNCIL),
      width: "minmax(75px,0.75fr)",
      field: "council_name",
      fieldOrder: "council_name_order",
    },
    {
      title: formatMessage(CREATE_PARCEL_FORM__INPUT__WEATHER_STATION),
      width: "minmax(75px,0.75fr)",
      field: "meteo_name",
      fieldOrder: "meteo_name_order",
    },
  ];

  return (
  <>
    <AdministrationPageComponent
      title={PARCELS_MANAGEMENT__TITLE}
      columns={columns}
      rows={parcels}
      rowsToShow={ADMIN_TABLE_ROWS_PARCELS}
      totalRows={totalRows}
      buttonText={CREATE_PARCEL_FORM__TITLE}
      modalComponent={
        <CreateParcelFormComponent
          name={name}
          handleSetName={handleSetName}
          cellar={cellar}
          handleSetCellar={handleSetCellar}
          code={code}
          handleSetCode={handleSetCode}
          description={description}
          handleSetDescription={handleSetDescription}
          variety={variety}
          handleSetVariety={handleSetVariety}
          conduction={conduction}
          handleSetConduction={handleSetConduction}
          maintenance={maintenance}
          handleSetMaintenance={handleSetMaintenance}
          density={density}
          handleSetDensity={handleSetDensity}
          year={year}
          handleSetYear={handleSetYear}
          irrigation={irrigation}
          handleSetIrrigation={handleSetIrrigation}
          zone={zone}
          handleSetZone={handleSetZone}
          location={location}
          handleSetLocation={handleSetLocation}
          surface={surface}
          handleSetSurface={handleSetSurface}
          orientation={orientation}
          handleSetOrientation={handleSetOrientation}
          province={province}
          handleSetProvince={handleSetProvince}
          council={council}
          handleSetCouncil={handleSetCouncil}
          station={station}
          handleSetStation={handleSetStation}
          latitude={latitude}
          handleSetLatitude={handleSetLatitude}
          longitude={longitude}
          handleSetLongitude={handleSetLongitude}
          altitude={altitude}
          handleSetAltitude={handleSetAltitude}
          /*reference={reference}
          handleSetReference={handleSetReference}*/
          handleCreateParcel={createParcel ? handleCreateParcel : handleUpdateParcel}
          displayError={displayError}
          errorMessage={errorMessage}
          createParcel={createParcel}
        />
      }
      displayCreateModal={displayCreateModal}
      handleOpenCreateModal={handleOpenCreateModal}
      handleEdit={handleEditParcel}
      handleDelete={handleDeleteParcel}
      createTitle={CREATE_PARCEL_FORM__TITLE}
      editTitle={EDIT_PARCEL_FORM__TITLE}
      deleteTitle={COMMON__PARCEL}
      handleChangePage={handleChangePage}
      handleOrderParameters={handleOrderParameters}
    />

    {displayParcelCreatedModal && (
      <ModalComponent
        body={
          <div>
            <TypographyComponent
              variant={TEXT.VARIANT_P}
              text={intl.formatMessage({ id: PARCEL_FORM__PARCEL_CREATED_MODAL_TEXT })}
            />
          </div>
        }
        handleClose={handleCloseParcelCreatedModal}
        title={intl.formatMessage({ id: PARCEL_FORM__PARCEL_CREATED_MODAL_TITLE })}
        buttonAcceptText={intl.formatMessage({ id: COMMON__ACTION_ACCEPT })}
        slim={true}
      />
    )}
  </>
  );
};

export default withAuthorization(ROLES_PARCEL_ADMIN)(ManageParcelsComponent);
