import React, { useEffect, useState } from "react";

import ButtonComponent from "../../../../../../common/atoms/button/ButtonComponent";
import TextAreaComponent from "../../../../../../common/atoms/textArea/TextAreaComponent";
import style from "../../../../../../common/organisms/form/Form.module.css";
import SelectGroupComponent from "../../../../../../common/molecules/selectGroup/SelectGroupComponent";
import AutocompleteGroupComponent from "../../../../../../common/molecules/autocompleteGroup/AutocompleteGroup";
import InputComponent from "../../../../../../common/atoms/input/InputComponent";
import { INPUT } from "../../../../../../common/atoms/input/inputConstants";
import {
  COMMON__ACTION_CLOSE,
  COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
  PARCEL_SELECT__PLAGUE_TREATMENT,
} from "../../../../../../../translations/constants";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { useIntl } from "react-intl";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import { Typography } from "@mui/material";
import { BUTTON } from "../../../../../../common/atoms/button/buttonConstants";

const TreatmentFormComponent = ({
  day,
  sendForm,
  inputs,
  submitButtonText,
  errorField,
  displayError,
  autocompleteTagStyle,
  handleClose,
}) => {
  const intl = useIntl();
  const { formatMessage } = useCustomIntl();

  const [plaguesAffected, setPlaguesAffected] = useState([]);
  /*const [productsSelected, setProductsSelected] = useState([]);*/

  useEffect(() => {
    const uniqueTags = new Set(); // Conjunto para almacenar tags únicos
    // Iterar sobre los inputs y agregar tags únicos al conjunto
    inputs?.forEach((input) => {
      if (input.type === INPUT.TYPE_AUTOCOMPLETE && input.tags.length > 0) {
        input.tags.forEach((tag) => uniqueTags.add(tag));
        setPlaguesAffected([...uniqueTags]);
      }
      /* if (input.type === INPUT.TYPE_SELECT && input.tags.length > 0) {
                input.tags.forEach((tag) => uniqueTags.add(tag.id));
                setProductsSelected([...uniqueTags]);
            }*/
    });
    // Actualizar el estado con los tags únicos
  }, [inputs]);

  const handleSubmit = (e) => {
    e.preventDefault();
    sendForm(e.target);
  };

  const getPlaguesAffected = () => {
    let uniquePlagueNames = new Set();
    plaguesAffected?.map((plagueAffected) =>
      plagueAffected?.plagues?.map((plague) => uniquePlagueNames.add(plague.name)),
    );
    return uniquePlagueNames.size > 0 ? (
      <Typography component="span" variant="subtitle1" color="inherit" className={style.highlightedText}>
        {formatMessage(PARCEL_SELECT__PLAGUE_TREATMENT) + " " + [...uniquePlagueNames].join(", ")}
      </Typography>
    ) : (
      ""
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={style.container}>
        {inputs?.map((input, index) => {
          return input.type === INPUT.TYPE_SELECT ? (
            <SelectGroupComponent key={input.name + index} data={input} className={style.inputLimit} />
          ) : input.type === INPUT.TYPE_TEXTAREA ? (
            <TextAreaComponent
              data={input}
              key={input.name + index}
              onChange={input.onChange}
              className={style.inputLimit}
            />
          ) : input.type === INPUT.TYPE_AUTOCOMPLETE ? (
            <>
              <AutocompleteGroupComponent
                data={input}
                key={input.name + index}
                name={input.name}
                tagStyle={autocompleteTagStyle}
                extraControls={input.name === "parcelas"}                
              />
              {input.name === "products" && getPlaguesAffected()}
            </>
          ) : (
            <InputComponent
              day={day}
              data={input}
              key={input.name + index || input.id + index}
              onChange={input.onChange}
              className={style.inputLimit}
            />
          );
        })}
        {displayError && (
          <TypographyComponent
            className={style.errorMessage}
            text={`*${intl.formatMessage(
              { id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE },
              { field: intl.formatMessage({ id: errorField }).toLowerCase() },
            )}`}
          />
        )}
        <div className={style.buttonContainer}>
          <ButtonComponent text={submitButtonText} type="submit" className={style.buttonLimit} />
          <ButtonComponent
            text={intl.formatMessage({ id: COMMON__ACTION_CLOSE })}
            variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
            onClick={handleClose}
          />
        </div>
      </form>
    </>
  );
};

export default TreatmentFormComponent;
