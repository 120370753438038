import React, { useState } from "react";

import style from "./TextArea.module.css";

const TextAreaComponent = ({ data, onChange, value }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleInput = (event) => {
    setInputValue(event.target.value);
    onChange(event);
  };

  return (
    <div>
      {data.label && <label htmlFor={data.name}>{`${data.label}${data.required ? "*" : ""}`}</label>}
      <textarea
        id={data.id}
        name={data.name}
        value={inputValue}
        onChange={handleInput}
        className={style.textArea}
        aria-label="empty textarea"
        placeholder={data.placeholder}
        required={data.required}
      />
    </div>
  );
};

export default TextAreaComponent;
