import React, { useState } from "react";
import style from "./CoordinatesInput.module.css";
import { INPUT } from "../input/inputConstants";
import {
  COORDINATES_DEGREES,
  COORDINATES_DEGREES_SYMBOL,
  COORDINATES_MINUTES,
  COORDINATES_MINUTES_SYMBOL,
  COORDINATES_SECONDS,
  COORDINATES_SECONDS_SYMBOL,
} from "./coordinatesInputConstants";
import TypographyComponent from "../typography/TypographyComponent";

const CoordinatesInput = ({ data, onChange }) => {
  const [inputValue, setInputValue] = useState({
    [COORDINATES_DEGREES]: undefined,
    [COORDINATES_MINUTES]: undefined,
    [COORDINATES_SECONDS]: undefined,
  });

  const handleInputChange = (event, field) => {
    let newValue = { ...inputValue };
    newValue[field] = event.target.value;
    setInputValue(newValue);

    onChange(newValue);
  };

  return (
    <div>
      <TypographyComponent text={data.label} />
      <div className={style.inputGroup}>
        <div className={style.inputContainer}>
          <input
            className={style.input}
            id={COORDINATES_DEGREES}
            name={COORDINATES_DEGREES}
            type={INPUT.TYPE_NUMBER}
            value={data.value[COORDINATES_DEGREES]}
            onChange={(e) => handleInputChange(e, COORDINATES_DEGREES)}
            disabled={data.disabled}
          />
          {COORDINATES_DEGREES_SYMBOL}
        </div>
        <div className={style.inputContainer}>
          <input
            className={style.input}
            id={COORDINATES_MINUTES}
            name={COORDINATES_MINUTES}
            type={INPUT.TYPE_NUMBER}
            value={data.value[COORDINATES_MINUTES]}
            onChange={(e) => handleInputChange(e, COORDINATES_MINUTES)}
            disabled={data.disabled}
          />
          {COORDINATES_MINUTES_SYMBOL}
        </div>
        <div className={style.inputContainer}>
          <input
            className={style.input}
            id={COORDINATES_SECONDS}
            name={COORDINATES_SECONDS}
            type={INPUT.TYPE_NUMBER}
            value={data.value[COORDINATES_SECONDS]}
            onChange={(e) => handleInputChange(e, COORDINATES_SECONDS)}
            disabled={data.disabled}
          />
          {COORDINATES_SECONDS_SYMBOL}
        </div>
      </div>
    </div>
  );
};

export default CoordinatesInput;
