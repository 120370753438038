import React from "react";
import style from "./CardLegend.module.css";
import TypographyComponent from "../../atoms/typography/TypographyComponent";
import ButtonComponent from "../../atoms/button/ButtonComponent";
import PointerComponent from "../../atoms/pointer/PointerComponent";
import { TEXT } from "../../atoms/typography/typographyConstants";
import { BUTTON } from "../../atoms/button/buttonConstants";
import { HIGH } from "../../../parcels/Constants";
import useCustomIntl from "../../../../hook/cutomHook";

function CardLegendComponent({ data, closeAction }) {
  const { formatMessage } = useCustomIntl();

  const { title, text1, text2, subtitle, risks } = data;

  return (
    <div className={style.container}>
      <div className={style.header}>
        <TypographyComponent text={title} variant={TEXT.VARIANT_H2} />
        <div className={`${style.buttonCloseLegend}`}>
          <ButtonComponent
            text={formatMessage(BUTTON.CLOSE_LEGEND)}
            variant={BUTTON.VARIANT_LIGHT}
            onClick={closeAction}
          />
        </div>
      </div>
      <div className={style.infoContainer}>
        <PointerComponent status={HIGH} size={50} number={5} />
        <div className={style.textInfoContainer}>
          <TypographyComponent text={text1} variant={TEXT.VARIANT_P} className={style.subtitle} />
          <TypographyComponent text={text2} variant={TEXT.VARIANT_P} className={style.subtitle} />
        </div>
      </div>
      <div className={style.legendContainer}>
        <TypographyComponent text={subtitle} variant={TEXT.VARIANT_H3} />
        <div className={style.itemContainer}>
          {risks?.map((item, index) => {
            return (
              <div className={style.item} key={index}>
                <PointerComponent status={item.value} size={10} />
                <TypographyComponent
                  text={formatMessage(item.name)}
                  variant={TEXT.VARIANT_SPAN}
                  className={style.subtitle}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CardLegendComponent;
