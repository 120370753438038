import { number, object, string } from "yup";
import {
  CREATE_PARCEL_FORM__INPUT__COUNCIL,
  COMMON__INPUT__NAME,
  CREATE_PARCEL_FORM__INPUT__VARIETY,
  CREATE_PARCEL_FORM__INPUT__WEATHER_STATION,
  CREATE_PARCEL_FORM__INPUT__ZONE,
  CREATE_PARCEL_FORM__INPUT__ALTITUDE,
  CREATE_PARCEL_FORM__INPUT__LATITUDE,
  CREATE_PARCEL_FORM__INPUT__LONGITUDE,
} from "../../../translations/constants";

export const CONDUCTION_TYPE_ESPALDEIRA = "Espaldeira";
export const CONDUCTION_TYPE_PARRA = "Parra";
export const CONDUCTION_TYPES = [CONDUCTION_TYPE_ESPALDEIRA, CONDUCTION_TYPE_PARRA];

export const MAINTENANCE_TYPE_LABOREO = "Laboreo";
export const MAINTENANCE_TYPE_CUBERTA = "Cuberta";
export const MAINTENANCE_TYPES = [MAINTENANCE_TYPE_LABOREO, MAINTENANCE_TYPE_CUBERTA];

export const IRRIGATION_TYPE_SECANO = "Secano";
export const IRRIGATION_TYPE_REGADIO = "Regadío";
export const IRRIGATION_TYPES = [IRRIGATION_TYPE_SECANO, IRRIGATION_TYPE_REGADIO];

export const LOCATION_TYPE_VAL = "Val";
export const LOCATION_TYPE_MONTE = "Monte";
export const LOCATION_TYPE_TRANSICION = "Transición";
export const LOCATION_TYPES = [LOCATION_TYPE_VAL, LOCATION_TYPE_MONTE, LOCATION_TYPE_TRANSICION];

export const ORIENTATION_TYPE_N = "N";
export const ORIENTATION_TYPE_S = "S";
export const ORIENTATION_TYPE_E = "E";
export const ORIENTATION_TYPE_O = "O";
export const ORIENTATION_TYPE_NE = "NE";
export const ORIENTATION_TYPE_NO = "NO";
export const ORIENTATION_TYPE_SO = "SO";
export const ORIENTATION_TYPE_SE = "SE";
export const ORIENTATION_TYPES = [
  ORIENTATION_TYPE_N,
  ORIENTATION_TYPE_S,
  ORIENTATION_TYPE_E,
  ORIENTATION_TYPE_O,
  ORIENTATION_TYPE_NE,
  ORIENTATION_TYPE_NO,
  ORIENTATION_TYPE_SO,
  ORIENTATION_TYPE_SE,
];

export const PLOT_SCHEMA = object({
  name: string().required(),
  cellar_id: number(),
  zone: string().required(),
  location: string().oneOf(LOCATION_TYPES),
  area: number(),
  orientation: string().oneOf(ORIENTATION_TYPES),
  variety: string().required(),
  conduction: string().oneOf(CONDUCTION_TYPES),
  land_maintenance: string().oneOf(MAINTENANCE_TYPES),
  density: number(),
  year: number(),
  irrigation: string().oneOf(IRRIGATION_TYPES),
  council_id: number(),
  meteo_id: string(),
  code: string(),
  geolocation: object().shape({
    latitude: number().required(),
    longitude: number().required(),
    altitude: number().required(),
  }),
  /*ref_cat: string(),*/
});

export const BASIC_PLOT_SCHEMA = object({
  name: string().required(),
  zone: string().required(),
  location: string().oneOf(LOCATION_TYPES),
  area: number(),
  orientation: string().oneOf(ORIENTATION_TYPES),
  variety: string().required(),
  conduction: string().oneOf(CONDUCTION_TYPES),
  land_maintenance: string().oneOf(MAINTENANCE_TYPES),
  density: number(),
  year: number(),
  irrigation: string().oneOf(IRRIGATION_TYPES),
  council_id: number(),
  meteo_id: string(),
  code: string(),
  geolocation: object().shape({
    latitude: number().required(),
    longitude: number().required(),
    altitude: number().required(),
  }),
});

export const CREATE_PLOT_ERROR_FIELD = {
  name: COMMON__INPUT__NAME,
  zone: CREATE_PARCEL_FORM__INPUT__ZONE,
  variety: CREATE_PARCEL_FORM__INPUT__VARIETY,
  council_id: CREATE_PARCEL_FORM__INPUT__COUNCIL,
  meteo_id: CREATE_PARCEL_FORM__INPUT__WEATHER_STATION,
  "geolocation.altitude": CREATE_PARCEL_FORM__INPUT__ALTITUDE,
  "geolocation.latitude": CREATE_PARCEL_FORM__INPUT__LATITUDE,
  "geolocation.longitude": CREATE_PARCEL_FORM__INPUT__LONGITUDE,
};

export const ADMIN_TABLE_ROWS_PARCELS = 6;
