import React from "react";
import style from "./CardWithButton.module.css";
import TypographyComponent from "../../atoms/typography/TypographyComponent";
import ButtonComponent from "../../atoms/button/ButtonComponent";
import PropTypes from "prop-types";
import { TEXT } from "../../atoms/typography/typographyConstants";
import { BUTTON } from "../../atoms/button/buttonConstants";

function CardWithButtonComponent({ titulo, data, buttonIcon, buttonText, onClick, disabled }) {
  return (
    <div className={style.container}>
      <div className={style.cardHeader}>
        <TypographyComponent text={titulo} variant={TEXT.VARIANT_H4} />
        <ButtonComponent
          icon={buttonIcon}
          text={buttonText}
          onClick={onClick}
          disabled={disabled}
          hiddenTextMobileVersion={true}
          variant={BUTTON.VARIANT_PRIMARY_WITH_ICON}
        />
      </div>
      <div className={style.cardBody}>
        {data.map((item, index) => (
          <span>
            <span className={style.cardInfo}>
              {item.metaTitle}: {item.metaValue}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
}

CardWithButtonComponent.propTypes = {
  titulo: PropTypes.string,
  data: PropTypes.array,
  buttonIcon: PropTypes.object,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default CardWithButtonComponent;
