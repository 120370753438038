import React from "react";
import PropTypes from "prop-types";
import styles from "./CreateCellarForm.module.css";
import InputComponent from "../../../../common/atoms/input/InputComponent";
import TextAreaComponent from "../../../../common/atoms/textArea/TextAreaComponent";
import {
  COMMON__BUTTONS__SAVE,
  COMMON__INPUT__CODE,
  COMMON__INPUT__NAME,
  COMMON__INPUT__DESCRIPTION,
} from "../../../../../translations/constants";
import { INPUT } from "../../../../common/atoms/input/inputConstants";
import useCustomIntl from "../../../../../hook/cutomHook";
import { useIntl } from "react-intl";
import TypographyComponent from "../../../../common/atoms/typography/TypographyComponent";
import { BUTTON } from "../../../../common/atoms/button/buttonConstants";
import ButtonComponent from "../../../../common/atoms/button/ButtonComponent";

const CreateCellarFormComponent = ({
  name,
  handleSetName,
  code,
  handleSetCode,
  description,
  handleSetDescription,
  handleCreateCellar,
  displayError,
  errorMessage,
  codeDisabled = false,
}) => {
  const { formatMessage } = useCustomIntl();
  const intl = useIntl();

  const nameInput = {
    id: 1,
    name: formatMessage(COMMON__INPUT__NAME),
    label: formatMessage(COMMON__INPUT__NAME),
    type: INPUT.TYPE_TEXT,
    value: "name",
    onChange: handleSetName,
    required: true,
  };
  const codeInput = {
    id: 2,
    name: formatMessage(COMMON__INPUT__CODE),
    label: formatMessage(COMMON__INPUT__CODE),
    type: INPUT.TYPE_TEXT,
    value: "code",
    onChange: handleSetCode,
    required: true,
    disabled: codeDisabled,
  };

  const descriptionInput = {
    id: 3,
    name: formatMessage(COMMON__INPUT__DESCRIPTION),
    label: formatMessage(COMMON__INPUT__DESCRIPTION),
    type: INPUT.TYPE_TEXTAREA,
    value: description,
    onChange: handleSetDescription,
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.infoBlock}>
        <div className={styles.infoRow}>
          <InputComponent data={nameInput} onChange={nameInput.onChange} value={name} />
          <InputComponent data={codeInput} onChange={codeInput.onChange} value={code} />
        </div>
        <TextAreaComponent data={descriptionInput} onChange={descriptionInput.onChange} value={description} />
      </div>
      {displayError && <TypographyComponent className={styles.errorMessage} text={errorMessage} />}
      <div className={styles.buttonContainer}>
        <ButtonComponent
          text={intl.formatMessage({ id: COMMON__BUTTONS__SAVE })}
          variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
          onClick={handleCreateCellar}
        />
      </div>
    </div>
  );
};

CreateCellarFormComponent.propTypes = {
  name: PropTypes.string.isRequired,
  handleSetName: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  handleSetCode: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  handleSetDescription: PropTypes.func.isRequired,
};

export default CreateCellarFormComponent;
