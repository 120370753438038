import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  roundButton: {
    backgroundColor: "blue",
    borderRadius: "15px",
    color: "white",
  },
}));

const RoundButtonComponent = ({ body, handleOnClick }) => {
  const classes = useStyles();
  return (
    <button className={classes.roundButton} onClick={handleOnClick}>
      {body}
    </button>
  );
};

export default RoundButtonComponent;
