import React, { useState } from "react";
import styles from "./Input.module.css";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Search, Visibility, VisibilityOff } from "@mui/icons-material";
import { INPUT } from "./inputConstants";

function InputComponent({ day, data, onChange, value, className = "" }) {
  const intl = useIntl();

  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState(data.type === INPUT.TYPE_DATE ? day : value);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onChange(event);
  };

  if (data.type === INPUT.TYPE_DATE) {
    onChange(day);
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    !!data && (
      <div className={className}>
        {data.label && <label htmlFor={data.name}>{`${data.label}${data.required ? "*" : ""}`}</label>}
        <div className={`${styles.formGroup} ${styles[data.variant]}`}>
          <div className={styles.icon} onClick={handleShowPassword}>
            {data.type === INPUT.TYPE_SEARCH && <Search />}
          </div>
          <input
            id={data.id}
            name={data.name}
            className={`${styles.input} ${data.disabled ? styles.disabled : ""}`}
            type={
              data.type === INPUT.TYPE_PASSWORD
                ? showPassword
                  ? INPUT.TYPE_TEXT
                  : INPUT.TYPE_PASSWORD
                : data.type === INPUT.TYPE_SEARCH
                ? INPUT.TYPE_TEXT
                : data.type
            }
            value={inputValue}
            onChange={handleInputChange}
            placeholder={!!data?.idTextPlaceholder ? intl.formatMessage({ id: data.idTextPlaceholder }) : ""}
            disabled={data.disabled}
          />
          <div className={styles.icon} onClick={handleShowPassword}>
            {data.type === INPUT.TYPE_PASSWORD ? showPassword ? <Visibility /> : <VisibilityOff /> : null}
          </div>
        </div>
      </div>
    )
  );
}

InputComponent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    disable: PropTypes.bool,
    type: PropTypes.oneOf([
      INPUT.TYPE_TEXT,
      INPUT.TYPE_PASSWORD,
      INPUT.TYPE_EMAIL,
      INPUT.TYPE_NUMBER,
      INPUT.TYPE_DATE,
      INPUT.TYPE_SEARCH,
    ]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    idTextPlaceholder: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
};

InputComponent.defaultProps = {
  data: { idTextPlaceholder: undefined },
  onChange: undefined,
};

export default InputComponent;
