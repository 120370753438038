import React from "react";

import styles from "./CreateParcelForm.module.css";
import InfoFormComponent from "./components/infoForm/InfoFormComponent";
import PlantationFormComponent from "./components/plantationForm/PlantationFormComponent";
import LocationFormComponent from "./components/locationForm/LocationFormComponent";
import GeographicFormComponent from "./components/geographicForm/GeographicFormComponent";
import PropTypes from "prop-types";
import ButtonComponent from "../../../../common/atoms/button/ButtonComponent";
import { BUTTON } from "../../../../common/atoms/button/buttonConstants";
import { useIntl } from "react-intl";
import { COMMON__BUTTONS__SAVE } from "../../../../../translations/constants";
import TypographyComponent from "../../../../common/atoms/typography/TypographyComponent";

const CreateParcelFormComponent = ({
  name,
  handleSetName,
  cellar,
  handleSetCellar,
  code,
  handleSetCode,
  description,
  handleSetDescription,
  variety,
  handleSetVariety,
  conduction,
  handleSetConduction,
  maintenance,
  handleSetMaintenance,
  density,
  handleSetDensity,
  year,
  handleSetYear,
  irrigation,
  handleSetIrrigation,
  zone,
  handleSetZone,
  location,
  handleSetLocation,
  surface,
  handleSetSurface,
  orientation,
  handleSetOrientation,
  province,
  handleSetProvince,
  council,
  handleSetCouncil,
  station,
  handleSetStation,
  latitude,
  handleSetLatitude,
  longitude,
  handleSetLongitude,
  altitude,
  handleSetAltitude,
  /*reference,
  handleSetReference,*/
  handleCreateParcel,
  displayError,
  errorMessage,
  createParcel,
  basicForm,
}) => {
  const intl = useIntl();

  return (
    <div className={styles.formContainer}>
      <InfoFormComponent
        name={name}
        handleSetName={handleSetName}
        cellar={cellar}
        handleSetCellar={handleSetCellar}
        code={code}
        handleSetCode={handleSetCode}
        description={description}
        handleSetDescription={handleSetDescription}
        createParcel={createParcel}
        basicForm={basicForm}
      />
      <PlantationFormComponent
        variety={variety}
        handleSetVariety={handleSetVariety}
        conduction={conduction}
        handleSetConduction={handleSetConduction}
        maintenance={maintenance}
        handleSetMaintenance={handleSetMaintenance}
        density={density}
        handleSetDensity={handleSetDensity}
        year={year}
        handleSetYear={handleSetYear}
        irrigation={irrigation}
        handleSetIrrigation={handleSetIrrigation}
        createParcel={createParcel}
      />
      <LocationFormComponent
        zone={zone}
        handleSetZone={handleSetZone}
        location={location}
        handleSetLocation={handleSetLocation}
        surface={surface}
        handleSetSurface={handleSetSurface}
        orientation={orientation}
        handleSetOrientation={handleSetOrientation}
        createParcel={createParcel}
      />
      <GeographicFormComponent
        province={province}
        handleSetProvince={handleSetProvince}
        council={council}
        handleSetCouncil={handleSetCouncil}
        station={station}
        handleSetStation={handleSetStation}
        latitude={latitude}
        handleSetLatitude={handleSetLatitude}
        longitude={longitude}
        handleSetLongitude={handleSetLongitude}
        altitude={altitude}
        handleSetAltitude={handleSetAltitude}
        /*reference={reference}
        handleSetReference={handleSetReference}*/
        createParcel={createParcel}
      />
      {displayError && <TypographyComponent className={styles.errorMessage} text={errorMessage} />}
      <div className={styles.buttonContainer}>
        <ButtonComponent
          text={intl.formatMessage({ id: COMMON__BUTTONS__SAVE })}
          variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
          onClick={handleCreateParcel}
        />
      </div>
    </div>
  );
};

CreateParcelFormComponent.propTypes = {
  name: PropTypes.string.isRequired,
  handleSetName: PropTypes.func.isRequired,
  cellar: PropTypes.string,
  handleSetCellar: PropTypes.func,
  code: PropTypes.string,
  handleSetCode: PropTypes.func,
  description: PropTypes.string,
  handleSetDescription: PropTypes.func,
  variety: PropTypes.string.isRequired,
  handleSetVariety: PropTypes.func.isRequired,
  conduction: PropTypes.string.isRequired,
  handleSetConduction: PropTypes.func.isRequired,
  maintenance: PropTypes.string.isRequired,
  handleSetMaintenance: PropTypes.func.isRequired,
  density: PropTypes.number,
  handleSetDensity: PropTypes.func.isRequired,
  year: PropTypes.number,
  handleSetYear: PropTypes.func.isRequired,
  irrigation: PropTypes.string.isRequired,
  handleSetIrrigation: PropTypes.func.isRequired,
  zone: PropTypes.string.isRequired,
  handleSetZone: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  handleSetLocation: PropTypes.func.isRequired,
  surface: PropTypes.number,
  handleSetSurface: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
  handleSetOrientation: PropTypes.func.isRequired,
  province: PropTypes.string.isRequired,
  handleSetProvince: PropTypes.func.isRequired,
  council: PropTypes.string,
  handleSetCouncil: PropTypes.func.isRequired,
  station: PropTypes.string,
  handleSetStation: PropTypes.func.isRequired,
  latitude: PropTypes.objectOf(PropTypes.number).isRequired,
  handleSetLatitude: PropTypes.func.isRequired,
  longitude: PropTypes.objectOf(PropTypes.number).isRequired,
  handleSetLongitude: PropTypes.func.isRequired,
  altitude: PropTypes.string,
  handleSetAltitude: PropTypes.func.isRequired,
  /*reference: PropTypes.string,
  handleSetReference: PropTypes.func,*/
  basicForm: PropTypes.bool,
};

export default CreateParcelFormComponent;
