import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  provinces: [],
  councils: [],
  stations: [],
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    storeProvincesActionCreator: (state, action) => {
      state.provinces = action.payload;
    },
    storeCouncilsActionCreator: (state, action) => {
      state.councils = action.payload;
    },
    storeStationsActionCreator: (state, action) => {
      state.stations = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  storeProvincesActionCreator,
  storeCouncilsActionCreator,
  storeStationsActionCreator,
} = locationSlice.actions;
export const { provinces, councils, stations } = locationSlice.reducer;
export default locationSlice.reducer;
