import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import style from "./FilterSelect.module.css";
import { FILTERSELECT } from "./FilterSelectConstants";
import TagComponent from "../../atoms/tag/TagComponent";
import TypographyComponent from "../../atoms/typography/TypographyComponent";
import { TEXT } from "../../atoms/typography/typographyConstants";
import SelectComponent from "../../atoms/select/SelectComponent";

const FilterSelectComponent = ({
  title,
  filterObjects,
  objects,
  handleFilterObjects,
  initialOption = true,
  defaultValue = "",
  defaultText = "filter",
}) => {
  const intl = useIntl();

  const [objectsToSelect, setObjectsToSelect] = useState(objects);

  useEffect(() => {
    filterSelectedObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects, filterObjects]);

  const filterSelectedObjects = () => {
    const filteredSelectedObjects = objects.filter((object2) => {
      return !filterObjects.find((object1) => object1.id === object2.id);
    });
    setObjectsToSelect(filteredSelectedObjects);
  };

  const removeObjectFilter = (objectId) => {
    const newFilters = filterObjects.filter((object) => {
      return object.id !== objectId;
    });
    handleFilterObjects(newFilters);
  };

  const addObjectFilter = (event) => {
    const id = parseInt(event.target.value);
    const exist = !!filterObjects.find((object) => object.id === id);
    if (!exist) {
      const newFilters = [...filterObjects];
      newFilters.push(objects.find((object) => object.id === id));
      handleFilterObjects(newFilters);
    }
  };
  return (
    <section className={style.container}>
      {title && <TypographyComponent variant={TEXT.VARIANT_H4} text={title} />}
      <div className={style.filterObjectContainer}>
        <SelectComponent
          data={objectsToSelect}
          fieldLabel={FILTERSELECT.FIELD_LABEL}
          fieldLValue={FILTERSELECT.FIELD_VALUE}
          handleOnchange={addObjectFilter}
          initialOption={initialOption}
          defaultValue={defaultValue}
          initialText={intl.formatMessage({ id: defaultText })}
        />
        {filterObjects.map((object, index) => (
          <TagComponent key={index} text={object.name} onClick={() => removeObjectFilter(object.id)} />
        ))}
      </div>
    </section>
  );
};

FilterSelectComponent.propTypes = {
  title: PropTypes.string,
  filterObjects: PropTypes.any,
  objects: PropTypes.array,
  handleFilterObjects: PropTypes.func,
  initialOption: PropTypes.bool,
  defaultValue: PropTypes.string,
  defaultText: PropTypes.string,
};

export default FilterSelectComponent;
