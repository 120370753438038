import React from "react";
import { makeStyles } from "@mui/styles";

import logoHeader from "../../images/app_logo.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    height: 70,
    bottom: 0,
    backgroundColor: "#007bc4",
    color: "white",
    width: "100%",
    position: "relative",
    "& a": {
      color: "white",
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  logoFooter: {
    padding: theme.spacing(2, 2, 0, 2),
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(0, -50%)",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      position: "relative",
    },
  },
  legalText: {
    textAlign: "right",
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& > div": {
      paddingRight: theme.spacing(1),
    },
  },
  copyright: {},
}));

export default function FooterComponent() {
  const classes = useStyles();
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className={classes.footer}>
      <div className={classes.logoFooter}>
        <div>
          <img width={150} alt="" src={logoHeader} />
        </div>
      </div>
      <div className={classes.legalText}>
        <div className={classes.copyright}>Copyright © {year} cinfo S.L.</div>
        {/*<div>
            {intl.formatMessage({ id: COMMON__ALL_RIGHTS_RESERVED })}
            <b>
              <a href={"/"} target="_blank" rel="noreferrer">
                {intl.formatMessage({ id: COMMON__PRIVACY_POLICY })}
              </a>
            </b>
            <b>
              <a href={"/"} target="_blank" rel="noreferrer">
                {intl.formatMessage({ id: COMMON__TERMS_OF_USE })}
              </a>
            </b>
          </div>*/}
      </div>
    </div>
  );
}
