import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import ErrorComponent from "../ErrorComponent";
import { hasRoles } from "../../../utils/keycloak";
import { COMMON__ERROR_403 } from "../../../translations/constants";
import LoadingComponent from "../loading/LoadingComponent";

const withAuthorization = (roles) => (Component) => {
  return (props) => {
    const { keycloak, initialized } = useKeycloak();

    const isAuthorized = () => {
      if (keycloak && roles) {
        return hasRoles(roles);
      }
      return false;
    };

    if (isAuthorized()) {
      return <Component {...props} />;
    }

    if (!!initialized && !!keycloak && !keycloak.authenticated) {
      keycloak.login();
      return <LoadingComponent open description />;
    } else if (!!keycloak && !initialized) {
      return <LoadingComponent open description />;
    }

    return <ErrorComponent error={COMMON__ERROR_403} />;
  };
};

export default withAuthorization;
