import React, { useEffect, useState } from "react";
import styles from "./lineDisclaimerRiskComponent.module.css";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { useIntl } from "react-intl";
import { timestampToDays } from "../../../../../../../utils/utils";
import {
  DISCLAIMER_MESSAGE_FEW_DAYS,
  DISCLAIMER_MESSAGE_FEW_DAYS_CONSECUTIVE,
  DISCLAIMER_MESSAGE_ONE_DAY,
} from "../../../../../../../translations/constants";

const LineDisclaimerRiskComponent = ({ disclaimerData, gridDays }) => {
  const intl = useIntl();
  const [disclaimerDays, setDisclaimerDays] = useState([]);

  const getDayFromDate = (dateString) => {
    return dateString.split("/")[0];
  };

  const findDisclaimerDays = () => {
    return disclaimerData
      ?.filter((disclaimer) => disclaimer.plagues.some((plague) => plague.missing_data))
      .map((disclaimer) => parseInt(getDayFromDate(timestampToDays(disclaimer.date))))
      .reverse();
  };

  const isSequence = (array) => {
    for (var i = 0; i < array.length - 1; i++) {
      if (array[i] + 1 !== array[i + 1]) {
        return false;
      }
    }
    return true;
  };

  const handleDisclaimerMessage = (disclaimerDays) => {
    return disclaimerDays.length === 1
      ? intl.formatMessage({ id: DISCLAIMER_MESSAGE_ONE_DAY }, { day: disclaimerDays })
      : disclaimerDays.length === 2
      ? intl.formatMessage(
          { id: DISCLAIMER_MESSAGE_FEW_DAYS },
          { daysWithoutLast: disclaimerDays.slice(0, -1), lastDay: disclaimerDays[disclaimerDays.length - 1] },
        )
      : isSequence(disclaimerDays)
      ? intl.formatMessage(
          { id: DISCLAIMER_MESSAGE_FEW_DAYS_CONSECUTIVE },
          { firstDay: disclaimerDays[0], lastDay: disclaimerDays[disclaimerDays.length - 1] },
        )
      : intl.formatMessage(
          { id: DISCLAIMER_MESSAGE_FEW_DAYS },
          {
            daysWithoutLast: disclaimerDays.slice(0, -1).join(", "),
            lastDay: disclaimerDays[disclaimerDays.length - 1],
          },
        );
  };

  useEffect(() => {
    setDisclaimerDays(findDisclaimerDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridDays, disclaimerData]);

  return !!disclaimerDays?.length ? (
    <div className={styles.lineContainer}>
      <ReportProblemOutlinedIcon />
      {handleDisclaimerMessage(disclaimerDays)}
    </div>
  ) : null;
};
export default LineDisclaimerRiskComponent;
