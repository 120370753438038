import React from "react";
import styles from "./Button.module.css";
import PropTypes from "prop-types";
import { BUTTON } from "./buttonConstants";

function ButtonComponent({
  onClick = () => {},
  variant = BUTTON.VARIANT_PRIMARY,
  text,
  type = BUTTON.TYPE_BUTTON,
  icon,
  disabled = false,
  hiddenTextMobileVersion = false,
  hiddenIcon = false,
  className = "",
  setSelectedButton = "",
}) {
  return (
    <button
      className={`${styles.button} ${styles[variant]} ${!!disabled && styles.disabled} ${className}`}
      disabled={disabled}
      onClick={
        !!setSelectedButton
          ? () => {
              onClick();
              setSelectedButton(text);
            }
          : onClick
      }
      type={type}
    >
      {!!icon ? <span className={`${hiddenIcon && styles.hiddenIcon}`}>{icon}</span> : null}
      {!!text ? <span className={`${hiddenTextMobileVersion && styles.hiddenTextMobilButton} `}> {text} </span> : null}
    </button>
  );
}

export default ButtonComponent;

ButtonComponent.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf([BUTTON.TYPE_BUTTON, BUTTON.TYPE_SUBMIT, BUTTON.TYPE_RESET]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    BUTTON.VARIANT_PRIMARY,
    BUTTON.VARIANT_SECONDARY,
    BUTTON.VARIANT_SUCCESS,
    BUTTON.VARIANT_DANGER,
    BUTTON.VARIANT_WARNING,
    BUTTON.VARIANT_LIGHT,
    BUTTON.VARIANT_DARK,
    BUTTON.VARIANT__PRIMARY_LIMIT_WITH,
    BUTTON.VARIANT__SECONDARY_LIMIT_WITH,
    BUTTON.VARIANT_DISABLED,
    BUTTON.VARIANT_PRIMARY_WITH_ICON,
    BUTTON.VARIANT_ONLY_ICON,
  ]),
};
