import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import ProductsListComponent from "./components/productList/ProductsListComponent";
import {
  PHYTOSANITARY__PRODUCTS__TITLE,
  PHYTOSANITARY__PRODUCTS__SEARCH,
  PHYTOSANITARY__PRODUCTS__SEARCH_REG,
  PHYTOSANITARY__PRODUCTS__NO__DATA,
  PHYTOSANITARY__FILTER__PLAGUES,
  PAGE_NEXT,
  PAGE_PREVIOUS,
} from "../../translations/constants";
import { INPUT } from "../common/atoms/input/inputConstants";
import TypographyComponent from "../common/atoms/typography/TypographyComponent";
import InputComponent from "../common/atoms/input/InputComponent";
import style from "./PhytosanitaryProductsComponent.module.css";
import { getPlaguesService, getPhytosanitaryProductsService } from "../../services/commonservices";
import withAuthorization from "../common/auth/withAuthorization";
import { NAME_PARAMETER, REG_NUM_PARAMETER, PLAGUE_PARAMETER, ROLES_ALL } from "../../utils/constants";
import FilterSelectComponent from "../common/molecules/filterSelect/FilterSelectComponent";
import { TEXT } from "../common/atoms/typography/typographyConstants";
import PRODUCTS_PAGE_SIZE from "./phytosanitaryConstants";
import { BUTTON } from "../common/atoms/button/buttonConstants";
import ButtonComponent from "../common/atoms/button/ButtonComponent";
import useCustomIntl from "../../hook/cutomHook";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const PhytosanitaryProductsComponent = () => {
  const { formatMessage } = useCustomIntl();

  const [key, setKey] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationParameters, setPaginationParameters] = useState({
    limit: PRODUCTS_PAGE_SIZE,
    offset: 0,
  });
  const [numberPages, setNumberPages] = useState(1);

  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState("");
  const [productReg, setProductReg] = useState("");
  const [plagues, setPlagues] = useState([]);
  const [filterPlagues, setFilterPlagues] = useState([]);

  const [filterParameters, setFilterParameters] = useState();

  const search = {
    id: "productsSearch",
    name: "productsSearch",
    type: INPUT.TYPE_SEARCH,
    value: productName,
    idTextPlaceholder: PHYTOSANITARY__PRODUCTS__SEARCH,
  };

  const searchReg = {
    id: "registerSearch",
    name: "registerSearch",
    type: INPUT.TYPE_SEARCH,
    value: productReg,
    idTextPlaceholder: PHYTOSANITARY__PRODUCTS__SEARCH_REG,
  };

  const handleSearch = (event) => {
    setProductName(event.target.value);
  };

  const handleSearchReg = (event) => {
    setProductReg(event.target.value);
  }

  const handleFilterPlagues = (plagues) => {
    setFilterPlagues(plagues);
  };

  const plaguesCallback = (response) => {
    setPlagues(response.data);
  };
  const getPlagues = () => {
    getPlaguesService({ include_not_active: true }, plaguesCallback);
  };

  const getProducts = () =>
    new Promise((resolve) => {
      getPhytosanitaryProductsService({ ...paginationParameters, ...filterParameters }, (result) => {
        resolve({ products: result.data, totalResults: result.headers["pagination-total-results"] });
      });
    });

  const clearFilterParams = (key) => {
    const newParams = { ...filterParameters };
    delete newParams[key];
    setFilterParameters(newParams);
  };

  useEffect(() => {
    !!filterPlagues.length
      ? setFilterParameters({ ...filterParameters, [PLAGUE_PARAMETER]: filterPlagues.map((pl) => pl.id) })
      : clearFilterParams(PLAGUE_PARAMETER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPlagues]);

  useEffect(() => {
    !!productName.length
      ? setFilterParameters({ ...filterParameters, [NAME_PARAMETER]: productName.toUpperCase() })
      : clearFilterParams(NAME_PARAMETER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productName]);

  useEffect(() => {
    !!productReg.length
      ? setFilterParameters({ ...filterParameters, [REG_NUM_PARAMETER]: productReg })
      : clearFilterParams(REG_NUM_PARAMETER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productReg]);

  useEffect(() => {
    getProducts().then((result) => {
      setProducts(result.products);
      setNumberPages(Math.ceil(parseInt(result.totalResults) / PRODUCTS_PAGE_SIZE));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParameters]);

  useEffect(() => {
    getPlagues();
    getProducts().then((result) => {
      setProducts(result.products);
      setNumberPages(Math.ceil(parseInt(result.totalResults) / PRODUCTS_PAGE_SIZE));
    });
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPage(1);
    // setPaginationParameters ({ limit: PRODUCTS_PAGE_SIZE, offset: PRODUCTS_PAGE_SIZE * (page - 1) });
    // if (!!key) {
    //   setKey(key + 1);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberPages]);

  useEffect(() => {
    setPaginationParameters({ limit: PRODUCTS_PAGE_SIZE, offset: PRODUCTS_PAGE_SIZE * (page - 1) });
    if (!!key) {
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (!!key) {
      getProducts().then((result) => {
        setProducts(result.products);
        setNumberPages(Math.ceil(parseInt(result.totalResults) / PRODUCTS_PAGE_SIZE));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const handlePagination = (value) => {
    const newPage = value ? page + 1 : page - 1;
    setPage(newPage);
  };

  return (
    <section className={style.phytosanitaryPage}>
      <TypographyComponent
        className={style.title}
        variant={TEXT.VARIANT_H2}
        text={<FormattedMessage id={PHYTOSANITARY__PRODUCTS__TITLE} />}
      />
      <section className={style.filtersContainer}>
        <div className={style.filters}>
          <div className={style.inputSearch}>
            <InputComponent data={search} onChange={handleSearch} />
          </div>
          <div className={style.inputSearch}>
            <InputComponent data={searchReg} onChange={handleSearchReg} />
          </div>
          <div className={style.inputSelect}>
            <FilterSelectComponent
              filterObjects={filterPlagues}
              objects={plagues}
              handleFilterObjects={(filterPlagues) => handleFilterPlagues(filterPlagues)}
              initialOption={true}
              defaultText={PHYTOSANITARY__FILTER__PLAGUES}
            />
          </div>
        </div>
        <div className={style.paginationContainer}>
          <div className={style.pageButtonContainer}>
            <ButtonComponent
              icon={<ArrowBackIosNewIcon />}
              hiddenIcon={true}
              text={formatMessage(PAGE_PREVIOUS)}
              variant={BUTTON.VARIANT_PRIMARY}
              onClick={() => handlePagination(false)}
              disabled={page === 1}
              hiddenTextMobileVersion={true}
            />
          </div>
          <div className={style.paginationText}>
            <TypographyComponent text={`${page} / ${numberPages}`} />
          </div>
          <div className={style.pageButtonContainer}>
            <ButtonComponent
              icon={<ArrowForwardIosIcon />}
              hiddenIcon={true}
              text={formatMessage(PAGE_NEXT)}
              variant={BUTTON.VARIANT_PRIMARY}
              onClick={() => handlePagination(true)}
              disabled={numberPages <= 1 || page === numberPages}
              hiddenTextMobileVersion={true}
            />
          </div>
        </div>
      </section>
      {!!products?.length ? (
        <ProductsListComponent products={products} />
      ) : (
        <FormattedMessage id={PHYTOSANITARY__PRODUCTS__NO__DATA} />
      )}
    </section>
  );
};

export default withAuthorization(ROLES_ALL)(PhytosanitaryProductsComponent);
