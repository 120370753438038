import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "../reducers/commonState";
import locationReducer from "../reducers/locationState";

export const store = configureStore({
  reducer: {
    common: commonReducer,
    location: locationReducer,
  },
});
