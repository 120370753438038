import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  COMMON__HIGH,
  COMMON__LOW,
  COMMON__MEDIUM,
  COMMON__PROTECTION_NONE,
  COMMON__RISK_NONE,
  COMMON__ACTION_ACCEPT,
  PROTECTION_HIGH,
  PROTECTION_LOW,
  PROTECTION_MEDIUM,
  OBSERVATION_FALSE,
  OBSERVATION_TRUE,
  MODAL_OBSERVATION_TITLE,
  MODAL_OBSERVATION_TEXT_ADD,
  MODAL_OBSERVATION_TEXT_DELETE,
  TOOLTIP_PROTECTION_LEVEL,
  TOOLTIP_RISK_LEVEL,
  TOOLTIP_OBSERVATION,
} from "../../../../../../../translations/constants";
import styles from "./TableRIsk.module.css";
import { PROTECTION_LEVEL, RISK_LEVEL } from "../../../../../../../utils/constants";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
//import { formatDate } from "../../../../../../../utils/utils";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import clsx from "clsx";
import { getCircleIcon } from "../../../../../../../images/icons/icons";
import { HIGH, MEDIUM, LOW, NONE, SIZE_BY_STATUS } from "../../../../../Constants";
import { formatTimestampToDate } from "../../../../../../../utils/utils";
import ModalComponent from "../../../../../../common/molecules/modal/ModalComponent";

const TableRiskComponent = ({ plagues, plotPlagueRisks, gridDays }) => {
  const intl = useIntl();

  const [plaguesObjs, setPlaguesObj] = useState(null);
  const [plagueId, setPlagueId] = useState(null);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const [tooltipRisk, setTooltipRisk] = useState();
  const [tooltipProtection, setTooltipProtection] = useState();
  const [tooltipObservation, setTooltipObservation] = useState();
  const [openModal, setOpenModal] = useState(false);
  //const [dateSelected, setDateSelected] = useState(null);
  //const [observationValue, setObservationValue] = useState(null);
  const formattedGridDays = [...gridDays.map((day) => formatTimestampToDate(day))];

  const getInfoTooltip = (riskDay) => {
    setPlagueId(riskDay.plague_id);
    const riskObject = {
      [HIGH]: intl.formatMessage({ id: COMMON__HIGH }),
      [MEDIUM]: intl.formatMessage({ id: COMMON__MEDIUM }),
      [LOW]: intl.formatMessage({ id: COMMON__LOW }),
      [NONE]: intl.formatMessage({ id: COMMON__RISK_NONE }),
    };
    setTooltipRisk(riskObject[riskDay.risk_level]);
    const protectionObject = {
      [HIGH]: intl.formatMessage({ id: PROTECTION_HIGH }),
      [MEDIUM]: intl.formatMessage({ id: PROTECTION_MEDIUM }),
      [LOW]: intl.formatMessage({ id: PROTECTION_LOW }),
      [NONE]: intl.formatMessage({ id: COMMON__PROTECTION_NONE }),
    };
    setTooltipProtection(protectionObject[riskDay.protection_level]);
    const observationObject = {
      0: intl.formatMessage({ id: OBSERVATION_FALSE }),
      1: intl.formatMessage({ id: OBSERVATION_TRUE }),
    };
    setTooltipObservation(observationObject[riskDay.observation]);
  };

  const findPlague = () => {
    const finalData = {};
    for (const plague of plagues) {
      finalData[plague.id] = { ...plague };
      for (const risk of plotPlagueRisks) {
        finalData[plague.id][formatTimestampToDate(risk.date)] = risk.plagues.find(
          (subPlague) => subPlague.plague_id === plague.id,
        );
      }
    }
    setPlaguesObj({ ...finalData });
  };

  useEffect(() => {
    findPlague();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotPlagueRisks, plagues]);

  const observationModal = (
    <div className={styles.modalContent}>
      <TypographyComponent
        variant={TEXT.VARIANT_P}
        text={`${
          true
            ? intl.formatMessage(
                { id: MODAL_OBSERVATION_TEXT_ADD },
                /*{ date: formatDate(dateSelected) },*/
              )
            : intl.formatMessage(
                { id: MODAL_OBSERVATION_TEXT_DELETE },
                /*{ date: formatDate(dateSelected) },*/
              )
        }`}
      />
    </div>
  );

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleObservationChange() {
    //console.log(`Trying to change observation from day ${dateSelected} to ${!observationValue}`);
    setOpenModal(false);
  }

  /*
  function handleDayClick(day, observation) {
    setOpenModal(true);
    setDateSelected(day);
    setObservationValue(observation);
  }
  */

  return (
    <>
      <div className={styles.tableRiskGeneralContainer}>
        {plagues.map((plague, index) => {
          return (
            <div className={clsx(styles.tableRiskContainer)} key={plague.id}>
              <div className={clsx(styles.plagueDataCell, styles.plagueDataHeader)}>{plague.name}</div>
              {!!Object.keys(plaguesObjs)?.length &&
                formattedGridDays?.map((day) => {
                  if (!plaguesObjs[plague.id]) {
                    return (
                      <div key={plague.id + day} className={styles.tableRiskIcon}>
                        {"-"}
                      </div>
                    );
                  }
                  const riskDay = plaguesObjs[plague.id][day];
                  return (
                    <div
                      key={plague.id + day}
                      className={clsx(styles.plagueDataCell, styles[`plagueDataCell-${riskDay?.[PROTECTION_LEVEL]}`])}
                      onMouseEnter={() =>
                        !!riskDay?.plague_id ? (getInfoTooltip(riskDay), setDisplayTooltip(day)) : null
                      }
                      onMouseLeave={() => setDisplayTooltip(null)}
                    >
                      {displayTooltip === day && riskDay?.plague_id === plagueId && (
                        <span className={styles.tooltip}>
                          <p>
                            {`${intl.formatMessage({ id: TOOLTIP_RISK_LEVEL })}`}: {tooltipRisk}
                          </p>
                          <p>
                            {intl.formatMessage({ id: TOOLTIP_PROTECTION_LEVEL })}: {tooltipProtection}
                          </p>
                          <p>
                            {intl.formatMessage({ id: TOOLTIP_OBSERVATION })}: {tooltipObservation}
                          </p>
                        </span>
                      )}
                      <div className={clsx(styles.plagueDataCell_base, riskDay?.observation === 1 && styles.plagueDataCell_observed)}>
                        {!!riskDay?.plague_id ? (
                          <div className={clsx(styles.tableRiskIcon, styles.hoverIcon)}>
                            <div
                              className={clsx(
                                styles.iconContainerRiskLevelsLegend,
                                styles[`tableRiskIcon-${riskDay[RISK_LEVEL]}`],
                              )}
                            >
                              {riskDay[RISK_LEVEL] !== NONE && riskDay[RISK_LEVEL] !== null
                                ? getCircleIcon(
                                    `tableRiskIcon-${riskDay.risk_level}`,
                                    SIZE_BY_STATUS[riskDay[PROTECTION_LEVEL]],
                                  )
                                : "-"}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.tableRiskIcon}>{"-"}</div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
        {openModal && (
          <ModalComponent
            body={observationModal}
            handleClose={handleCloseModal}
            title={intl.formatMessage({ id: MODAL_OBSERVATION_TITLE })}
            buttonAcceptText={intl.formatMessage({ id: COMMON__ACTION_ACCEPT })}
            handleSave={() => {
              handleObservationChange();
            }}
          />
        )}
      </div>
    </>
  );
};

export default TableRiskComponent;
