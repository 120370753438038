import React from "react";
import { Delete } from "@mui/icons-material";

import TypographyComponent from "../typography/TypographyComponent";
import { TEXT } from "../typography/typographyConstants";
import style from "./Tag.module.css";

const TagComponent = ({ text, onClick }) => {
  const onHandleDelete = () => {
    onClick();
  };

  return (
    <div className={style.container}>
      <div className={`${style.text} ${style.maxHeigh}`}>
        <TypographyComponent variant={TEXT.VARIANT_SPAN} text={text} />
      </div>
      <div className={style.button} onClick={onHandleDelete}>
        <Delete />
      </div>
    </div>
  );
};

export default TagComponent;
