import Keycloak from "keycloak-js";
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from "./constants";

const keycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};
const keycloak = new Keycloak(keycloakConfig);

export const hasRole = (role) => (!!keycloak ? keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role) : false);

export const hasRoles = (roles) => {
  return !!keycloak ? roles.some((r) => keycloak.hasRealmRole(r) || keycloak.hasResourceRole(r)) : false;
};

export default keycloak;
