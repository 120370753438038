import React from "react";
import PropTypes from "prop-types";
import TypographyComponent from "../../atoms/typography/TypographyComponent";
import { TEXT } from "../../atoms/typography/typographyConstants";
import style from "./Modal.module.css";
import { Close } from "@mui/icons-material";
import { BUTTON } from "../../atoms/button/buttonConstants";
import ButtonComponent from "../../atoms/button/ButtonComponent";
import { useIntl } from "react-intl";
import { COMMON__ACTION_CLOSE } from "../../../../translations/constants";

function ModalComponent({ title, body, handleClose, buttonAcceptText, handleSave = false, displayCloseButton = true, slim = false }) {
  const intl = useIntl();

  return (
    <section className={style.over}>
      <div className={`${style.container} ${slim && style.containerSlim}`}>
        <div className={style.header}>
          <TypographyComponent text={title} variant={TEXT.VARIANT_H2} />
          <Close onClick={handleClose} className={style.close} />
        </div>
        <div className={style.modalContent}>{body}</div>
        <div className={style.buttonContainer}>
          {!!buttonAcceptText && !!handleSave && (
            <ButtonComponent
              text={buttonAcceptText}
              variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
              onClick={handleSave}
            />
          )}
          {displayCloseButton && (
            <ButtonComponent
              text={intl.formatMessage({ id: COMMON__ACTION_CLOSE })}
              variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
              onClick={handleClose}
            />
          )}
        </div>
      </div>
    </section>
  );
}

ModalComponent.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  buttonAcceptText: PropTypes.string,
};

ModalComponent.defaultProps = {
  handleSave: undefined,
  buttonAcceptText: "",
};

export default ModalComponent;
