import React, { useEffect, useState } from "react";
import FilterBar from "../components/filterBar/FilterBar";
import ParcelsListComponent from "../components/parcelList/ParcelListComponent";
import {
  CELLAR_ID_PARAMETER,
  VARIETY_PARAMETER,
  ZONE_PARAMETER,
  NAME_PARAMETER,
  ROLE_AGACAL_BASIC_USER,
  ERROR_TYPE_REQUIRED,
  ERROR_TYPE_OPTIONALITY,
  HTTP_CODE_409,
} from "../../../../utils/constants";
import style from "../Parcels.module.css";
import { DEFAULT_FILTER_PARAMETERS } from "../parcelsConstants";
import { INITIAL_MAP_CONFIG } from "../components/maps/mapConstants";
import { getPlotsService, postCreatePlotService } from "../../../../services/commonservices";
import PrivateMapComponent from "../components/maps/privateMap/PrivateMapComponent";
import { hasRole } from "../../../../utils/keycloak";
import {
  COMMON__ERROR_MESSAGE_PARCEL_ALREADY_EXISTS,
  COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
  CREATE_PARCEL_FORM__TITLE,
  PARCELS_MANAGEMENT__CREATE,
  PAGE_NEXT,
  PAGE_PREVIOUS,
} from "../../../../translations/constants";
import { BUTTON } from "../../../common/atoms/button/buttonConstants";
import ButtonComponent from "../../../common/atoms/button/ButtonComponent";
import { useIntl } from "react-intl";
import useCustomIntl from "../../../../hook/cutomHook";
import AddIcon from "@mui/icons-material/Add";
import ModalComponent from "../../../common/molecules/modal/ModalComponent";
import CreateParcelFormComponent from "../../../administration/manageParcels/components/createParcelForm/CreateParcelFormComponent";
import { coordinatesToDecimal, filterObject } from "../../../../utils/utils";
import {
  BASIC_PLOT_SCHEMA,
  CREATE_PLOT_ERROR_FIELD,
} from "../../../administration/manageParcels/manageParcelsConstants";
import {
  COORDINATES_DEGREES,
  COORDINATES_MINUTES,
  COORDINATES_SECONDS,
} from "../../../common/atoms/coordinatesInput/coordinatesInputConstants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import TypographyComponent from "../../../common/atoms/typography/TypographyComponent";
import { HEADERS_FIELD_TOTAL_RESULTS, PARCELS_PAGE_SIZE } from "../constants";

const PrivateParcelsComponent = () => {

  const { formatMessage } = useCustomIntl();
  const intl = useIntl();
  const [parcels, setParcels] = useState([]);
  const [parcelsList, setParcelsList] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedVariety, setSelectedVariety] = useState("");
  const [selectedCellar, setSelectedCellar] = useState("");
  const [parcelName, setParcelName] = useState("");
  
  const [page, setPage] = useState(1);
  const [numberPages, setNumberPages] = useState(1);

  const [displayCreateModal, setDisplayCreateModal] = useState(false);

  const handleFilterZone = (event) => {
    setSelectedZone(event.target.value);
  };

  const handleFilterVarietyGrapes = (event) => {
    setSelectedVariety(event.target.value);
  };

  const handleFilterCellars = (event) => {
    setSelectedCellar(event.target.value);
  };

  const handleSearch = (event) => {
    setParcelName(event.target.value);
  };

  const parcelServicePaginatedCallback = (response) => {
    setNumberPages(Math.ceil(response.headers[HEADERS_FIELD_TOTAL_RESULTS] / PARCELS_PAGE_SIZE));
    setParcelsList(response.data);
  };

  const parcelServiceAllCallback = (response) => {
    setParcels(response.data);
  };

  const addParameter = (parameters, parameter, value) => {
    return { ...parameters, [parameter]: value };
  };

  const searchParcels = (paginationParameters = undefined) => {
    let parameters = {...DEFAULT_FILTER_PARAMETERS};
    if (!!selectedZone) {
      parameters = addParameter(parameters, ZONE_PARAMETER, selectedZone);
    }
    if (!!selectedVariety) {
      parameters = addParameter(parameters, VARIETY_PARAMETER, selectedVariety);
    }
    if (!!selectedCellar) {
      parameters = addParameter(parameters, CELLAR_ID_PARAMETER, selectedCellar);
    }
    if (!!parcelName) {
      parameters = addParameter(parameters, NAME_PARAMETER, parcelName.toUpperCase());
    }
    if (!!paginationParameters) {
      parameters = { ...parameters, ...paginationParameters };
      getPlotsService(parameters, parcelServicePaginatedCallback);
    } else {
      getPlotsService(parameters, parcelServiceAllCallback);
    }
  };

  useEffect(() => {
    setPage(1);
    searchParcels({ limit: PARCELS_PAGE_SIZE, offset: 0 });
    searchParcels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCellar, selectedVariety, selectedZone, parcelName]);

  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /* BLOQUE 1 */
  const [name, setName] = useState("");

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  /* BLOQUE 2 */
  const [variety, setVariety] = useState("");
  const [conduction, setConduction] = useState("");
  const [maintenance, setMaintenance] = useState("");
  const [density, setDensity] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [irrigation, setIrrigation] = useState("");

  const handleSetVariety = (e) => {
    setVariety(e.target.value);
  };

  const handleSetConduction = (e) => {
    setConduction(e.target.value);
  };

  const handleSetMaintenance = (e) => {
    setMaintenance(e.target.value);
  };

  const handleSetDensity = (e) => {
    setDensity(+e.target.value);
  };

  const handleSetYear = (e) => {
    setYear(+e.target.value);
  };

  const handleSetIrrigation = (e) => {
    setIrrigation(e.target.value);
  };

  /* BLOQUE 3 */
  const [zone, setZone] = useState("");
  const [location, setLocation] = useState("");
  const [surface, setSurface] = useState(undefined);
  const [orientation, setOrientation] = useState("");

  const handleSetZone = (e) => {
    setZone(e.target.value);
  };

  const handleSetLocation = (e) => {
    setLocation(e.target.value);
  };

  const handleSetSurface = (e) => {
    setSurface(e.target.value);
  };

  const handleSetOrientation = (e) => {
    setOrientation(e.target.value);
  };

  /* BLOQUE 4 */
  const [province, setProvince] = useState("");
  const [council, setCouncil] = useState("");
  const [station, setStation] = useState(undefined);

  const [latitude, setLatitude] = useState({
    [COORDINATES_DEGREES]: undefined,
    [COORDINATES_MINUTES]: undefined,
    [COORDINATES_SECONDS]: undefined,
  });
  const [longitude, setLongitude] = useState({
    [COORDINATES_DEGREES]: undefined,
    [COORDINATES_MINUTES]: undefined,
    [COORDINATES_SECONDS]: undefined,
  });
  const [altitude, setAltitude] = useState(undefined);

  const handleSetProvince = (e) => {
    setProvince(e.target.value);
  };

  const handleSetCouncil = (e) => {
    setCouncil(e.target.value);
  };

  const handleSetStation = (e) => {
    setStation(e.target.value);
  };

  const handleSetLatitude = (value) => {
    setLatitude(value);
  };

  const handleSetLongitude = (value) => {
    setLongitude(value);
  };

  const handleSetAltitude = (e) => {
    setAltitude(+e.target.value);
  };

  const handleOpenCreateModal = () => {
    setDisplayCreateModal(!displayCreateModal);
    if (displayCreateModal) {
      setName("");

      setVariety("");
      setConduction("");
      setMaintenance("");
      setDensity(undefined);
      setYear(undefined);
      setIrrigation("");

      setZone("");
      setLocation("");
      setSurface(undefined);
      setOrientation("");

      setProvince("");
      setCouncil(undefined);
      setStation(undefined);
      setLatitude({
        [COORDINATES_DEGREES]: undefined,
        [COORDINATES_MINUTES]: undefined,
        [COORDINATES_SECONDS]: undefined,
      });
      setLongitude({
        [COORDINATES_DEGREES]: undefined,
        [COORDINATES_MINUTES]: undefined,
        [COORDINATES_SECONDS]: undefined,
      });
      setAltitude(undefined);
    }
  };

  const createParcelCallback = () => {
    handleOpenCreateModal();
    searchParcels({ limit: PARCELS_PAGE_SIZE, offset: 0 });
  };

  const createParcelErrorCallback = (error) => {
    if (error.response.status === HTTP_CODE_409) {
      setErrorMessage(`*${intl.formatMessage({ id: COMMON__ERROR_MESSAGE_PARCEL_ALREADY_EXISTS })}`);
      setDisplayError(true);
    }
  };

  const handleCreateParcel = () => {
    const body = {
      name,
      location,
      area: surface,
      orientation,
      variety,
      conduction,
      land_maintenance: maintenance,
      density: density,
      year: year,
      irrigation,
      council_id: +council || undefined,
      meteo_id: +station || undefined,
      geolocation: {
        latitude: coordinatesToDecimal(latitude),
        longitude: coordinatesToDecimal(longitude),
        altitude: altitude,
      },
      zone,
    };

    const formattedBody = filterObject(body);

    BASIC_PLOT_SCHEMA.validate(formattedBody)
      .then(() => {
        postCreatePlotService(formattedBody, createParcelCallback, createParcelErrorCallback);
        setDisplayError(false);
        setErrorMessage("");
      })
      .catch((error) => {
        if (error.type === ERROR_TYPE_REQUIRED || error.type === ERROR_TYPE_OPTIONALITY) {
          setErrorMessage(
            `*${intl.formatMessage(
              {
                id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
              },
              { field: intl.formatMessage({ id: CREATE_PLOT_ERROR_FIELD[error.path] }).toLowerCase() },
            )}`,
          );
          setDisplayError(true);
        }
      });
  };

  const handlePagination = (value) => {
    const newPage = value ? page + 1 : page - 1;
    setPage(newPage);
  };

  useEffect(() => {
    searchParcels({ limit: PARCELS_PAGE_SIZE, offset: PARCELS_PAGE_SIZE * (page - 1) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <section className={style.container}>
      <PrivateMapComponent
        center={[parseFloat(INITIAL_MAP_CONFIG.INITIAL_LONGITUDE), parseFloat(INITIAL_MAP_CONFIG.INITIAL_LATITUDE)]}
        zoom={INITIAL_MAP_CONFIG.INITIAL_ZOOM}
        parcels={parcels}
      />
      <section className={style.filterContainer}>
        {hasRole(ROLE_AGACAL_BASIC_USER) ? (
           <div className={style.noParcelsContainer}>
              <FilterBar
                  handleFilterZone={handleFilterZone}
                  handleFilterVarietyGrapes={handleFilterVarietyGrapes}
                  selectedZone={selectedZone}
                  selectedCellar={selectedCellar}
                  selectedGrape={selectedVariety}
                  handleFilterCellars={handleFilterCellars}
                  handleSearch={handleSearch}
              />
              <ButtonComponent
                  icon={<AddIcon />}
                  text={intl.formatMessage({ id: PARCELS_MANAGEMENT__CREATE })}
                  variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
                  onClick={() => setDisplayCreateModal(true)}
                  hiddenTextMobileVersion
                  className={style.buttonClass}
              />
            </div>
        ) : <FilterBar
                handleFilterZone={handleFilterZone}
                handleFilterVarietyGrapes={handleFilterVarietyGrapes}
                selectedZone={selectedZone}
                selectedCellar={selectedCellar}
                selectedGrape={selectedVariety}
                handleFilterCellars={handleFilterCellars}
                handleSearch={handleSearch}
            />
        }
        {parcelsList?.length > 0 && (
          <>
            <ParcelsListComponent parcels={parcelsList} />
            
            <div className={style.paginationContainer}>
              <div className={style.pageButtonContainer}>
                <ButtonComponent
                  icon={<ArrowBackIosNewIcon />}
                  hiddenIcon={true}
                  text={formatMessage(PAGE_PREVIOUS)}
                  variant={BUTTON.VARIANT_PRIMARY}
                  onClick={() => handlePagination(false)}
                  disabled={page === 1}
                  hiddenTextMobileVersion={true}
                />
              </div>
              <div className={style.paginationText}>
                <TypographyComponent text={`${page} / ${numberPages}`} />
              </div>
              <div className={style.pageButtonContainer}>
                <ButtonComponent
                  icon={<ArrowForwardIosIcon />}
                  hiddenIcon={true}
                  text={formatMessage(PAGE_NEXT)}
                  variant={BUTTON.VARIANT_PRIMARY}
                  onClick={() => handlePagination(true)}
                  disabled={numberPages <= 1 || page === numberPages}
                  hiddenTextMobileVersion={true}
                />
              </div>
            </div>
          </>
        )}
        {displayCreateModal && (
          <ModalComponent
            body={
              /*<ParcelForm />*/
              <CreateParcelFormComponent
                name={name}
                handleSetName={handleSetName}
                variety={variety}
                handleSetVariety={handleSetVariety}
                conduction={conduction}
                handleSetConduction={handleSetConduction}
                maintenance={maintenance}
                handleSetMaintenance={handleSetMaintenance}
                density={density}
                handleSetDensity={handleSetDensity}
                year={year}
                handleSetYear={handleSetYear}
                irrigation={irrigation}
                handleSetIrrigation={handleSetIrrigation}
                zone={zone}
                handleSetZone={handleSetZone}
                location={location}
                handleSetLocation={handleSetLocation}
                surface={surface}
                handleSetSurface={handleSetSurface}
                orientation={orientation}
                handleSetOrientation={handleSetOrientation}
                province={province}
                handleSetProvince={handleSetProvince}
                council={council}
                handleSetCouncil={handleSetCouncil}
                station={station}
                handleSetStation={handleSetStation}
                latitude={latitude}
                handleSetLatitude={handleSetLatitude}
                longitude={longitude}
                handleSetLongitude={handleSetLongitude}
                altitude={altitude}
                handleSetAltitude={handleSetAltitude}
                handleCreateParcel={handleCreateParcel}
                displayError={displayError}
                errorMessage={errorMessage}
                createParcel
                basicForm={hasRole(ROLE_AGACAL_BASIC_USER)}
              />
            }
            handleClose={handleOpenCreateModal}
            title={intl.formatMessage({ id: CREATE_PARCEL_FORM__TITLE })}
            displayCloseButton={false}
          />
        )}
      </section>
    </section>
  );
};

export default PrivateParcelsComponent;
