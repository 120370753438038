import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../../../../common/atoms/button/ButtonComponent";
import style from "./weatherNavigationComponent.module.css";
import { BUTTON } from "../../../../../../common/atoms/button/buttonConstants";
import { WEEK_AFTER, WEEK_BEFORE } from "../../../../../../../translations/constants";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DateRangeIcon from "@mui/icons-material/DateRange";

function WeatherNavigationComponent({ handleChangePositionDays, positionDay, dat }) {
  const { formatMessage } = useCustomIntl();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const NUMBER_DAY_STOP = 0;

  useEffect(() => {
    if (positionDay > NUMBER_DAY_STOP && buttonDisabled) {
      setButtonDisabled(false);
    } else {
      if (!buttonDisabled && positionDay === 0) {
        setButtonDisabled(true);
      } else if (buttonDisabled && positionDay !== 0) {
        setButtonDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionDay]);

  return (
    <div className={style.buttonGroup}>
      <div>
        <ButtonComponent
          icon={
            <>
              <DateRangeIcon />
              <ArrowForwardIosIcon />
            </>
          }
          hiddenTextMobileVersion={true}
          hiddenIcon={true}
          text={formatMessage(WEEK_AFTER)}
          variant={BUTTON.VARIANT__PRIMARY_BOTTOM_ALIGNED}
          onClick={() => handleChangePositionDays(7)}
          disabled={buttonDisabled}
        />
      </div>
      <div className={style.separte}></div>
      <div>
        <ButtonComponent
          icon={
            <>
              <ArrowBackIosNewIcon />
              <DateRangeIcon />
            </>
          }
          hiddenTextMobileVersion={true}
          hiddenIcon={true}
          text={formatMessage(WEEK_BEFORE)}
          variant={BUTTON.VARIANT__PRIMARY_BOTTOM_ALIGNED}
          onClick={() => handleChangePositionDays(-7)}
        />
      </div>
    </div>
  );
}

export default WeatherNavigationComponent;
