import React from "react";
// import WeatherDetailComponent from "./WeatherDetailComponent";

const CarrouselWeatherComponent = () => {
  /*const [weatherData, setWeatherData] = useState([]);

  const handleSelectWeatherData = (weatherDataSelected) => {
     setWeatherData(weatherDataSelected);
  };

  const cardCarrousel = (option) => {
    return (
      <div key={option.id} onClick={handleSelectWeatherData(this)}>
        <div>{option.date}</div>
        <div>{option.icon}</div>
        <div>{option.degrees}</div>
        <div>{option.wind}</div>
      </div>
    );
  };

  const handlePreviousImage = () => {
    console.log("Movimiento izquierda");
  };

  const handleNextImage = () => {
    console.log("Movimiento derecha");
  };*/

  return (
    <>
      {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button onClick={handlePreviousImage}>{'<'}</button>
                {options.map( option =>
                    cardCarrousel(option))
                }
                <button onClick={handleNextImage}>{'>'}</button>
            </div>
            { weatherData.length > 0 &&
                <WeatherDetailComponent weatherData={weatherData} />
            }*/}
    </>
  );
};

export default CarrouselWeatherComponent;
