import React, { useEffect, useState } from "react";
import { ChevronLeftOutlined, ChevronRightOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";
import style from "./TablePro.module.css";
import { headMok, rowMock } from "./tableProConstants";
import clsx from "clsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TablePro = ({
  head,
  rows,
  dropDown,
  actionButtons,
  numberItemsPage,
  numberItemsTotal,
  height,
  displayableTooltip,
  handleChangePage,
  handleOrder,
}) => {
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(1);
  const [numberPages, setNumberPages] = useState(1);
  const [currentRows, setCurrentRows] = useState([]);
  const [order, setOrder] = useState({});
  const [orderParam, setOrderParam] = useState({});
  const [displayTooltip, setDisplayTooltip] = useState(false);

  useEffect(() => {
    setNumberPages(Math.ceil(numberItemsTotal / numberItemsPage));
  }, [numberItemsPage, numberItemsTotal]);

  useEffect(() => {
    setCurrentRows(rows?.slice(0, numberItemsPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const generateColumnTemplate = (head) => {
    const columnSizes = head.map((column) => column?.width || "1fr").join(" ");
    return `${columnSizes} ${!!actionButtons ? "minmax(190px,1fr)" : ""}`;
  };

  const handleDropDown = (index) => {
    setOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };

  const handlePagination = (value) => {
    const newPage = value ? page + 1 : page - 1;
    setPage(newPage);
  };

  const sortResults = (fieldOrder) => {
    setOrder({ fieldOrder: fieldOrder, ascendant: !order.ascendant });
  };

  useEffect(() => {
    if (!!order.fieldOrder) {
      Object.keys(order).length > 0 && order.ascendant
        ? setOrderParam({ [order.fieldOrder]: "asc" })
        : setOrderParam({ [order.fieldOrder]: "desc" });
    } else {
      setOrderParam({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    handleOrder(orderParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderParam]);

  return (
    <>
      <div className={style.container}>
        <div className={style.head} style={{ gridTemplateColumns: `${generateColumnTemplate(head)}` }}>
          {!!dropDown && <div />}
          {head.map((item, index) => (
            <div
              key={`head-${item?.title}-${index}`}
              className={`${style.column} ${style.headItem}`}
              onClick={() => sortResults(item.fieldOrder)}
            >
              {order.fieldOrder === item.fieldOrder ? (
                order.ascendant ? (
                  <span className={style.headerColumnContainer}>
                    {" "}
                    {item.title} <KeyboardArrowUpIcon />
                  </span>
                ) : (
                  <span className={style.headerColumnContainer}>
                    {" "}
                    {item.title} <KeyboardArrowDownIcon />
                  </span>
                )
              ) : (
                item.title
              )}
            </div>
          ))}
          {!!actionButtons ? <div /> : null}
        </div>
        <div className={style.body} style={{ height }}>
          {currentRows?.map((row, index) => (
            <div key={`row-${row?.id || index}`}>
              <div className={style.row} style={{ gridTemplateColumns: `${generateColumnTemplate(head)}` }}>
                {!!dropDown && (
                  <div className={style.expandMore} onClick={() => handleDropDown(index)}>
                    {!open[index] ? <ExpandMore /> : <ExpandLess />}
                  </div>
                )}

                {head
                  .map((column) => column.field)
                  ?.map((cell) => (
                    <div className={clsx(style.columnContainer, style.hoverIcon)} key={`column-${cell}`}>
                      <div
                        style={{ width: cell.width }}
                        className={style.column}
                        onMouseEnter={(e) => e.target?.clientWidth < e.target?.scrollWidth && setDisplayTooltip(cell)}
                        onMouseLeave={() => setDisplayTooltip(null)}
                      >
                        {row[cell] || " - "}
                      </div>
                      {displayableTooltip && !!displayTooltip ? <div className={style.tooltip}>{row[cell]}</div> : null}
                    </div>
                  ))}
                {actionButtons(row)}
              </div>
              {!!dropDown && open[index] ? <div className={style.dropDown}>{dropDown(row)}</div> : null}
            </div>
          ))}
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.pagination}>
          <ChevronLeftOutlined
            className={page === 1 ? style.disable : ""}
            onClick={() => {
              handlePagination(false);
              handleChangePage(false);
            }}
          />
          {`${page} / ${numberPages}`}
          <ChevronRightOutlined
            className={page === numberPages || numberPages === 1 ? style.disable : ""}
            onClick={() => {
              handlePagination(true);
              handleChangePage(true);
            }}
          />
        </div>
      </div>
    </>
  );
};

TablePro.propTypes = {
  head: PropTypes.arrayOf(PropTypes.any),
  rows: PropTypes.arrayOf(PropTypes.any),
  dropDown: PropTypes.element,
  actionButtons: PropTypes.func,
  numberItemsPage: PropTypes.number,
  numberItemsTotal: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getRows: PropTypes.func,
  displayableTooltip: PropTypes.bool,
  previousPage: PropTypes.bool,
  nextPage: PropTypes.bool,
};

TablePro.defaultProps = {
  head: headMok,
  rows: rowMock,
  dropDown: undefined,
  numberItemsPage: 6,
  numberItemsTotal: undefined,
  height: "fit-content",
  getRows: undefined,
  actionButtons: undefined,
  displayableTooltip: true,
};

export default TablePro;
