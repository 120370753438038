import { array, date, object, string } from "yup";
import {
  CREATE_TREATMENT_FORM__INPUT__DATE,
  CREATE_TREATMENT_FORM__INPUT__NOTES,
  CREATE_TREATMENT_FORM__INPUT__PLAGUE__IDS,
  CREATE_TREATMENT_FORM__INPUT__PLOT__IDS,
  CREATE_TREATMENT_FORM__INPUT__PRODUCT__IDS,
} from "../../../../../../../translations/constants";

export const SELECT_FIELD_LABEL = "name";
export const SELECT_FIELD_CODE = "code";
export const SELECT_FIELD_VALUE = "id";

export const TREATMENT_SCHEMA = object({
  date: date().required(),
  notes: string(),
  plot_ids: array().min(1).required(),
  plague_ids: array().min(1).required(),
  product_ids: array().min(1).required(),
});

export const CREATE_TREATMENT_ERROR_FIELD = {
  date: CREATE_TREATMENT_FORM__INPUT__DATE,
  notes: CREATE_TREATMENT_FORM__INPUT__NOTES,
  plot_ids: CREATE_TREATMENT_FORM__INPUT__PLOT__IDS,
  plague_ids: CREATE_TREATMENT_FORM__INPUT__PLAGUE__IDS,
  product_ids: CREATE_TREATMENT_FORM__INPUT__PRODUCT__IDS,
};
