export const INPUT = {
  TYPE_PASSWORD: "password",
  TYPE_TEXT: "text",
  TYPE_TEXTAREA: "textArea",
  TYPE_SELECT: "select",
  TYPE_SEARCH: "search",
  TYPE_EMAIL: "email",
  TYPE_DATE: "date",
  TYPE_NUMBER: "number",
  TYPE_AUTOCOMPLETE: "autocomplete",
};

export const INPUT_VARIANT_SECONDARY = "secondary";
