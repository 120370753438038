import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import PrivateParcelsComponent from "./private/PrivateParcelsComponent";
import PublicParcelsComponent from "./public/PublicParcelsComponent";
import LoadingComponent from "../../common/loading/LoadingComponent";
import { PUBLIC_WEB_AVAILABLE } from "../../../utils/constants";

const ParcelsComponent = () => {
  const { keycloak, initialized } = useKeycloak();

  if (!!initialized && !!keycloak && keycloak.authenticated) {
    return <PrivateParcelsComponent />;
  } else if (!!keycloak && !initialized) {
    return <LoadingComponent open description />;
  } else {
    if (PUBLIC_WEB_AVAILABLE) {
      return <PublicParcelsComponent />;
    } else {
      keycloak.login();
    }
  }
};

export default ParcelsComponent;
