import React from "react";
import style from "./Select.module.css";
import PropTypes from "prop-types";
import clsx from "clsx";

const SelectComponent = ({
    showDescription=false,
  label,
  data,
  fieldLValue,
  fieldLabel,
  handleOnchange,
  initialOption,
  defaultValue,
  initialValue,
  initialText,
  variant,
  disabled,
  required,
  verticalPlacementLabel,
}) => {
  return (
    <div className={verticalPlacementLabel ? style.verticalPlacementLabel : ""}>
      {label && (
        <label className={style.label} htmlFor={label}>
          {`${label}${required ? "*" : ""}`}
        </label>
      )}
      <select
        className={clsx(style.select, style[variant])}
        onChange={handleOnchange}
        value={defaultValue}
        name={label}
        disabled={disabled}
      >
        {initialOption && (
          <option className={style.initialOption} value={initialValue}>
            {initialText}
          </option>
        )}
        {data?.map((item, index) => (
          <option className={style.option} key={`key-${index}`} value={!!fieldLValue ? item[fieldLValue] : item}>
            {!!fieldLabel ? item[fieldLabel] : item}
            {showDescription && `  ·  ${item.name}`}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectComponent.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  fieldLValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldLabel: PropTypes.string,
  handleOnchange: PropTypes.func,
  initialOption: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultText: PropTypes.string,
  variant: PropTypes.any,
  disabled: PropTypes.bool,
};

SelectComponent.defaultProps = {
  label: "",
  data: [],
  fieldLValue: undefined,
  fieldLabel: undefined,
  handleOnchange: undefined,
  initialOption: false,
  defaultValue: "",
  defaultText: "",
  disabled: false,
  verticalPlacementLabel: false,
};

export default SelectComponent;
