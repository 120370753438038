import style from "./CardWithPointer.module.css";
import ButtonComponent from "../../atoms/button/ButtonComponent";
import PointerComponent from "../../atoms/pointer/PointerComponent";
import { BUTTON } from "../../atoms/button/buttonConstants";

const CardWithPointerComponent = ({ data }) => {
  const {
    id,
    title,
    date,
    cardStatus,
    description,
    pointerStatus,
    textButton1,
    handlerButton1,
    textButton2,
    handlerButton2,
    handlerState,
    warning,
  } = data;

  return (
    <>
      {handlerState ? (
        <article className={`${style.container} ${!!cardStatus && style.isRead}`} onClick={() => handlerState(warning)}>
          <div className={style.warningPointer}>
            <PointerComponent status={pointerStatus} size={15} />
          </div>
          <div className={style.content}>
            <div className={style.header}>
              <h3> {title} </h3>
              <span> {date} </span>
            </div>
            <p> {description} </p>
          </div>
        </article>
      ) : (
        <article className={`${style.container} ${!!cardStatus ? style.isRead : ""}`}>
          <div className={style.warningPointer}>
            <PointerComponent status={pointerStatus} size={15} />
          </div>
          <div className={style.content}>
            <div className={style.header}>
              <h3> {title} </h3>
              <span> {date} </span>
            </div>
            <p> {description} </p>
            <div className={style.buttonsContainer}>
              <ButtonComponent
                text={textButton1}
                variant={BUTTON.VARIANT_SECONDARY}
                onClick={() => handlerButton1(id)}
              />
              {!cardStatus && (
                <ButtonComponent
                  text={textButton2}
                  variant={BUTTON.VARIANT_PRIMARY}
                  onClick={() => handlerButton2(id, title, description)}
                />
              )}
            </div>
          </div>
        </article>
      )}
    </>
  );
};

export default CardWithPointerComponent;
