import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "../../CreateParcelForm.module.css";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import InputComponent from "../../../../../../common/atoms/input/InputComponent";
import TextAreaComponent from "../../../../../../common/atoms/textArea/TextAreaComponent";
import {
  COMMON__INPUT__CODE,
  COMMON__INPUT__NAME,
  CREATE_PARCEL_FORM__INPUT__CELLAR,
  CREATE_PARCEL_FORM__INPUT__DESCRIPTION,
  CREATE_PARCEL_FORM__SECTION_INFO,
} from "../../../../../../../translations/constants";
import { INPUT, INPUT_VARIANT_SECONDARY } from "../../../../../../common/atoms/input/inputConstants";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import { getCellarsService } from "../../../../../../../services/commonservices";
import SelectComponent from "../../../../../../common/atoms/select/SelectComponent";
import { SELECT_FIELD_LABEL } from "../../../../../../parcels/parcelDetail/components/weatherDataComponent/components/parcelTitleComponent/parcelTitleConstants";
import { FIELD_ID } from "../../../../../../../utils/constants";

const InfoFormComponent = ({
  name,
  handleSetName,
  cellar,
  handleSetCellar,
  code,
  handleSetCode,
  description,
  handleSetDescription,
  createParcel,
  basicForm,
}) => {
  const { formatMessage } = useCustomIntl();

  const [cellars, setCellars] = useState([]);

  const nameInput = {
    id: 1,
    name: formatMessage(COMMON__INPUT__NAME),
    label: formatMessage(COMMON__INPUT__NAME),
    type: INPUT.TYPE_TEXT,
    value: name,
    onChange: handleSetName,
    required: createParcel,
  };

  const [cellarInput, setCellarInput] = useState({
    id: 2,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__CELLAR),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__CELLAR),
    type: INPUT.TYPE_TEXT,
    fieldLabel: SELECT_FIELD_LABEL,
    fieldLValue: FIELD_ID,
    initialOption: true,
    onChange: handleSetCellar,
    data: [],
    variant: INPUT_VARIANT_SECONDARY,
    defaultValue: "",
    defaultText: cellars.find((c) => c.id === cellar)?.name || "",
  });

  const cellarsCallback = (response) => {
    setCellars(response.data);
    setCellarInput({ ...cellarInput, data: response.data });
  };

  useEffect(() => {
    !basicForm && getCellarsService({ name_order: "asc" }, cellarsCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !basicForm && setCellarInput({ ...cellarInput, defaultValue: cellar });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellar]);

  const codeInput = {
    id: 3,
    name: formatMessage(COMMON__INPUT__CODE),
    label: formatMessage(COMMON__INPUT__CODE),
    type: INPUT.TYPE_TEXT,
    value: code,
    onChange: handleSetCode,
    disabled: !createParcel,
  };

  const descriptionInput = {
    id: 4,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__DESCRIPTION),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__DESCRIPTION),
    type: INPUT.TYPE_TEXTAREA,
    value: description,
    onChange: handleSetDescription,
  };

  return (
    <div className={styles.infoBlock}>
      <TypographyComponent text={formatMessage(CREATE_PARCEL_FORM__SECTION_INFO)} variant={TEXT.VARIANT_H3} />
      <div className={styles.infoRow}>
        <InputComponent data={nameInput} onChange={nameInput.onChange} value={nameInput.value} />
        {!basicForm && (
          <SelectComponent
            data={cellarInput.data}
            label={cellarInput.label}
            fieldLabel={cellarInput.fieldLabel}
            fieldLValue={cellarInput.fieldLValue}
            variant={cellarInput.variant}
            handleOnchange={cellarInput.onChange}
            initialOption={cellarInput.initialOption}
            defaultValue={cellarInput.defaultValue}
            defaultText={cellarInput.defaultText}
            verticalPlacementLabel
          />
        )}
        {!basicForm && <InputComponent data={codeInput} onChange={codeInput.onChange} value={codeInput.value} />}
      </div>
      {!basicForm && (
        <TextAreaComponent
          data={descriptionInput}
          onChange={descriptionInput.onChange}
          value={descriptionInput.value}
        />
      )}
    </div>
  );
};

InfoFormComponent.propTypes = {
  name: PropTypes.string.isRequired,
  handleSetName: PropTypes.func.isRequired,
  cellar: PropTypes.string,
  handleSetCellar: PropTypes.func,
  description: PropTypes.string,
  handleSetDescription: PropTypes.func,
};

export default InfoFormComponent;
