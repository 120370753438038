import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "../../CreateParcelForm.module.css";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import SelectComponent from "../../../../../../common/atoms/select/SelectComponent";
import InputComponent from "../../../../../../common/atoms/input/InputComponent";
import { SELECT_FIELD_LABEL } from "../../../../../../parcels/parcelDetail/components/weatherDataComponent/components/parcelTitleComponent/parcelTitleConstants";
import { INPUT, INPUT_VARIANT_SECONDARY } from "../../../../../../common/atoms/input/inputConstants";
import {
  getCouncilsService,
  getProvincesService,
  getStationsService,
} from "../../../../../../../services/commonservices";
import { FIELD_ID } from "../../../../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  storeCouncilsActionCreator,
  storeProvincesActionCreator,
  storeStationsActionCreator,
} from "../../../../../../../reducers/locationState";
import CoordinatesInput from "../../../../../../common/atoms/coordinatesInput/CoordinatesInput";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import {
  CREATE_PARCEL_FORM__INPUT__ALTITUDE,
  CREATE_PARCEL_FORM__INPUT__COUNCIL,
  CREATE_PARCEL_FORM__INPUT__LATITUDE,
  CREATE_PARCEL_FORM__INPUT__LONGITUDE,
  CREATE_PARCEL_FORM__INPUT__PROVINCE,
  CREATE_PARCEL_FORM__INPUT__WEATHER_STATION,
  CREATE_PARCEL_FORM__SECTION_GEOGRAPHY,
  CREATE_PARCEL_FORM__SECTION_GEOPOSITION,
  CREATE_PARCEL_FORM__TEXT,
} from "../../../../../../../translations/constants";

const GeographicFormComponent = ({
  province,
  handleSetProvince,
  council,
  handleSetCouncil,
  station,
  handleSetStation,
  latitude,
  handleSetLatitude,
  longitude,
  handleSetLongitude,
  altitude,
  handleSetAltitude,
  /*reference,
  handleSetReference,*/
  createParcel,
}) => {
  const { formatMessage } = useCustomIntl();
  const dispatch = useDispatch();

  const councils = useSelector((state) => state.location.councils);
  const stations = useSelector((state) => state.location.stations);

  const [provinceInput, setProvinceInput] = useState({
    id: 14,
    data: [],
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__PROVINCE),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetProvince,
    initialOption: true,
    defaultValue: province,
    defaultText: province,
    disabled: !createParcel,
    required: true,
  });

  const provincesCallback = (response) => {
    dispatch(storeProvincesActionCreator(response.data));
    setProvinceInput({ ...provinceInput, data: response.data });
  };

  useEffect(() => {
    getProvincesService(provincesCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [councilInput, setCouncilInput] = useState({
    id: 15,
    data: councils,
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__COUNCIL),
    fieldLabel: SELECT_FIELD_LABEL,
    fieldLValue: FIELD_ID,
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetCouncil,
    initialOption: true,
    defaultValue: council || "",
    defaultText: councils.find((c) => c.id === council)?.council_name || "",
    disabled: true,
    required: true,
  });

  const councilsCallback = (response) => {
    dispatch(storeCouncilsActionCreator(response.data));
    setCouncilInput({ ...councilInput, data: response.data, disabled: !createParcel });
  };

  useEffect(() => {
    setProvinceInput({ ...provinceInput, defaultValue: province });
    !!province && getCouncilsService(councilsCallback, province);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [province]);

  const [stationInput, setStationInput] = useState({
    id: 16,
    data: [],
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__WEATHER_STATION),
    fieldLabel: SELECT_FIELD_LABEL,
    fieldLValue: FIELD_ID,
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetStation,
    initialOption: true,
    defaultValue: station || "",
    defaultText: stations.find((s) => s.id === station)?.name || "",
    disabled: true,
    required: true,
  });

  const stationsCallback = (response) => {
    dispatch(storeStationsActionCreator(response.data));
    setStationInput({ ...stationInput, data: response.data, disabled: false });
  };

  useEffect(() => {
    setCouncilInput({ ...councilInput, defaultValue: council, defaultText: council?.council_name || "" });
    const parameters = { council_id: council };
    !!council && getStationsService(parameters, stationsCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [council]);

  useEffect(() => {
    setStationInput({ ...stationInput, defaultValue: station, defaultText: station?.name || "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station]);

  const latitudeInput = {
    id: 17,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__LATITUDE),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__LATITUDE),
    type: INPUT.TYPE_TEXT,
    value: latitude,
    onChange: handleSetLatitude,
    disabled: !createParcel,
  };

  const longitudeInput = {
    id: 18,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__LONGITUDE),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__LONGITUDE),
    type: INPUT.TYPE_TEXT,
    value: longitude,
    onChange: handleSetLongitude,
    disabled: !createParcel,
  };

  const altitudeInput = {
    id: 19,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__ALTITUDE),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__ALTITUDE),
    type: INPUT.TYPE_NUMBER,
    value: altitude,
    onChange: handleSetAltitude,
    disabled: !createParcel,
  };

  /*const referenceInput = {
    id: 20,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__REFERENCE),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__REFERENCE),
    type: INPUT.TYPE_TEXT,
    value: reference,
    onChange: handleSetReference,
    disabled: !createParcel,
  };*/

  return (
    <div className={styles.infoBlock}>
      <div className={styles.infoBlock}>
        <TypographyComponent text={formatMessage(CREATE_PARCEL_FORM__SECTION_GEOGRAPHY)} variant={TEXT.VARIANT_H3} />
        <div className={styles.geographicInputs}>
          <SelectComponent
            data={provinceInput.data}
            label={provinceInput.label}
            fieldLabel={provinceInput.fieldLabel}
            fieldLValue={provinceInput.fieldLValue}
            variant={provinceInput.variant}
            handleOnchange={provinceInput.onChange}
            initialOption={provinceInput.initialOption}
            defaultValue={provinceInput.defaultValue}
            defaultText={provinceInput.defaultText}
            verticalPlacementLabel
            disabled={provinceInput.disabled}
            required={provinceInput.required}
          />
          <SelectComponent
            data={councilInput.data}
            label={councilInput.label}
            fieldLabel={councilInput.fieldLabel}
            fieldLValue={councilInput.fieldLValue}
            variant={councilInput.variant}
            handleOnchange={councilInput.onChange}
            initialOption={councilInput.initialOption}
            defaultValue={councilInput.defaultValue}
            defaultText={councilInput.defaultText}
            disabled={councilInput.disabled}
            required={councilInput.required}
            verticalPlacementLabel
          />
          <SelectComponent
            data={stationInput.data}
            label={stationInput.label}
            fieldLabel={stationInput.fieldLabel}
            fieldLValue={stationInput.fieldLValue}
            variant={stationInput.variant}
            handleOnchange={stationInput.onChange}
            initialOption={stationInput.initialOption}
            defaultValue={stationInput.defaultValue}
            defaultText={stationInput.defaultText}
            disabled={stationInput.disabled}
            required={stationInput.required}
            verticalPlacementLabel
          />
        </div>
      </div>
      <div className={styles.infoBlock}>
        <TypographyComponent text={formatMessage(CREATE_PARCEL_FORM__SECTION_GEOPOSITION)} variant={TEXT.VARIANT_H3} />
        <div className={styles.geodispositionInputs}>
          <CoordinatesInput data={latitudeInput} onChange={latitudeInput.onChange} />
          <CoordinatesInput data={longitudeInput} onChange={longitudeInput.onChange} />
          <InputComponent data={altitudeInput} onChange={altitudeInput.onChange} value={altitudeInput.value} />
        </div>
      </div>
      {/*{
       <div className={styles.infoBlock}>
          <TypographyComponent
            text={formatMessage(CREATE_PARCEL_FORM__SECTION_CADASTRAL_REFERENCE)}
            variant={TEXT.VARIANT_H3}
          />
          <div className={styles.referenceInputs}>
            <InputComponent data={referenceInput} onChange={referenceInput.onChange} value={referenceInput.value} />
          </div>
        </div>
      }*/}
      <TypographyComponent text={formatMessage(CREATE_PARCEL_FORM__TEXT)} />
    </div>
  );
};

GeographicFormComponent.propTypes = {
  province: PropTypes.string.isRequired,
  handleSetProvince: PropTypes.func.isRequired,
  council: PropTypes.string,
  handleSetCouncil: PropTypes.func.isRequired,
  station: PropTypes.string.isRequired,
  handleSetStation: PropTypes.func.isRequired,
  latitude: PropTypes.objectOf(PropTypes.number).isRequired,
  handleSetLatitude: PropTypes.func.isRequired,
  longitude: PropTypes.objectOf(PropTypes.number).isRequired,
  handleSetLongitude: PropTypes.func.isRequired,
  altitude: PropTypes.string.isRequired,
  handleSetAltitude: PropTypes.func.isRequired,
  /*reference: PropTypes.string.isRequired,
  handleSetReference: PropTypes.func.isRequired,*/
};

export default GeographicFormComponent;
