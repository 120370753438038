import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import styles from "./CreateUserForm.module.css";
import {
  COMMON__BUTTONS__SAVE,
  CREATE_USER_FORM__INPUT__CELLAR_SELECTION,
  CREATE_USER_FORM__INPUT__EMAIL,
  CREATE_USER_FORM__INPUT__PARCEL_SELECTION,
  CREATE_USER_FORM__INPUT__PASSWORD,
  CREATE_USER_FORM__INPUT__PASSWORD_CONFIRM,
  CREATE_USER_FORM__INPUT__ROLE,
  CREATE_USER_FORM__INPUT__USERNAME,
  CREATE_USER_FORM__USER_INFO_SECTION,
} from "../../../../../translations/constants";
import { INPUT, INPUT_VARIANT_SECONDARY } from "../../../../common/atoms/input/inputConstants";
import useCustomIntl from "../../../../../hook/cutomHook";
import InputComponent from "../../../../common/atoms/input/InputComponent";
import SelectComponent from "../../../../common/atoms/select/SelectComponent";
import {
  ROLE_AGACAL_BASIC_USER,
  ROLE_AGACAL_SUPER_USER,
  ROLE_AGACAL_TECHNICAL_USER,
  ROLES_ALL,
} from "../../../../../utils/constants";
import FilterSelectComponent from "../../../../common/molecules/filterSelect/FilterSelectComponent";
import {getCellarsService, getPlotsService} from "../../../../../services/commonservices";
import TypographyComponent from "../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../common/atoms/typography/typographyConstants";
import ButtonComponent from "../../../../common/atoms/button/ButtonComponent";
import { BUTTON } from "../../../../common/atoms/button/buttonConstants";
import { hasRole } from "../../../../../utils/keycloak";

const CreateUserFormComponent = ({
  username,
  handleSetUsername,
  email,
  handleSetEmail,
  role,
  handleSetRole,
  password1,
  handleSetPassword1,
  password2,
  handleSetPassword2,
  selectedParcels,
  handleSetSelectedParcels,
  selectedCellars,
  handleSetSelectedCellars,
  displayError,
  errorMessage,
  handleCreateUser,
  createUser,
}) => {
  const intl = useIntl();
  const { formatMessage } = useCustomIntl();

  const [parcels, setParcels] = useState([]);

  const parcelsCallback = (response) => {
    setParcels(response.data);
  };

  const getParcels = () => {
    getPlotsService({}, parcelsCallback);
  };

  useEffect(() => {
    getParcels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cellars, setCellars] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellars]);

  const cellarsCallback = (response) => {
    setCellars(response.data);
  };

  const getCellars = () => {
    getCellarsService({},cellarsCallback);
  };

  useEffect(() => {
    getCellars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usernameInput = {
    id: 1,
    name: formatMessage(CREATE_USER_FORM__INPUT__USERNAME),
    label: formatMessage(CREATE_USER_FORM__INPUT__USERNAME),
    type: INPUT.TYPE_TEXT,
    value: username,
    onChange: handleSetUsername,
    required: createUser,
    disabled: !createUser,
  };

  const emailInput = {
    id: 2,
    name: formatMessage(CREATE_USER_FORM__INPUT__EMAIL),
    label: formatMessage(CREATE_USER_FORM__INPUT__EMAIL),
    type: INPUT.TYPE_TEXT,
    value: email,
    onChange: handleSetEmail,
    required: createUser,
    disabled: !createUser,
  };

  const roleInput = {
    id: 3,
    data: hasRole(ROLE_AGACAL_TECHNICAL_USER) ? [ROLE_AGACAL_BASIC_USER] : ROLES_ALL,
    label: formatMessage(CREATE_USER_FORM__INPUT__ROLE),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetRole,
    initialOption: false,
    defaultValue: role,
    defaultText: role,
    required: createUser,
  };

  const pass1Input = {
    id: 4,
    name: formatMessage(CREATE_USER_FORM__INPUT__PASSWORD),
    label: formatMessage(CREATE_USER_FORM__INPUT__PASSWORD),
    type: INPUT.TYPE_PASSWORD,
    value: password1,
    onChange: handleSetPassword1,
    required: createUser,
  };

  const pass2Input = {
    id: 5,
    name: formatMessage(CREATE_USER_FORM__INPUT__PASSWORD_CONFIRM),
    label: formatMessage(CREATE_USER_FORM__INPUT__PASSWORD_CONFIRM),
    type: INPUT.TYPE_PASSWORD,
    value: password2,
    onChange: handleSetPassword2,
    required: createUser,
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.infoBlock}>
        <TypographyComponent text={formatMessage(CREATE_USER_FORM__USER_INFO_SECTION)} variant={TEXT.VARIANT_H3} />
        <div className={styles.userInputs}>
          <InputComponent data={usernameInput} onChange={usernameInput.onChange} value={usernameInput.value} />
          <InputComponent data={emailInput} onChange={emailInput.onChange} value={emailInput.value} />
          <SelectComponent
            data={roleInput.data}
            label={roleInput.label}
            fieldLabel={roleInput.fieldLabel}
            fieldLValue={roleInput.fieldLValue}
            variant={roleInput.variant}
            handleOnchange={roleInput.onChange}
            initialOption={roleInput.initialOption}
            defaultValue={roleInput.defaultValue}
            defaultText={roleInput.defaultText}
            verticalPlacementLabel
            required={roleInput.required}
          />
          <InputComponent data={pass1Input} onChange={pass1Input.onChange} />
          <InputComponent data={pass2Input} onChange={pass2Input.onChange} />
        </div>
      </div>
      {role !== ROLE_AGACAL_SUPER_USER && (
        <div className={styles.infoBlock}>
          <TypographyComponent
            text={formatMessage(CREATE_USER_FORM__INPUT__PARCEL_SELECTION)}
            variant={TEXT.VARIANT_H3}
          />
          <FilterSelectComponent
            filterObjects={selectedParcels}
            objects={parcels}
            handleFilterObjects={handleSetSelectedParcels}
            initialOption={true}
            defaultText={CREATE_USER_FORM__INPUT__PARCEL_SELECTION}
          />
        </div>
      )}
      {!!role && !!hasRole(ROLE_AGACAL_SUPER_USER) && role !== ROLE_AGACAL_SUPER_USER && role !== ROLE_AGACAL_BASIC_USER &&(
        <div className={styles.infoBlock}>
          <TypographyComponent
            text={formatMessage(CREATE_USER_FORM__INPUT__CELLAR_SELECTION)}
            variant={TEXT.VARIANT_H3}
          />
          <FilterSelectComponent
            filterObjects={selectedCellars}
            objects={cellars}
            handleFilterObjects={handleSetSelectedCellars}
            initialOption={true}
            defaultText={CREATE_USER_FORM__INPUT__CELLAR_SELECTION}
          />
        </div>
      )}
      {displayError && <TypographyComponent className={styles.errorMessage} text={errorMessage} />}
      <div className={styles.buttonContainer}>
        <ButtonComponent
          text={intl.formatMessage({ id: COMMON__BUTTONS__SAVE })}
          variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
          onClick={handleCreateUser}
        />
      </div>
    </div>
  );
};

export default CreateUserFormComponent;
