export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  disable: {
    display: "none",
  },
  imageContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  description: {
    marginTop: theme.spacing(2),
    fontSize: 24,
  },
});
