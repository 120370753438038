import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import { styles } from "./LoadingComponentStyles";
import { COMMON__LOADING__DESCRIPTION } from "../../../translations/constants";
import LoadingSpinner from "./loadingSpinner/LoadingSpinner";

const useStyles = makeStyles((theme) => styles(theme));

export default function LoadingComponent({ description, open }) {
  const classes = useStyles();
  return (
    <div className={!open ? `${classes.disable} ${classes.root} pageContainer` : `${classes.root} pageContainer`}>
      <div className={classes.imageContainer}>
        <div className={classes.spinnerContainer}>
          <LoadingSpinner />
        </div>
        {description && (
          <p className={classes.description}>
            <FormattedMessage id={COMMON__LOADING__DESCRIPTION} />
          </p>
        )}
      </div>
    </div>
  );
}

LoadingComponent.propTypes = {
  description: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};
