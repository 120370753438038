export const BUTTON = {
  /*Textos */
  DEFAULT_TEXT: "Texto del boton",
  DELETE: "common__buttons__delete_advise",
  CLOSE: "parcels_list__close_map_legend",
  MARK_READ: "common__buttons__mark_as_read",
  FICHA_TECNICA: "phytosanitary__products__data__sheet",
  OPEN_LEGEND: "parcels_list__open_map_legend",
  CLOSE_LEGEND: "parcels_list__close_map_legend",
  MORE_INFO: "parcel_title__button__more_info",
  NEW_TREATMENT: "parcel_title__button__add_treatment",
  LEGEND: "parcel_title__button__legend",
  REGISTER: "common__buttons__register",
  /*Tipos */
  TYPE_BUTTON: "button",
  TYPE_SUBMIT: "submit",
  TYPE_RESET: "reset",
  /*Colors */
  VARIANT_PRIMARY: "primary",
  VARIANT_SECONDARY: "secondary",
  VARIANT_SUCCESS: "success",
  VARIANT_DANGER: "danger",
  VARIANT_WARNING: "warning",
  VARIANT_LIGHT: "light",
  VARIANT_DARK: "dark",
  VARIANT__PRIMARY_LIMIT_WITH: "primary_limitWith",
  VARIANT__SECONDARY_LIMIT_WITH: "secondary_limitWith",
  VARIANT__PRIMARY_BOTTOM_ALIGNED: "primary_bottomAligned",
  VARIANT_DISABLED: "disabled",
  VARIANT_ONLY_ICON: "only_icon",
  VARIANT_PRIMARY_WITH_ICON: "primary_with_icon",
  PHENOLOGICAL_LEGEND: "parcel_title_phenological_button",
};
