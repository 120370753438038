import React from "react";

export const DELETE_ICON = (
  <svg width="24" height="24" viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M305.333-150.667q-30.575 0-51.954-21.379T232-224v-504.667h-28q-8.15 0-14.241-6.251-6.092-6.252-6.092-14.667T189.75-764q6.084-6 14.25-6h152q0-13.666 11.092-22.5 11.091-8.833 24.908-8.833h177.334q13.816 0 24.908 8.725 11.091 8.725 11.091 22.608h152q8.45 0 14.725 6.129 6.275 6.128 6.275 14.544 0 8.415-6.275 14.537-6.275 6.123-14.725 6.123h-28V-224q0 30.575-21.379 51.954T656-150.667H305.333Zm-32-578V-224q0 14 9.334 23 9.333 9 22.666 9H656q13.333 0 22.667-9Q688-210 688-224v-504.667H273.333Zm116.334 434.001q0 8.166 6.117 14.249 6.117 6.084 14.333 6.084 8.216 0 14.55-6.084Q431-286.5 431-294.666v-332.001q0-7.8-6.312-14.4-6.312-6.6-14.817-6.6-8.221 0-14.212 6.6-5.992 6.6-5.992 14.4v332.001Zm141.333 0q0 8.166 6.117 14.249 6.117 6.084 14.333 6.084 8.217 0 14.55-6.084 6.333-6.083 6.333-14.249v-332.001q0-7.8-6.312-14.4-6.312-6.6-14.816-6.6-8.222 0-14.213 6.6-5.992 6.6-5.992 14.4v332.001ZM273.333-728.667V-224q0 14 9.334 23 9.333 9 22.666 9h-32V-728.667Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
