import React from "react";

function SelectComponent({ options, handleOnchange, title }) {
  return (
    <>
      <span>{title}</span>
      <select onChange={handleOnchange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
}

export default SelectComponent;
