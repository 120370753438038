import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import WarningsListComponent from "./components/warningList/WarningListComponent";
import {
  WARNINGS__NO__DATA,
  WARNINGS__SORT__BY__DATE__ASC,
  WARNINGS__SORT__BY__DATE__DES,
  WARNINGS__TITLE,
} from "../../translations/constants";
import { TEXT } from "../common/atoms/typography/typographyConstants";
import TypographyComponent from "../common/atoms/typography/TypographyComponent";
import style from "./WarningsComponent.module.css";
import { getWarningsService } from "../../services/commonservices";
import { sortArrayByKey } from "../../utils/utils";
import { DATE_PARAMETER, ROLES_ALL } from "../../utils/constants";
import withAuthorization from "../common/auth/withAuthorization";

const WarningsComponent = () => {
  const locale = localStorage.getItem('locale');
  const intl = useIntl();
  const [warnings, setWarnings] = useState([]);
  const [sort, setSort] = useState(true);
  const [key, setKey] = useState(new Date());

  const toggleSort = () => {
    setSort(!sort);
    const newArray = sortArrayByKey(warnings, DATE_PARAMETER, !sort);
    setWarnings(newArray);
  };

  const warningsCallback = (response) => {
    setWarnings(response.data);
    setKey(new Date());
  };

  const getWarnings = () => {
    getWarningsService({ date_order: "desc" }, warningsCallback, undefined, { "Accept-Language": locale });
  };

  useEffect(() => {
    getWarnings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  return (
    <section className={style.warningsPage}>
      <TypographyComponent variant={TEXT.VARIANT_H1} text={<FormattedMessage id={WARNINGS__TITLE} />} />
      <button className={style.sortButton} onClick={(event) => toggleSort(event)}>
        {!!sort ? (
          <>
            <FormattedMessage id={WARNINGS__SORT__BY__DATE__DES} />
            <ArrowDropDownIcon />
          </>
        ) : (
          <>
            <FormattedMessage id={WARNINGS__SORT__BY__DATE__ASC} />
            <ArrowDropUpIcon />
          </>
        )}
      </button>
      {!!warnings?.length ? (
        <WarningsListComponent warnings={warnings} getWarnings={getWarnings} key={key} />
      ) : (
        <FormattedMessage id={WARNINGS__NO__DATA} />
      )}
    </section>
  );
};

export default withAuthorization(ROLES_ALL)(WarningsComponent);
