import React from "react";
import { useIntl } from "react-intl";

import styles from "./lineWeather.module.css";
import { DATA_WEATHER } from "../../../../../../../utils/constants";
import {
  LINE_WEATHER__LABEL_WEATHER,
  LINE_WEATHER__LABEL_WIND,
  WIND_UNIT,
} from "../../../../../../../translations/constants";
import { formatTimestampToDate } from "../../../../../../../utils/utils";

const LineWeatherComponent = ({ rows, gridDays }) => {
  const intl = useIntl();

  return (
    <div className={styles.meteoContainer}>
      <div className={styles.lineContainer}>
        <div className={styles.lineItemsContainer}>
          <span>{intl.formatMessage({ id: LINE_WEATHER__LABEL_WEATHER })}</span>
        </div>
        {gridDays.map((day, index) => {
          const item = rows.find((r) => formatTimestampToDate(r.date) === formatTimestampToDate(day));
          return (
            <div className={styles.lineItemsContainer} key={index + "row"}>
              {item && <img src={DATA_WEATHER[item.sky_state]?.icon} alt={DATA_WEATHER[item.sky_state]?.alt} />}
            </div>
          );
        })}
      </div>
      <div className={styles.lineContainer}>
        <div className={styles.lineItemsContainer}>
          <span>{intl.formatMessage({ id: LINE_WEATHER__LABEL_WIND })}</span>
          <span className={styles.itemCategory}>{WIND_UNIT}</span>
        </div>
        {gridDays.map((day, index) => {
          const item = rows.find((r) => formatTimestampToDate(r.date) === formatTimestampToDate(day));
          return (
            <div className={styles.lineItemsContainerWindValue} key={index + "row"}>
              {item && (
                <span className={styles.windValue}>
                  {item.wind_dir} {!!item.wind_speed ? parseFloat(item.wind_speed).toFixed(1) : "-"}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LineWeatherComponent;
