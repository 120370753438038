import React from "react";

import withAuthorization from "../../common/auth/withAuthorization";
import { ROLES_ALL } from "../../../utils/constants";
import WeatherDataComponent from "./components/weatherDataComponent/WeatherDataComponent";

const ParcelDetailComponent = () => {
  return <WeatherDataComponent />;
};

export default withAuthorization(ROLES_ALL)(ParcelDetailComponent);
