export const headMok = [
  { content: "Login de usuario", width: "400px" },
  { content: "Tipo de usuario", width: "1fr" },
  { content: "Estado del usuario", width: "1fr" },
  { content: "CIF / NIF", width: "1fr" },
  { content: "Alias", width: "1fr" },
  { content: "ID de usuario", width: "1fr" },
  { content: "ID de Cliente", width: "1fr" },
];
export const rowMock = [
  [
    { width: "400px", content: "AACANALONLINE.RESI@GMAIL.COM" },
    { width: "1fr", content: "Titula" },
    { width: "1fr", content: "Activado" },
    { width: "1fr", content: "72415739D" },
    { width: "1fr", content: "Leire" },
    { width: "1fr", content: "829095" },
    { width: "1fr", content: "2149718" },
  ],
  [
    { width: "400px", content: "ZZCANALONLINE.RESI@GMAIL.COM" },
    { width: "1fr", content: "Titula" },
    { width: "1fr", content: "Activado" },
    { width: "1fr", content: "72415739D" },
    { width: "1fr", content: "Leire" },
    { width: "1fr", content: "829095" },
    { width: "1fr", content: "2149718" },
  ],
  [
    { width: "400px", content: "CCCANALONLINE.RESI@GMAIL.COM" },
    { width: "1fr", content: "Titula" },
    { width: "1fr", content: "Activado" },
    { width: "1fr", content: "72415739D" },
    { width: "1fr", content: "Leire" },
    { width: "1fr", content: "829095" },
    { width: "1fr", content: "2149718" },
  ],
  [
    { width: "400px", content: "KKCANALONLINE.RESI@GMAIL.COM" },
    { width: "1fr", content: "Titula" },
    { width: "1fr", content: "Activado" },
    { width: "1fr", content: "72415739D" },
    { width: "1fr", content: "Leire" },
    { width: "1fr", content: "829095" },
    { width: "1fr", content: "2149718" },
  ],
  [
    { width: "400px", content: "HHCANALONLINE.RESI@GMAIL.COM" },
    { width: "1fr", content: "Titula" },
    { width: "1fr", content: "Activado" },
    { width: "1fr", content: "72415739D" },
    { width: "1fr", content: "Leire" },
    { width: "1fr", content: "829095" },
    { width: "1fr", content: "2149718" },
  ],
  [
    { width: "400px", content: "FFCANALONLINE.RESI@GMAIL.COM" },
    { width: "1fr", content: "Titula" },
    { width: "1fr", content: "Activado" },
    { width: "1fr", content: "72415739D" },
    { width: "1fr", content: "Leire" },
    { width: "1fr", content: "829095" },
    { width: "1fr", content: "2149718" },
  ],
  [
    { content: "GGCANALONLINE.RESI@GMAIL.COM" },
    { content: "Titula" },
    { content: "Activado" },
    { content: "72415739D" },
    { content: "Leire" },
    { content: "829095" },
    { content: "2149718" },
  ],
  [
    { content: "CANALONLINE.RESI@GMAIL.COM" },
    { content: "Titula" },
    { content: "Activado" },
    { content: "72415739D" },
    { content: "Leire" },
    { content: "829095" },
    { content: "2149718" },
  ],
  [
    { content: "CANALONLINE.RESI@GMAIL.COM" },
    { content: "Titula" },
    { content: "Activado" },
    { content: "72415739D" },
    { content: "Leire" },
    { content: "829095" },
    { content: "2149718" },
  ],
];
