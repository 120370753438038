import React, { useState } from "react";

import ModalComponent from "../../common/molecules/modal/ModalComponent";
import { listDataWeather } from "../Constants";
import TableComponent from "../components/TableComponent";
import CarrouselWeatherComponent from "../components/CarrouselWeatherComponent";
import ParcelDetailHeaderComponent from "../components/ParcelDetailHeaderComponent";
import withAuthorization from "../../common/auth/withAuthorization";
import { ROLES_ALL } from "../../../utils/constants";

const SimplifiedParcelComponent = () => {
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [bodyModal, setBodyModal] = useState("");

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModal = (title, body) => {
    setTitleModal(title);
    setBodyModal(body);
    toggleModal();
  };

  return (
    <>
      <ParcelDetailHeaderComponent />
      <CarrouselWeatherComponent options={listDataWeather} />
      <TableComponent rows={[]} columns={[]} header={[]} handleOpenModal={handleOpenModal} />
      {openModal && <ModalComponent title={titleModal} body={bodyModal} />}
    </>
  );
};

export default withAuthorization(ROLES_ALL)(SimplifiedParcelComponent);
