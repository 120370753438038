import style from "./ModalWithTwoButtons.module.css";
import PropTypes from "prop-types";
import { BUTTON } from "../../common/atoms/button/buttonConstants";
import ButtonComponent from "../../common/atoms/button/ButtonComponent";
import TypographyComponent from "../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../common/atoms/typography/typographyConstants";
import { Close } from "@mui/icons-material";

function ModalWithTwoButtons({ data }) {
  const {
    title,
    subtitle,
    subtitleData,
    body,
    textButton1,
    handlerButton1,
    textButton2,
    handlerButton2,
    hideButton2,
    handleClose,
  } = data;

  return (
    <section className={style.over}>
      <dialog className={`${style.ventanaFlotante} ${style.container}`}>
        <div className={style.header}>
          <span className={style.title}>
            {title && <TypographyComponent text={title} variant={TEXT.VARIANT_H2} />}
            <Close onClick={handleClose} className={style.close} />
          </span>
          {subtitle && (
            <div className={style.subtitle}>
              {subtitle && <TypographyComponent text={subtitle} variant={TEXT.VARIANT_H4} />}
              {subtitleData && <TypographyComponent text={subtitleData} variant={TEXT.VARIANT_H4} />}
            </div>
          )}
        </div>
        <div className={style.content}>{body}</div>
        <div className={style.buttonsContainer}>
          <ButtonComponent text={textButton1} variant={BUTTON.VARIANT_SECONDARY} onClick={handlerButton1} />
          {!hideButton2 && (
            <ButtonComponent text={textButton2} variant={BUTTON.VARIANT_PRIMARY} onClick={handlerButton2} />
          )}
        </div>
      </dialog>
    </section>
  );
}

ModalWithTwoButtons.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  textButton1: PropTypes.string,
  handlerButton1: PropTypes.func,
  textButtonAction: PropTypes.string,
  handlerButton2: PropTypes.func,
};

export default ModalWithTwoButtons;
