import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { createTheme, ThemeProvider } from "@mui/material";
import { orange } from "@mui/material/colors";

import "./App.css";
import { ErrorBoundary } from "./components/common/ErrorBoundaryComponent";
import NotificationSnack from "./components/common/NotificationSnack";
import GlobalLoadingComponent from "./components/common/loading/GlobalLoadingComponent";
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import messages_gl from "./translations/gl.json";
import Routes from "./components/routes/Routers";

const theme = createTheme({
  palette: {
    primary: {
      main: "#049fe3",
    },
    secondary: orange,
  },
});

const messages = {
  es: messages_es,
  en: messages_en,
  gl: messages_gl,
};

export default function App() {
  const language = localStorage.getItem("locale") || navigator.language.split(/[-_]/)[0];

  const [locale, setLocale] = useState({ locale: language, messages: messages[language] });

  const handleLanguageChanged = (lang) => {
    if (!!messages[lang]) {
      setLocale({ locale: lang, messages: messages[lang] });
    }
  };

  return (
    <IntlProvider locale={locale.locale} messages={locale.messages} defaultLocale="es">
      <ThemeProvider theme={theme}>
        <div className="App">
          <ErrorBoundary key={"landingErrorBoundary"}>
            <Routes handleLanguageChanged={handleLanguageChanged} />
          </ErrorBoundary>
          <NotificationSnack />
          <GlobalLoadingComponent />
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
}
