import React from "react";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { FormattedMessage } from "react-intl";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((/*theme*/) => ({
  phenologicalListContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    rowGap: "2rem",
    padding: "4rem",
    paddingTop: "2rem",
    paddingBottom: "2rem",
    overflowY: "hidden"
  },
  listItem: {
    display: "flex",
    gap: "1rem",
    width: "max-content",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "10rem"
  },
  imgContainer: {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    width: "80px",
    height: "70px",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(4)",
      zIndex: "9999",
      position: "absolute"
    },
  },
}));

const ParcelDetailPhenologicalComponent = ({ fenStates }) => {
  const classes = useStyles();

  return (
    <div>
      <section>
        <ul className={classes.phenologicalListContainer}>
          {fenStates.map((fenState) => (
            <li className={classes.listItem} key={fenState.id}>
              <div className={classes.imgContainer}>
                  <img
                    src={`${process.env.PUBLIC_URL}/phenological_images/${fenState.code}.jpg`}
                    alt={fenState.name}
                    style={{ borderRadius: "10px", width: "100%", height: "100%" }}
                  />
              </div>
              <div className={classes.descriptionContainer}>
                <TypographyComponent text={<FormattedMessage id={fenState.code} />} variant={TEXT.VARIANT_H4} />
                <TypographyComponent text={<FormattedMessage id={fenState.name} />} variant={TEXT.VARIANT_P} />
              </div>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default ParcelDetailPhenologicalComponent;
