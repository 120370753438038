import React, { useState } from "react";
import { Info, QuestionMark } from "@mui/icons-material";

import style from "./parcelTitle.module.css";
import {
  COMMON__LEGEND,
  PARCEL_TITLE__LAST_UPDATE,
  PARCEL_TITLE__TABLE__HEADER__COUNCIL_NAME,
  PARCEL_TITLE__TABLE__HEADER__DENOMINATION,
  PARCEL_TITLE__TABLE__HEADER__REGION,
  PARCEL_TITLE__TABLE__HEADER__TREATMENT,
  PARCEL_TITLE__TABLE__HEADER__VARIETY,
  PARCEL_TITLE__TABLE__HEADER__ZONE,
} from "../../../../../../../translations/constants";
import ButtonComponent from "../../../../../../common/atoms/button/ButtonComponent";
import { BUTTON } from "../../../../../../common/atoms/button/buttonConstants";
import ModalComponent from "../../../../../../common/molecules/modal/ModalComponent";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import ParcelLegendComponent from "../parcelDetailLegendComponent/ParcelLegendComponent";
import { useIntl } from "react-intl";
import ParcelDetailPhenologicalComponent from "../parcelDetailPhenologicalComponent/ParcelDetailPhenologicalComponent";

const ParcelTitleComponent = ({ plotData, fenStates, lastUpdated }) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openLegend, setOpenLegend] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  const intl = useIntl();
  const { formatMessage } = useCustomIntl();

  const handleOpenInfo = () => setOpenInfo(!openInfo);
  const handleOpenLegend = () => setOpenLegend(!openLegend);

  const moreInfo = [
    {
      headerText: formatMessage(PARCEL_TITLE__TABLE__HEADER__REGION),
      description: plotData.region || "-",
    },
    {
      headerText: formatMessage(PARCEL_TITLE__TABLE__HEADER__COUNCIL_NAME),
      description: plotData.council_name || "-",
    },
    {
      headerText: formatMessage(PARCEL_TITLE__TABLE__HEADER__ZONE),
      description: plotData.zone || "-",
    },
    {
      headerText: formatMessage(PARCEL_TITLE__TABLE__HEADER__DENOMINATION),
      description: plotData.denomination || "-",
    },
    {
      headerText: formatMessage(PARCEL_TITLE__TABLE__HEADER__VARIETY),
      description: plotData.variety || "-",
    },
    {
      headerText: formatMessage(PARCEL_TITLE__TABLE__HEADER__TREATMENT),
      description: plotData.treatment || "-",
    },
  ];

  return (
    <section className={style.container}>
      <div className={style.titleContainer}>
        <div className={style.titleH1}>
          <TypographyComponent text={plotData.name} variant={TEXT.VARIANT_H1} />
        </div>
        <div className={style.titleButtonGroup}>
          <ButtonComponent
            icon={<Info />}
            hiddenTextMobileVersion={true}
            onClick={handleOpenInfo}
            text={formatMessage(BUTTON.MORE_INFO)}
          />
          <ButtonComponent
            icon={<QuestionMark />}
            hiddenTextMobileVersion={true}
            onClick={handleOpenLegend}
            text={formatMessage(BUTTON.LEGEND)}
            setSelectedButton={setSelectedButton}
          />
          <ButtonComponent
            icon={<QuestionMark />}
            hiddenTextMobileVersion={true}
            onClick={handleOpenLegend}
            text={formatMessage(BUTTON.PHENOLOGICAL_LEGEND)}
            setSelectedButton={setSelectedButton}
          />
          <TypographyComponent text={intl.formatMessage({ id: PARCEL_TITLE__LAST_UPDATE }, { time: lastUpdated })} variant={TEXT.VARIANT_P} />
        </div>
      </div>

      <div className={style.titleDescriptionGrid} style={{ gridTemplateColumns: `repeat(${moreInfo?.length}, 1fr)` }}>
        {openInfo &&
          moreInfo.map((item, index) => (
            <div key={item.headerText + index}>
              <p className={style.headerText}>{item.headerText}</p>
              <p>{item.description}</p>
            </div>
          ))}
      </div>
      {openLegend && selectedButton === formatMessage(BUTTON.LEGEND) ? (
        <ModalComponent
          body={<ParcelLegendComponent />}
          handleClose={handleOpenLegend}
          title={intl.formatMessage({ id: COMMON__LEGEND })}
        />
      ) : openLegend && selectedButton === formatMessage(BUTTON.PHENOLOGICAL_LEGEND) ? (
        <ModalComponent
          body={<ParcelDetailPhenologicalComponent fenStates={fenStates} />}
          handleClose={handleOpenLegend}
          title={intl.formatMessage({ id: BUTTON.PHENOLOGICAL_LEGEND })}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default ParcelTitleComponent;
