import { object, string } from "yup";
import {
  CREATE_USER_FORM__INPUT__EMAIL,
  CREATE_USER_FORM__INPUT__PASSWORD,
  CREATE_USER_FORM__INPUT__ROLE,
  CREATE_USER_FORM__INPUT__USERNAME,
} from "../../../translations/constants";
import { ROLES_ALL } from "../../../utils/constants";

export const USER_SCHEMA = object({
  username: string().required(),
  email: string().email().required(),
  role: string().oneOf(ROLES_ALL).required(),
  password: string().required(),
});

export const CREATE_USER_ERROR_FIELD = {
  username: CREATE_USER_FORM__INPUT__USERNAME,
  email: CREATE_USER_FORM__INPUT__EMAIL,
  role: CREATE_USER_FORM__INPUT__ROLE,
  password: CREATE_USER_FORM__INPUT__PASSWORD,
};

export const CREATE_USER_FIELD_EMAIL = "email";

export const ADMIN_TABLE_ROWS_USERS = 6;
