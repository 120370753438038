import React, { useEffect, useState } from "react";
import { getPublicPlagueRisks } from "../../../../services/commonservices";
import PublicMapComponent from "../components/maps/publicMap/PublicMapComponent";
import { INITIAL_MAP_CONFIG } from "../components/maps/mapConstants";
import style from "../Parcels.module.css";

const PublicParcelsComponent = () => {
  const [parcels, setParcels] = useState([]);
  const callback = (response) => {
    setParcels(response.data);
  };

  useEffect(() => {
    getPublicPlagueRisks({}, callback);
  }, []);

  return (
    <section className={style.container}>
      <PublicMapComponent
        center={[parseFloat(INITIAL_MAP_CONFIG.INITIAL_LONGITUDE), parseFloat(INITIAL_MAP_CONFIG.INITIAL_LATITUDE)]}
        zoom={INITIAL_MAP_CONFIG.INITIAL_ZOOM}
        parcels={parcels}
      />
    </section>
  );
};

export default PublicParcelsComponent;
