import React from "react";

import AutocompleteComponent from "../../atoms/autocomplete/AutocompleteComponent";
import TagComponent from "../../atoms/tag/TagComponent";
import style from "./AutocompleteGroup.module.css";

const AutocompleteGroupComponent = ({ data, name, tagStyle, extraControls=false }) => {
  const handleAddTag = (tag) => {
    if (!data.tags.some((dataTag) => dataTag.id === tag.id)) {
      data.setTags([tag, ...data.tags]);
    }
  };
  
  const handleAddAllTags = () => {
    data.setTags(data.data);
  }

  const handleRemoveAllTags = () => {
    data.resetTags();
  }

  const removeTagFilter = (itemId) => {
    const newFilters = data.tags.filter((item) => {
      return item.id !== itemId;
    });
    data.setTags(newFilters);
  };

  return (
    <div className={style.container}>
      <AutocompleteComponent data={data} onClick={handleAddTag} key={name} extraControls={extraControls} addAllTags={handleAddAllTags} removeAllTags={handleRemoveAllTags}/>
      <div className={tagStyle === "grid" ? style.tagContainerGrid : style.tagContainer}>
        {data.tags.map((item, index) => (
          <TagComponent
            key={item.text + index || item.name + index}
            text={item.name}
            onClick={() => removeTagFilter(item.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default AutocompleteGroupComponent;
