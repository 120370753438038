import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: navigator.language.split(/[-_]/)[0],
  loading: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    turnOffLoadingActionCreator: (state) => {
      state.loading = false;
    },
    turnOnLoadingActionCreator: (state) => {
      state.loading = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLanguage, turnOffLoadingActionCreator, turnOnLoadingActionCreator } = commonSlice.actions;
export const { language, loading } = commonSlice.reducer;
export default commonSlice.reducer;
