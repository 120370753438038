import {
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_HIGH,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_VIGILANCE,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_LOW,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_MEDIUM,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_NONE,
} from "../../../../../translations/constants";

export const DEFAULT_ZOOM = 8;

export const SELECT_FIELD_LABEL = "name";
export const SELECT_FIELD_VALUE = "id";

export const listDataWeather = [
  {
    id: 1,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 2,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 3,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 4,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 5,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
];
export const HIGH = "HIGH";
export const MEDIUM = "MEDIUM";
export const LOW = "LOW";
export const VIGILANCE = "VIGILANCE";
export const NONE = "NONE";

export const PLAGUE_RISKS = [HIGH, MEDIUM, LOW, NONE];
export const PLAGUE_RISKS_NAMES = [
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_HIGH,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_MEDIUM,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_LOW,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_VIGILANCE,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_NONE,
];

export const COLOR_RISKS = {
  [HIGH]: {
    color: "#ff001b",
    borderColor: "rgba(255, 0, 27, 0.5)",
  },
  [MEDIUM]: {
    color: "#ff8000",
    borderColor: "rgba(255, 128, 0, 0.5)",
  },
  [LOW]: {
    color: "#fcdb03",
    borderColor: "rgba(252, 219, 3, 0.5)",
  },
  [VIGILANCE]: {
    color: "#689f38",
    borderColor: "rgba(104, 159, 56, 0.5)",
  },
  [NONE]: {
    color: "#e8e3d7",
    borderColor: "rgba(140, 138, 133, 0.5)",
  },
  undefined: {
    color: "#689F38",
    borderColor: "rgba(104, 159, 56, 0.5)",
  },
};
export const INITIAL_MAP_CONFIG = {
  INITIAL_ZOOM: 8.25,
  INITIAL_LONGITUDE: "-7.925286",
  INITIAL_LATITUDE: "42.309922",
};
