import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Search } from "@mui/icons-material";
import ButtonComponent from "../button/ButtonComponent"
import { useIntl } from "react-intl";
import {
  CREATE_TREATMENT_FORM__INPUT__ADD__ALL__PARCELS,
  CREATE_TREATMENT_FORM__INPUT__REMOVE__ALL__PARCELS
} from "../../../../translations/constants"

import style from "./Autocomplete.module.css";
import { INPUT } from "./AutocompleteConstants";

const AutocompleteComponent = ({ data, onClick, extraControls = false, addAllTags, removeAllTags }) => {
  const [inputValue, setInputValue] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    setSuggestions(data.data);
  }, [data]);

  function hasElementWithLength(arr) {
    return arr.some(function(element) {
        return element && element.length && element.length > 2;
    });
  }

  useEffect(() => {
    setShowSuggestion(!!suggestions.length && hasElementWithLength(inputValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  const handleInputChange = (event, index) => {
    let newInputValue = inputValue;
    inputValue[index] = event.target.value;
    setInputValue(newInputValue);
    if (Array.isArray(data.handleValues)) {
      data.handleValues[index](inputValue[index]);
    }
    else {
      data.handleValues(inputValue[index]);
    }
    //const results = data.fetching(data.tags, data.data)
    setShowSuggestion(!!data.data.length && hasElementWithLength(inputValue));
    setSuggestions(data.data);
  };

  const onClickAddAll = () => {
    setShowSuggestion(false);
    addAllTags();
  };

  const onClickRemoveAll = () => {
    removeAllTags();
  };

  const addTag = (item) => {
    onClick(item);
    setShowSuggestion(false);
    if (!!data.handleValues && !Array.isArray(data.handleValues)) {
      data.handleValues("");
    }
    setInputValue(inputValue.map(() => ""));
  };

  const getInputFields = () => {
    let inputFields;
    if (Array.isArray(data.handleValues)) {
      inputFields = data.handleValues.map((handler, index) => {
        let placeholder = data.placeholder?.length >= index ? data.placeholder[index] : "";
        return (
          <input
            key={index}
            id={data.id}
            name={data.name}
            className={`${style.input} ${data.disable && style.disable}`}
            type={INPUT.TYPE_TEXT}
            value={inputValue[index]}
            onChange={(event) => handleInputChange(event, index)}
            placeholder={placeholder}
            autoComplete="off"
          />
        )
      })
    } else {
      inputFields = (
        <input
          id={data.id}
          name={data.name}
          className={`${style.input} ${data.disable && style.disable}`}
          type={INPUT.TYPE_TEXT}
          value={inputValue[0]}
          onChange={(event) => handleInputChange(event, 0)}
          placeholder={data.placeholder}
          autoComplete="off"
        />
      );
    }
    return inputFields;
  }

  return (
    !!data && (
      <div className={style.formGroup}>
        {data.label && <label htmlFor={data.name}>{data.label}</label>}
        {data.sublabel && <div className={style.sublabel}>{data.sublabel}</div>}
        <div className={`${style.inputGroup} ${style[data.variant]}`}>
          <div className={style.icon}>
            <Search />
          </div>
          {getInputFields()}
          {extraControls && (
            <div className={style.tagControls}>
              <div className={style.button}>
                <ButtonComponent text={intl.formatMessage({ id: CREATE_TREATMENT_FORM__INPUT__ADD__ALL__PARCELS })} onClick={onClickAddAll}/>
              </div>
              <div className={style.button}>
                <ButtonComponent text={intl.formatMessage({ id: CREATE_TREATMENT_FORM__INPUT__REMOVE__ALL__PARCELS })} onClick={onClickRemoveAll}/>
              </div>
            </div>
          )}
        </div>
        {showSuggestion && (
          <div className={style.suggestion}>
            {suggestions.map((item, index) => (
              <span onClick={() => addTag(item)} key={index}>
                {item.name}
              </span>
            ))}
          </div>
        )}
      </div>
    )
  );
};

export default AutocompleteComponent;

AutocompleteComponent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disable: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
};
