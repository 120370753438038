import React from "react";
import LoadingComponent from "./LoadingComponent";
import { useSelector } from "react-redux";

const GlobalLoadingComponent = () => {
  const commonStateLoading = useSelector((state) => state.common.loading);
  return <LoadingComponent open={commonStateLoading} description />;
};

export default GlobalLoadingComponent;
