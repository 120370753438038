import React, { useEffect, useRef, useState } from "react";
import { Map } from "ol";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { Style, Fill, Circle, Stroke, Text } from "ol/style";
import { Cluster } from "ol/source";
import Modal from "react-modal";
import { boundingExtent } from "ol/extent.js";
import { useIntl } from "react-intl";

import "ol/ol.css";
import "../map.module.css";
import Button from "../../../../../common/atoms/button/ButtonComponent";
import {
  COMMON__MODAL_CLOSE,
  MAP__RISK_LEVEL_HIGH,
  MAP__RISK_LEVEL_LOW,
  MAP__RISK_LEVEL_VIGILANCE,
  MAP__RISK_LEVEL_MEDIUM,
  MAP__RISK_LEVEL_NONE,
  MAP_MODAL_PLAGUE_NAME,
  MAP_MODAL_RISK_LEVEL,
  PARCELS_LIST__MAP_LEGEND_SUBTITLE,
  PARCELS_LIST__MAP_LEGEND_TEXT1_PUBLIC,
  PARCELS_LIST__MAP_LEGEND_TEXT2,
  PARCELS_LIST__MAP_LEGEND_TITLE,
  PARCELS_LIST__MAP_DAYS_MODAL_TEXT,
  PARCELS_LIST__MAP_DAYS_MODAL_TITLE,
  WARNINGS__RISK_LEVEL_HIGH,
  WARNINGS__RISK_LEVEL_LOW,
  WARNINGS__RISK_LEVEL_VIGILANCE,
  WARNINGS__RISK_LEVEL_MEDIUM, ZONES_LIST__MAP_LEGEND_SUBTITLE, ZONES_LIST__ZONES, ZONES_SINGULAR_ZONE,
} from "../../../../../../translations/constants";
import { BUTTON } from "../../../../../common/atoms/button/buttonConstants";
import styles from "../map.module.css";
import CardLegend from "../../../../../common/molecules/cardLegend/CardLegendComponent";
import { getPublicPlaguesService, getPublicPlagueRisks } from "../../../../../../services/commonservices";
import { COLOR_RISKS, HIGH, MEDIUM, LOW, NONE, VIGILANCE } from "../mapConstants";
import useCustomIntl from "../../../../../../hook/cutomHook";
import { POINTER_STATUS } from "../../../../../common/atoms/pointer/pointerContanst";
import FilterDayComponent from "../components/FilterDayComponent";
import FilterPlagueComponent from "../components/FilterPlagueComponent";
import TypographyComponent from "../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../common/atoms/typography/typographyConstants";

const PublicMapComponent = ({ parcels, zoom, center }) => {
  const mapRef = useRef();
  const { formatMessage } = useCustomIntl();
  const intl = useIntl();

  const MIN_POINT_SIZE = 10;
  const MAX_POINT_SIZE = 40;
  const MODAL_HEIGHT = 250;
  const MODAL_WIDTH = 250;
  const features = [];

  const [plagues, setPlagues] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showUnavailableDataModal, setShowUnavailableDataModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: "0px", left: "0px" });
  const [showLegend, setShowLegend] = useState(false);
  const [map, setMap] = useState(undefined);
  const [parcelsOnMap, setParcelsOnMap] = useState(parcels);
  const [dayFilter, setDayFilter] = useState("")
  const [plagueFilter, setPlagueFilter] = useState("")
  const [parcelsFilterData, setParcelsFilterData] = useState("")

  const levels = ['HIGH', 'MEDIUM', 'LOW', 'VIGILANCE', 'NONE'];
  const parcelsByRisk = {
    [HIGH]: 0,
    [MEDIUM]: 0,
    [LOW]: 0,
    [VIGILANCE]: 0,
    [NONE]: 0,
  }

  const checkForUnavailableData = (data) => {
    let dataFound = false;
    data.forEach((value) => {
      if (value.plague_risk) {
        dataFound = true;
      }
    });
    return !dataFound;
  }

  const getPlotsServiceCallback = (response) => {
    if (response.data.length === 0) {
      setParcelsFilterData(response.data)
      return;
    }
    if (checkForUnavailableData(response.data)) {
      setShowUnavailableDataModal(true);
      setParcelsOnMap(response.data);
      return;
    }
    setParcelsFilterData(response.data)
    setParcelsOnMap(response.data);
  };

  const handlePlaguesSuccess = (response) => {
    setPlagues(response.data);
  };

  useEffect(() => {
    getPublicPlaguesService({ include_not_active: false }, handlePlaguesSuccess);
  }, []);

  useEffect(() => {
    setParcelsOnMap(parcels);
  }, [parcels]);

  useEffect(() => {
    let parameters = {
      summary: true,
      include_plague_risks: true,
    }
    if (dayFilter) {
      parameters['date'] = dayFilter
    }
    if (plagueFilter) {
      parameters['plague_id'] = plagueFilter
    }
    getPublicPlagueRisks(parameters, getPlotsServiceCallback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayFilter, plagueFilter]);

  const getHighestRiskLevel = (features) => {
    const riskLevels = features.map(item => item.getProperties().plague_risk[0].risk_level);
    const highestRisk = levels.find(level => riskLevels.includes(level));
    return highestRisk || undefined;
  }

  const getColorLevel = (riskLevel) => {
    let colorSelected;
    let borderColorSelected;

    if (riskLevel === HIGH) {
      colorSelected = COLOR_RISKS.HIGH.color;
      borderColorSelected = COLOR_RISKS.HIGH.borderColor;
    } else if (riskLevel === MEDIUM) {
      colorSelected = COLOR_RISKS.MEDIUM.color;
      borderColorSelected = COLOR_RISKS.MEDIUM.borderColor;
    } else if (riskLevel === LOW) {
      colorSelected = COLOR_RISKS.LOW.color;
      borderColorSelected = COLOR_RISKS.LOW.borderColor;
    } else if (riskLevel === VIGILANCE) {
      colorSelected = COLOR_RISKS.VIGILANCE.color;
      borderColorSelected = COLOR_RISKS.VIGILANCE.borderColor;
    } else if (riskLevel === NONE) {
      colorSelected = COLOR_RISKS.NONE.color;
      borderColorSelected = COLOR_RISKS.NONE.borderColor;
    } else {
      colorSelected = COLOR_RISKS.undefined.color;
      borderColorSelected = COLOR_RISKS.undefined.borderColor;
    }

    return [borderColorSelected, colorSelected];
  };

  const getStrokeLevel = (riskLevel) => {
    if (riskLevel === NONE) {
      return 3;
    } else {
      return 15;
    }
  }

  const getNumberOfParcelsSameRiskLevel = (parcels) => {
    let parcelsGroup = { ...parcelsByRisk };
    parcels?.forEach((parcel) => {
      if (parcel.values_?.plague_risk) {
        parcel.values_?.plague_risk.forEach((plague) => {
          return (parcelsGroup[plague.risk_level] = parcelsGroup[plague.risk_level] + 1);
        });
      }
    });

    let dataToPrint = "";
    dataToPrint = Object.entries(parcelsGroup).map(([prop, value], index) => {
      const riskObject = {
        [HIGH]: intl.formatMessage({ id: MAP__RISK_LEVEL_HIGH }),
        [MEDIUM]: intl.formatMessage({ id: MAP__RISK_LEVEL_MEDIUM }),
        [LOW]: intl.formatMessage({ id: MAP__RISK_LEVEL_LOW }),
        [VIGILANCE]: intl.formatMessage({ id: MAP__RISK_LEVEL_VIGILANCE }),
        [NONE]: intl.formatMessage({ id: MAP__RISK_LEVEL_NONE }),
      };

      const translatedProp = riskObject[prop];

      return (
        <li key={prop + index}>
          {translatedProp}: {value}{" "}
          {value === 1
            ? intl.formatMessage({ id: ZONES_SINGULAR_ZONE })
            : intl.formatMessage({ id: ZONES_LIST__ZONES })}
        </li>
      );
    });
    return dataToPrint;
  };

  const initializeMap = () => {
    const raster = new TileLayer({
      source: new OSM(),
    });

    setMap(
      new Map({
        layers: [raster],
        target: mapRef.current,
        view: new View({
          center: fromLonLat(center),
          zoom: zoom,
        }),
      }),
    );
  };

  const parcelsToMap = () => {
    const layers = map.getLayers().getArray();
    layers.forEach((layer) => {
      if (!(layer instanceof TileLayer)) {
        map.removeLayer(layer);
      }
    });
    for (let i = 0; i < parcelsOnMap.length; ++i) {
      features[i] = new Feature({
        geometry: new Point(
          fromLonLat([parcelsOnMap[i]?.geolocation.longitude, parcelsOnMap[i]?.geolocation.latitude]),
        ),
        name: parcelsOnMap[i].name,
        id: parcelsOnMap[i].id,
        plague_risk: [
          {
            risk_level: parcelsOnMap[i]?.plague_risk?.map_risk_level,
            plague_name: parcelsOnMap[i]?.plague_risk?.plague_name,
          },
        ],
      });
    }

    const source = new VectorSource({
      features: features,
    });

    const clusterSource = new Cluster({
      distance: parseInt(25, 8),
      minDistance: parseInt(25, 8),
      source: source,
    });
    const getSizePoint = (size) => {
      let pointSize = size * 10;
      if (size > 5) {
        pointSize = MAX_POINT_SIZE;
      } else {
        if (size < 2) pointSize = MIN_POINT_SIZE;
      }
      return pointSize;
    };

    const clusters = new VectorLayer({
      source: clusterSource,
      style: function (feature) {
        const featuresGroup = feature.get("features");
        const numberFeaturesGroup = featuresGroup.length;
        const riskLevel = getHighestRiskLevel(featuresGroup);
        if (riskLevel === undefined) {
          return;
        }
        const colorsSelected = getColorLevel(riskLevel);
        const strokeWidth = getStrokeLevel(riskLevel);
        const sizePoint = getSizePoint(numberFeaturesGroup);
        const style = new Style({
          image: new Circle({
            radius: sizePoint,
            stroke: new Stroke({
              color: colorsSelected[0],
              width: strokeWidth,
            }),
            fill: new Fill({
              color: colorsSelected[1],
            }),
          }),
          text: new Text({
            text: numberFeaturesGroup.toString(),
            fill: new Fill({
              color: "#36333a",
            }),
          }),
        });
        return style;
      },
    });

    map.addLayer(clusters);

    const setDataToModal = (featuresSelected) => {
      if (featuresSelected.length === 1) {
        const featureData = featuresSelected[0].getProperties();
        let riskTranslated = "";
        switch (featureData.plague_risk[0].risk_level) {
          case HIGH:
            riskTranslated = WARNINGS__RISK_LEVEL_HIGH;
            break;
          case MEDIUM:
            riskTranslated = WARNINGS__RISK_LEVEL_MEDIUM;
            break;
          case LOW:
            riskTranslated = WARNINGS__RISK_LEVEL_LOW;
            break;
          case VIGILANCE:
            riskTranslated = WARNINGS__RISK_LEVEL_VIGILANCE;
            break;
          case NONE:
            riskTranslated = MAP__RISK_LEVEL_NONE;
            break;
          default:
            riskTranslated = featureData.plague_risk[0].risk_level;
            break;
        }

        setSelectedPoint(
          <>
            <h2>{featureData.name}</h2>
            <section className={styles.parcelList}>
              <p>
                {intl.formatMessage({ id: MAP_MODAL_PLAGUE_NAME })}: {featureData.plague_risk[0].plague_name}
              </p>
              <p>
                {intl.formatMessage({ id: MAP_MODAL_RISK_LEVEL })}: {intl.formatMessage({ id: riskTranslated })}
              </p>
            </section>
          </>,
        );
      } else {
        setSelectedPoint(
          <>
            <h2>{featuresSelected.length + " " + intl.formatMessage({ id: ZONES_LIST__MAP_LEGEND_SUBTITLE })}</h2>
            <ul className={styles.parcelList}>{getNumberOfParcelsSameRiskLevel(featuresSelected)}</ul>
          </>,
        );
      }
    };

    const calculateModalPosition = (coord) => {
      const pixel = map.getPixelFromCoordinate(coord);
      const mapWidth = map.getTargetElement().offsetWidth;
      const mapHeight = map.getTargetElement().offsetHeight;
      let left = pixel[0];
      let top = pixel[1];
      let minMargin = 20;
      if (left + MODAL_WIDTH/2 > mapWidth) {
        left = mapWidth - MODAL_WIDTH/2 - minMargin;
      }
      else if (left - MODAL_WIDTH/2 < 0) {
        left = 0 + MODAL_WIDTH/2 + minMargin;
      }
      if (top + MODAL_HEIGHT/2 > mapHeight) {
        top = mapHeight - MODAL_HEIGHT/2 - minMargin;
      }
      else if (top - MODAL_HEIGHT/2 < 0) {
        top = 0 + MODAL_HEIGHT/2 + minMargin;
      }
      const x = mapRef.current.offsetLeft + left;
      const y = mapRef.current.offsetTop + top;
      return { modalLeft: x + "px", modalTop: y + "px" };
    };
    map.on("click", (event) => {
      clusters.getFeatures(event.pixel).then((clickedFeatures) => {
        if (clickedFeatures.length) {
          // Get clustered Coordinates
          const features = clickedFeatures[0].get("features");
          if (features.length > 1) {
            const coordinates = boundingExtent(
              features.map((r) => {
                return r.getGeometry().getCoordinates();
              }),
            );
            const { modalLeft, modalTop } = calculateModalPosition(coordinates);
            setModalPosition({ top: modalTop, left: modalLeft });
            setDataToModal(features);
            /*map.getView().fit(coordinates, {duration: 1000, padding: [50, 50, 50, 50]});*/
          } else {
            if (features[0]) {
              const geometry = features[0].getGeometry();
              const coordinates = geometry.getCoordinates();
              const { modalLeft, modalTop } = calculateModalPosition(coordinates);
              setModalPosition({ left: modalLeft, top: modalTop });
              setDataToModal(features);
            }
          }
          setShowModal(true);
        }
      });
    });
  };

  useEffect(() => {
    initializeMap();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    map && parcelsToMap();
    // eslint-disable-next-line
  }, [parcelsOnMap, map]);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPoint(null);
  };

  const handleCloseUnavailableDataModal = () => {
    setShowUnavailableDataModal(false);
  }

  const openLegend = () => {
    setShowLegend(!showLegend);
  };

  const cardLegendData = {
    title: intl.formatMessage({ id: PARCELS_LIST__MAP_LEGEND_TITLE }),
    text1: intl.formatMessage({ id: PARCELS_LIST__MAP_LEGEND_TEXT1_PUBLIC }),
    text2: intl.formatMessage({ id: PARCELS_LIST__MAP_LEGEND_TEXT2 }),
    subtitle: intl.formatMessage({ id: PARCELS_LIST__MAP_LEGEND_SUBTITLE }),
    risks: POINTER_STATUS,
  };

  return (
    <div className={styles.publicMap} ref={mapRef}>
      <div className={styles.legendGroup}>
        <div className={`${styles.buttonLegend} ${showLegend && styles.buttonLegend_invisible}`}>
          <Button text={formatMessage(BUTTON.OPEN_LEGEND)} variant={BUTTON.VARIANT_DARK} onClick={openLegend} />
        </div>
        <div className={`${styles.cardLegend} ${showLegend && styles.cardLegend_visible}`}>
          <CardLegend data={cardLegendData} closeAction={openLegend} />
        </div>
      </div>

      <div className={styles.filtersGroup}>
        <FilterDayComponent getData={setDayFilter} plotData={parcelsFilterData}/>
        <FilterPlagueComponent plagues={plagues} getData={setPlagueFilter} plotData={parcelsFilterData}/>
      </div>

      {selectedPoint && (
        <Modal
          isOpen={showModal}
          onRequestClose={handleCloseModal}
          style={{
            content: {
              top: modalPosition.top,
              left: modalPosition.left,
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: MODAL_WIDTH,
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 6,
            },
          }}
        >
          {selectedPoint}
          <section className={styles.modalButtonsContainer}>
            <Button
              text={intl.formatMessage({ id: COMMON__MODAL_CLOSE })}
              variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
              onClick={handleCloseModal}
            />
          </section>
        </Modal>
      )}
      {showUnavailableDataModal && (
        <Modal
          isOpen={showUnavailableDataModal}
          onRequestClose={handleCloseUnavailableDataModal}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <TypographyComponent
            variant={TEXT.VARIANT_H3}
            text={intl.formatMessage({ id: PARCELS_LIST__MAP_DAYS_MODAL_TITLE })}
          />
          <TypographyComponent
            variant={TEXT.VARIANT_P}
            text={intl.formatMessage({ id: PARCELS_LIST__MAP_DAYS_MODAL_TEXT })}
          />
          <div className={styles.modalCloseButton}>
            <Button
              text={intl.formatMessage({ id: COMMON__MODAL_CLOSE })}
              variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
              onClick={handleCloseUnavailableDataModal}
            />
          </div>
        </Modal>
      )
      }
    </div>
  );
};

export default PublicMapComponent;
