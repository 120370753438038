import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { format } from "date-fns";

import style from "./WarningListComponent.module.css";
import CardWithPointerComponent from "../../../common/molecules/cardWidthPointer/CardWithPointerComponent";
import {
  COMMON__BUTTONS__DELETE,
  COMMON__BUTTONS__MARK__READED,
  WARNINGS__MODAL__SUBTITLE,
  WARNINGS__RISK_LEVEL_LOW,
  WARNINGS__RISK_LEVEL_MEDIUM,
  WARNINGS__RISK_LEVEL_HIGH,
  WARNINGS__RISK_LEVEL_NONE,
} from "../../../../translations/constants";
import ModalWithTwoButtons from "../../../molecules/modalWithTwoButtons/ModalWithTwoButtons";
import { deleteWarningService, patchWarningService } from "../../../../services/commonservices";
import { HIGH, LOW, MEDIUM, NONE } from "../../../pages/parcels/components/maps/mapConstants";

const WarningsListComponent = ({ warnings, getWarnings }) => {
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [subtitleModal, setSubtitleModal] = useState("");
  const [bodyModal, setBodyModal] = useState("");
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [displayReadButton, setDisplayReadButton] = useState(true);
  const [selectedWarning, setSelectedWarning] = useState();

  useEffect(() => {
    const handleWindowResize = () => setWindowSize(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const callback = () => {
    getWarnings();
  };

  const handleDeleteButton = (id) => {
    deleteWarningService(id, callback);
  };

  const handleReadButton = (id, name, description) => {
    const body = {
      name,
      description,
      is_read: true,
    };
    patchWarningService(id, body, callback);
  };

  const transformWarningLevelPointer = (level) => {
    switch (level) {
      case 0:
        return NONE;
      case 1:
        return LOW;
      case 2:
        return MEDIUM;
      case 3:
        return HIGH;
      default:
        return level;
    }
  };
  const transformWarningLevelTranlation = (level) => {
    switch (level) {
      case 0:
        return <FormattedMessage id={WARNINGS__RISK_LEVEL_NONE} />;
      case 1:
        return <FormattedMessage id={WARNINGS__RISK_LEVEL_LOW} />;
      case 2:
        return <FormattedMessage id={WARNINGS__RISK_LEVEL_MEDIUM} />;
      case 3:
        return <FormattedMessage id={WARNINGS__RISK_LEVEL_HIGH} />;
      default:
        return level;
    }
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModal = (warning) => {
    setSelectedWarning(warning.id);
    setTitleModal(warning.name);
    setBodyModal(warning.description);
    setDisplayReadButton(!warning?.is_read);
    setSubtitleModal(transformWarningLevelTranlation(warning.level));

    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();
  };

  return (
    <>
      {windowSize >= 600 ? (
        <ul className={style.listContainer}>
          {warnings?.map((warning, index) => (
            <li key={index}>
              <CardWithPointerComponent
                data={{
                  id: warning.id,
                  title: warning.name,
                  date: !!warning?.date ? format(warning.date * 1000, "dd/MM/yyyy") : " - ",
                  cardStatus: warning.is_read,
                  description: warning.description,
                  pointerStatus: transformWarningLevelPointer(warning.level),
                  textButton1: <FormattedMessage id={COMMON__BUTTONS__DELETE} />,
                  handlerButton1: handleDeleteButton,
                  textButton2: <FormattedMessage id={COMMON__BUTTONS__MARK__READED} />,
                  handlerButton2: handleReadButton,
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <>
          <ul>
            {warnings?.map((warning, index) => (
              <li key={index}>
                <CardWithPointerComponent
                  data={{
                    id: warning.id,
                    title: warning.name,
                    date: !!warning?.date ? format(warning.date * 1000, "dd/MM/yyyy") : " - ",
                    cardStatus: warning.is_read,
                    description: warning.description,
                    pointerStatus: transformWarningLevelPointer(warning.level),
                    warning,
                    handlerState: (warning) => handleOpenModal(warning),
                  }}
                />
              </li>
            ))}
          </ul>
          {openModal && (
            <ModalWithTwoButtons
              warning={selectedWarning}
              data={{
                title: titleModal,
                subtitle: <FormattedMessage id={WARNINGS__MODAL__SUBTITLE} />,
                subtitleData: transformWarningLevelTranlation(subtitleModal),
                body: bodyModal,
                textButton1: <FormattedMessage id={COMMON__BUTTONS__DELETE} />,
                handlerButton1: handleDeleteButton,
                textButton2: <FormattedMessage id={COMMON__BUTTONS__MARK__READED} />,
                handlerButton2: () => handleReadButton(selectedWarning),
                hideButton2: !displayReadButton,
                handleClose: handleCloseModal,
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default WarningsListComponent;
