import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import {
  COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
  CREATE_CELLAR_FORM__TITLE,
  CELLARS_MANAGEMENT__TITLE,
  COMMON__CELLAR,
  EDIT_CELLAR_FORM__TITLE,
  COMMON__INPUT__NAME,
  COMMON__INPUT__CODE,
  COMMON__INPUT__DESCRIPTION,
  COMMON__ERROR_MESSAGE_CELLAR_ALREADY_EXISTS,
} from "../../../translations/constants";
import CreateCellarFormComponent from "./components/CreateCellarForm/CreateCellarFormComponent";
import {
  CREATE_CELLAR_ERROR_FIELD,
  CELLAR_SCHEMA,
  CELLAR_EDIT_SCHEMA,
  EDIT_CELLAR_ERROR_FIELD,
  ADMIN_TABLE_ROWS_CELLARS,
} from "./manageCellarsConstants";
import { ERROR_TYPE_OPTIONALITY, ERROR_TYPE_REQUIRED, HTTP_CODE_409, ROLES_SUPER } from "../../../utils/constants";
import {
  getCellarsService,
  patchCellarService,
  postCreateCellarService,
  deleteCellarService,
} from "../../../services/commonservices";
import withAuthorization from "../../common/auth/withAuthorization";
import AdministrationPageComponent from "../AdministrationPageComponent";
import useCustomIntl from "../../../hook/cutomHook";

const ManageCellarsComponent = () => {
  const intl = useIntl();
  const { formatMessage } = useCustomIntl();

  const [cellars, setCellars] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [displayError, setDisplayError] = useState(false);

  const [key, setKey] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationParameters, setPaginationParameters] = useState({
    limit: ADMIN_TABLE_ROWS_CELLARS,
    offset: 0,
  });
  const [orderParameters, setOrderParemeters] = useState({});

  const [totalRows, setTotalRows] = useState(0);

  /* CREATE MODAL */
  const [createCellar, setCreateCellar] = useState(true);
  const [displayCreateModal, setDisplayCreateModal] = useState(false);
  const cleanFormValues = () => {
    setName("");
    setCode("");
    setDescription("");
  };

  const handleOpenCreateModal = () => {
    cleanFormValues();
    setCreateCellar(true);
    setDisplayCreateModal(!displayCreateModal);
    setDisplayError(false);
    setErrorMessage("");
  };

  /* CELLAR DATA */
  const [cellarId, setCellarId] = useState(null);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");

  const handleSetName = (e) => {
    setName(e.target.value);
  };
  const handleSetCode = (e) => {
    setCode(e.target.value);
  };
  const handleSetDescription = (e) => {
    setDescription(e.target.value);
  };

  const createCellarCallback = () => {
    handleGetCellars();
  };

  const createCellarErrorCallback = (error) => {
    if (error.response.status === HTTP_CODE_409) {
      setErrorMessage(`*${intl.formatMessage({ id: COMMON__ERROR_MESSAGE_CELLAR_ALREADY_EXISTS })}`);
      setDisplayError(true);
    }
  };

  const handleCreateCellar = () => {
    const body = {
      name,
      code,
      description,
    };

    CELLAR_SCHEMA.validate(body)
      .then(() => {
        postCreateCellarService(body, createCellarCallback, createCellarErrorCallback);
        setDisplayError(false);
        setErrorMessage("");
        setDisplayCreateModal(false);
      })
      .catch((error) => {
        if (error.type === ERROR_TYPE_OPTIONALITY || error.type === ERROR_TYPE_REQUIRED) {
          setErrorMessage(
            `*${intl.formatMessage(
              {
                id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
              },
              { field: intl.formatMessage({ id: CREATE_CELLAR_ERROR_FIELD[error.path] }).toLowerCase() },
            )}`,
          );
          setDisplayError(true);
        }
      });
  };

  const editCellarErrorCallback = (error) => {
    console.error(error);
  };

  const handleUpdateCellar = () => {
    const body = {
      name,
      description,
    };

    CELLAR_EDIT_SCHEMA.validate(body)
      .then(() => {
        patchCellarService(cellarId, body, createCellarCallback, editCellarErrorCallback);
        setDisplayError(false);
        setErrorMessage("");
        setDisplayCreateModal(false);
      })
      .catch((error) => {
        if (error.type === ERROR_TYPE_OPTIONALITY || error.type === ERROR_TYPE_REQUIRED) {
          setErrorMessage(
            `*${intl.formatMessage(
              {
                id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
              },
              { field: intl.formatMessage({ id: EDIT_CELLAR_ERROR_FIELD[error.path] }).toLowerCase() },
            )}`,
          );
          setDisplayError(true);
        }
      });
  };

  const getCellars = () =>
    new Promise((resolve) => {
      getCellarsService({ ...paginationParameters, ...orderParameters }, (result) => {
        resolve({ cellars: result.data, totalResults: result.headers["pagination-total-results"] });
      });
    });

  const handleOrderParameters = (orderParam) => {
    setOrderParemeters(orderParam);
  };

  useEffect(() => {
    getCellars().then((result) => {
      setCellars(result.cellars);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderParameters]);

  useEffect(() => {
    setPaginationParameters({ limit: ADMIN_TABLE_ROWS_CELLARS, offset: ADMIN_TABLE_ROWS_CELLARS * (page - 1) });
    if (!!key) {
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleGetCellars = () => {
    getCellars().then((result) => {
      setCellars(result.cellars);
      setTotalRows(parseInt(result.totalResults));
    });
  };

  useEffect(() => {
    if (!!key) {
      handleGetCellars();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    handleGetCellars();
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (value) => {
    const newPage = value ? page + 1 : page - 1;
    setPage(newPage);
  };

  const handleEditCellar = (row) => {
    setCellarId(row.id);
    setName(row.name);
    setCode(row.code);
    setDescription(row.description);

    setCreateCellar(false);
    setDisplayCreateModal(!displayCreateModal);
    setDisplayError(false);
    setErrorMessage("");
  };

  const deleteCellarCallback = () => {
    handleGetCellars();
  };

  const handleDeleteCellar = (row) => {
    deleteCellarService(row.id, deleteCellarCallback);
  };

  const columns = [
    { title: formatMessage(COMMON__INPUT__NAME), width: "minmax(125px,1fr)", field: "name", fieldOrder: "name_order" },
    {
      title: formatMessage(COMMON__INPUT__CODE),
      width: "minmax(100px,0.5fr)",
      field: "code",
      fieldOrder: "code_order",
    },
    {
      title: formatMessage(COMMON__INPUT__DESCRIPTION),
      width: "minmax(150px,1.5fr)",
      field: "description",
      fieldOrder: "description_order",
    },
  ];

  return (
    <AdministrationPageComponent
      title={CELLARS_MANAGEMENT__TITLE}
      columns={columns}
      rows={cellars}
      rowsToShow={ADMIN_TABLE_ROWS_CELLARS}
      totalRows={totalRows}
      buttonText={CREATE_CELLAR_FORM__TITLE}
      modalComponent={
        <CreateCellarFormComponent
          name={name}
          handleSetName={handleSetName}
          code={code}
          handleSetCode={handleSetCode}
          description={description}
          handleSetDescription={handleSetDescription}
          handleCreateCellar={createCellar ? handleCreateCellar : handleUpdateCellar}
          displayError={displayError}
          errorMessage={errorMessage}
          codeDisabled={!createCellar}
        />
      }
      displayCreateModal={displayCreateModal}
      handleOpenCreateModal={handleOpenCreateModal}
      handleEdit={handleEditCellar}
      handleDelete={handleDeleteCellar}
      createTitle={CREATE_CELLAR_FORM__TITLE}
      editTitle={EDIT_CELLAR_FORM__TITLE}
      deleteTitle={COMMON__CELLAR}
      handleChangePage={handleChangePage}
      handleOrderParameters={handleOrderParameters}
    />
  );
};

export default withAuthorization(ROLES_SUPER)(ManageCellarsComponent);
