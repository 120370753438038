import style from "./Return.module.css";
import React from "react";

const ReturnComponent = ({ url, text }) => {
  return (
    <div className={style.return}>
      <a href={url}>{text}</a>
    </div>
  );
};

export default ReturnComponent;
