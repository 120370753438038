export const APP_BASE_ROUTE = "/" + process.env.REACT_APP_BASE_ROUTE;

export const ROUTE_LANDING = "/";
export const ROUTE_REGISTER = "/register";
export const ROUTE_PARCELS = "/parcels";
export const ROUTE_PARCEL_DETAIL = "/parcel-detail/:id";
export const ROUTE_PARCEL_SIMPLIFIED_INFORMATION = "/parcel-simplified-information";
export const ROUTE_WARNINGS = "/warnings";
export const ROUTE_PHYTOSANITARY_PRODUCTS = "/phytosanitary-products";
export const ROUTE_MANAGE_CELLARS = "/administration/cellars";
export const ROUTE_MANAGE_PARCELS = "/administration/parcels";
export const ROUTE_MANAGE_USERS = "/administration/users";
export const ROUTE_MANAGE_WARNINGS = "/administration/warnings";
export const ROUTE_OTHER = "*";
