import React from "react";
import TagComponent from "../../atoms/tag/TagComponent";
import style from "./SelectGroup.module.css";
import SelectComponent from "../../atoms/select/SelectComponent";
import PropTypes from "prop-types";
import styles from "../../atoms/button/Button.module.css";

const SelectGroupComponent = ({ data, className = "" }) => {
  const results = data.fetching(data.tags, data.data);

  const handleAddTag = (event) => {
    const id = event.target.value;
    const exist = results.find((plague) => +plague.id === +id);

    data.setTags([exist, ...data.tags]);
  };

  const removePlagueFilter = (plagueId) => {
    const newFilters = data.tags.filter((plague) => {
      return plague.id !== plagueId;
    });

    data.setTags(newFilters);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <SelectComponent
        key={data.name}
        data={results}
        label={data.label}
        fieldLabel={data.fieldLabel}
        fieldLValue={data.fieldLValue}
        labelOption={data.labelOption}
        variant={data.variant}
        handleOnchange={handleAddTag}
        fetching={data.fetching}
        initialOption={data.initialOption}
        initialValue={data.initialValue}
        initialText={data.initialText}
      />
      <div className={style.tagContainer}>
        {data.tags.map((item, index) => (
          <TagComponent
            key={item?.text + index}
            text={item?.name || " - "}
            onClick={() => removePlagueFilter(item?.id)}
          />
        ))}
      </div>
    </div>
  );
};

SelectGroupComponent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default SelectGroupComponent;
