import React from "react";
import style from "./ParcelItem.module.css";
import { NavLink } from "react-router-dom";
import { TEXT } from "../typography/typographyConstants";
import TypographyComponent from "../typography/TypographyComponent";
import { buildUrl } from "../../../../utils/axiosRequests";
import { ROUTE_PARCEL_DETAIL } from "../../../../utils/routes";

const ParcelItemComponent = ({ parcel }) => {
  const urlParcelDetail = buildUrl(ROUTE_PARCEL_DETAIL, { id: parcel.id });
  return (
    <NavLink to={urlParcelDetail} className={style.container}>
      <TypographyComponent text={parcel.name} variant={TEXT.VARIANT_P} />
    </NavLink>
  );
};

export default ParcelItemComponent;
