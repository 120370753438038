import React from "react";
import { PARCELS_LIST__TITLE } from "../../../../../translations/constants";
import { TEXT } from "../../../../common/atoms/typography/typographyConstants";
import TypographyComponent from "../../../../common/atoms/typography/TypographyComponent";
import style from "./ParcelList.module.css";
import ParcelItemComponent from "../../../../common/atoms/parcelItem/ParcelItemComponent";
import useCustomIntl from "../../../../../hook/cutomHook";

const ParcelsListComponent = ({ parcels }) => {
  const { formatMessage } = useCustomIntl();

  return (
    <div className={style.container}>
      <TypographyComponent variant={TEXT.VARIANT_H3} text={formatMessage(PARCELS_LIST__TITLE)} />
      <div className={style.listContainer}>
        {parcels.map((parcel, index) => (
          <ParcelItemComponent parcel={parcel} key={parcel.name + index} />
        ))}
      </div>
    </div>
  );
};

export default ParcelsListComponent;
