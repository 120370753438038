import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { timestampToDaysNoFormat } from "../../../../../../utils/utils";
import "ol/ol.css";
import "../map.module.css";
import {
  MAP_DAY_TODAY,
  PARCELS_LIST__MAP_DAYS_FILTER,
  WEEKDAY_MONDAY,
  WEEKDAY_TUESDAY,
  WEEKDAY_WEDNESDAY,
  WEEKDAY_THURSDAY,
  WEEKDAY_FRIDAY,
  WEEKDAY_SATURDAY,
  WEEKDAY_SUNDAY,
  MONTH_JANUARY,
  MONTH_FEBRUARY,
  MONTH_MARCH,
  MONTH_APRIL,
  MONTH_MAY,
  MONTH_JUNE,
  MONTH_JULY,
  MONTH_AUGUST,
  MONTH_SEPTEMBER,
  MONTH_OCTOBER,
  MONTH_NOVEMBER,
  MONTH_DECEMBER,
} from "../../../../../../translations/constants";
import { SELECT_FIELD_LABEL, SELECT_FIELD_VALUE } from "../mapConstants";
import Select from "../../../../../common/atoms/select/SelectComponent";

const FilterDayComponent = ({filterDayId, getData, plotData }) => {
  const intl = useIntl();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const afterTomorrow = new Date(today);
  afterTomorrow.setDate(today.getDate() + 2);
  const overmorrow = new Date(today);
  overmorrow.setDate(today.getDate() + 3);
  const [daySelected, setDaySelected] = useState(filterDayId);
  let previousDaySelected = 1;

  const formatTextDate = (date, startWithCapital = true) => {    
    const daysOfWeek = [WEEKDAY_SUNDAY, WEEKDAY_MONDAY, WEEKDAY_TUESDAY, WEEKDAY_WEDNESDAY, WEEKDAY_THURSDAY, WEEKDAY_FRIDAY, WEEKDAY_SATURDAY];
    const months = [MONTH_JANUARY, MONTH_FEBRUARY, MONTH_MARCH, MONTH_APRIL, MONTH_MAY, MONTH_JUNE, MONTH_JULY, MONTH_AUGUST, MONTH_SEPTEMBER, MONTH_OCTOBER, MONTH_NOVEMBER, MONTH_DECEMBER];
  
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    
    if (startWithCapital) {
      return `${intl.formatMessage({ id: dayOfWeek })} ${dayOfMonth} ${intl.formatMessage({ id: month })}`;
    }
    else {
      return `${intl.formatMessage({ id: dayOfWeek }).toLocaleLowerCase()} ${dayOfMonth} ${intl.formatMessage({ id: month })}`;
    }    
  }

  const daysAvailable =
    [
      { "name": intl.formatMessage({ id: MAP_DAY_TODAY }, { date: formatTextDate(today, false) }), "timestamp": Math.floor(today.getTime() / 1000), "id": 1 },
      { "name": formatTextDate(tomorrow), "timestamp": Math.floor(tomorrow.getTime() / 1000), "id": 2 },
      { "name": formatTextDate(afterTomorrow), "timestamp": Math.floor(afterTomorrow.getTime() / 1000), "id": 3 },
      { "name": formatTextDate(overmorrow), "timestamp": Math.floor(overmorrow.getTime() / 1000), "id": 4 }
    ];

  const handleFilterDays = (event) => {
    let selection = daysAvailable.find(day => (day.id === parseInt(event.target.value)))
    if (selection) {
      previousDaySelected = daySelected;
      setDaySelected(selection.id);
      getData(timestampToDaysNoFormat(selection.timestamp));
    }    
  };

  useEffect(() => {
    if (plotData.length === 0) {
      setDaySelected(previousDaySelected);
      let selection = daysAvailable.find(day => (day.id === parseInt(previousDaySelected)))
      getData(timestampToDaysNoFormat(selection.timestamp))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotData]);

  return (
    <div>
      <Select
        data={daysAvailable}
        fieldLabel={SELECT_FIELD_LABEL}
        fieldLValue={SELECT_FIELD_VALUE}
        handleOnchange={handleFilterDays}
        labelOption={intl.formatMessage({ id: PARCELS_LIST__MAP_DAYS_FILTER })}
        defaultValue={daySelected}
        initialOption={true}
        initialValue={""}
        initialText={intl.formatMessage({ id: PARCELS_LIST__MAP_DAYS_FILTER })}
      >
      </Select>
    </div>
  )
};
export default FilterDayComponent;