import React from "react";
import PropTypes from "prop-types";
import { TEXT } from "./typographyConstants";

function TypographyComponent({ text, variant = TEXT.VARIANT_P, className }) {
  return React.createElement(variant, { className }, text);
}

export default TypographyComponent;
TypographyComponent.propTypes = {
  text: PropTypes.any,
  variant: PropTypes.oneOf([
    TEXT.VARIANT_H1,
    TEXT.VARIANT_H2,
    TEXT.VARIANT_H3,
    TEXT.VARIANT_H4,
    TEXT.VARIANT_P,
    TEXT.VARIANT_SPAN,
  ]),
  className: PropTypes.string,
};
