import React from "react";
import RoundButtonIconComponent from "../../common/RoundButtonIconComponent";
import RoundButtonComponent from "../../common/RoundButtonComponent";
import SelectComponent from "../../common/SelectComponent";
import TypographyComponent from "../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../common/atoms/typography/typographyConstants";

const ParcelDetailHeaderComponent = () => {
  const listEFenologicos = ["Botones florales separados", "Botones florales separados 2"];

  const handleAddTreatment = () => {
    console.error("Lanzamos modal para crear un tratamiento");
  };
  const handleChangeFenologico = () => {
    console.error("Cambia el select de E. Fenológico");
  };

  return (
    <>
      <TypographyComponent text={"Parcela 01"} variant={TEXT.VARIANT_H1} />
      <RoundButtonIconComponent handleOnClick={handleAddTreatment} icon={"fas fa-times"} />
      <RoundButtonComponent handleOnClick={handleAddTreatment} body={"Ver info. simplificada"} />
      <RoundButtonIconComponent handleOnClick={handleAddTreatment} icon={"fas fa-info"} />
      <RoundButtonIconComponent handleOnClick={handleAddTreatment} icon={"fas fa-help"} />
      <SelectComponent handelOnchange={handleChangeFenologico} options={listEFenologicos} title={"E.Fenológico"} />
      <RoundButtonComponent
        handleOnClick={handleAddTreatment}
        body={<i className={"fas PestControl"}></i> + "Filtrar plaga"}
      />
    </>
  );
};

export default ParcelDetailHeaderComponent;
