import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "../../CreateParcelForm.module.css";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import SelectComponent from "../../../../../../common/atoms/select/SelectComponent";
import InputComponent from "../../../../../../common/atoms/input/InputComponent";
import { INPUT, INPUT_VARIANT_SECONDARY } from "../../../../../../common/atoms/input/inputConstants";
import { getZonesService } from "../../../../../../../services/commonservices";
import { LOCATION_TYPES, ORIENTATION_TYPES } from "../../../../manageParcelsConstants";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import {
  CREATE_PARCEL_FORM__INPUT__LOCATION,
  CREATE_PARCEL_FORM__INPUT__ORIENTATION,
  CREATE_PARCEL_FORM__INPUT__SURFACE,
  CREATE_PARCEL_FORM__INPUT__ZONE,
  CREATE_PARCEL_FORM__SECTION_LOCATION,
} from "../../../../../../../translations/constants";

const LocationFormComponent = ({
  zone,
  handleSetZone,
  location,
  handleSetLocation,
  surface,
  handleSetSurface,
  orientation,
  handleSetOrientation,
  createParcel,
}) => {
  const { formatMessage } = useCustomIntl();

  const [zoneInput, setZoneInput] = useState({
    id: 10,
    data: [],
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__ZONE),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetZone,
    initialOption: true,
    defaultValue: zone,
    defaultText: zone,
    required: createParcel,
    disabled: !createParcel,
  });

  const zonesCallback = (response) => {
    setZoneInput({ ...zoneInput, data: response.data });
  };

  const getZones = () => {
    getZonesService(zonesCallback);
  };

  useEffect(() => {
    getZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    zone && setZoneInput({ ...zoneInput, defaultValue: zone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone]);

  const locationInput = {
    id: 11,
    data: LOCATION_TYPES,
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__LOCATION),
    variant: INPUT_VARIANT_SECONDARY,
    initialOption: true,
    onChange: handleSetLocation,
    defaultValue: location,
    defaultText: location,
    disabled: !createParcel,
  };

  const surfaceInput = {
    id: 12,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__SURFACE),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__SURFACE),
    type: INPUT.TYPE_NUMBER,
    value: surface,
    onChange: handleSetSurface,
    disabled: !createParcel,
  };

  const orientationInput = {
    id: 13,
    data: ORIENTATION_TYPES,
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__ORIENTATION),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetOrientation,
    initialOption: true,
    defaultValue: orientation,
    defaultText: orientation,
    disabled: !createParcel,
  };

  return (
    <div className={styles.infoBlock}>
      <TypographyComponent text={formatMessage(CREATE_PARCEL_FORM__SECTION_LOCATION)} variant={TEXT.VARIANT_H3} />
      <div className={styles.locationInputs}>
        <SelectComponent
          data={zoneInput.data}
          label={zoneInput.label}
          variant={zoneInput.variant}
          handleOnchange={zoneInput.onChange}
          initialOption={zoneInput.initialOption}
          defaultValue={zoneInput.defaultValue}
          defaultText={zoneInput.defaultText}
          required={zoneInput.required}
          verticalPlacementLabel
          disabled={zoneInput.disabled}
        />
        <SelectComponent
          data={locationInput.data}
          label={locationInput.label}
          fieldLabel={locationInput.fieldLabel}
          fieldLValue={locationInput.fieldLValue}
          variant={locationInput.variant}
          handleOnchange={locationInput.onChange}
          initialOption={locationInput.initialOption}
          defaultValue={locationInput.defaultValue}
          defaultText={locationInput.defaultText}
          verticalPlacementLabel
          disabled={locationInput.disabled}
        />
        <InputComponent data={surfaceInput} onChange={surfaceInput.onChange} value={surfaceInput.value} />
        <SelectComponent
          data={orientationInput.data}
          label={orientationInput.label}
          fieldLabel={orientationInput.fieldLabel}
          fieldLValue={orientationInput.fieldLValue}
          variant={orientationInput.variant}
          handleOnchange={orientationInput.onChange}
          initialOption={orientationInput.initialOption}
          defaultValue={orientationInput.defaultValue}
          defaultText={orientationInput.defaultText}
          verticalPlacementLabel
          disabled={orientationInput.disabled}
        />
      </div>
    </div>
  );
};

LocationFormComponent.propTypes = {
  zone: PropTypes.string.isRequired,
  handleSetZone: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  handleSetLocation: PropTypes.func.isRequired,
  surface: PropTypes.number.isRequired,
  handleSetSurface: PropTypes.func.isRequired,
  orientation: PropTypes.string.isRequired,
  handleSetOrientation: PropTypes.func.isRequired,
};

export default LocationFormComponent;
