export const CHART_PESTS__COLUMN__AXIS_ID__LEFT = "y-axis-l";
export const CHART_PESTS__COLUMN__AXIS_ID__RIGHT = "y-axis-r";

export const CHART_PESTS__COLUMN__BACKGROUND_COLOR__FOLIAR_WETTING = "#ccdaff";
export const CHART_PESTS__COLUMN__BORDER_COLOR__FOLIAR_WETTING = "#ccdaff";

export const CHART_PESTS__COLUMN__BACKGROUND_COLOR__RELATIVE_HUMIDITY = "#737475";
export const CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__RELATIVE_HUMIDITY = "#737475";
export const CHART_PESTS__COLUMN__BORDER_COLOR__RELATIVE_HUMIDITY = "#b7b6b6";

export const CHART_PESTS__COLUMN__BACKGROUND_COLOR__INSOLATION = "#fcdb03";
export const CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__INSOLATION = "#fcdb03";
export const CHART_PESTS__COLUMN__BORDER_COLOR__INSOLATION = "#fcdb03";

export const CHART_PESTS__COLUMN__BACKGROUND_COLOR__MAX_TEMPERATURE = "rgba(175,0,9,0.08)";
export const CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__MAX_TEMPERATURE = "#ff989b";
export const CHART_PESTS__COLUMN__BORDER_COLOR__MAX_TEMPERATURE = "#ff989b";

export const CHART_PESTS__COLUMN__BACKGROUND_COLOR__AVG_TEMPERATURE = "#ff989b";
export const CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__AVG_TEMPERATURE = "#ff989b";
export const CHART_PESTS__COLUMN__BORDER_COLOR__AVG_TEMPERATURE = "#ff989b";

export const CHART_PESTS__COLUMN__BACKGROUND_COLOR__MIN_TEMPERATURE = "#ff989b";
export const CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__MIN_TEMPERATURE = "#ff989b";
export const CHART_PESTS__COLUMN__BORDER_COLOR__MIN_TEMPERATURE = "#ff989b";

export const CHART_PESTS__COLUMN__BACKGROUND_COLOR__PRECIPITATION = "#7FBCE1";
export const CHART_PESTS__COLUMN__BORDER_COLOR__PRECIPITATION = "#7FBCE1";

export const CHART_PESTS__COLUMN_TYPE__BAR = "bar";
export const CHART_PESTS__COLUMN_TYPE__LINE = "line";
