import React from "react";
import style from "./Pointer.module.css";

const PointerComponent = ({ number, status, size }) => {
  return (
    <div className={`${style.container} ${style[status]}`} style={{ width: size, height: size }}>
      {number}
    </div>
  );
};

export default PointerComponent;
