import React from "react";
import { timestampToDays } from "../../../../../../../utils/utils";
import { useIntl } from "react-intl";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./lineTreatmentDisclaimer.module.css";
import { DISCLAIMER_TREATMENT_MESSAGE } from "../../../../../../../translations/constants";

const LineTreatmentDisclaimerComponent = ({ day, closeDisclaimer }) => {
  const intl = useIntl();

  return (
    <div className={styles.lineContainer}>
      <span className={styles.disclaimerMessage}>
        <ReportProblemOutlinedIcon />
        {intl.formatMessage({ id: DISCLAIMER_TREATMENT_MESSAGE }, { day: timestampToDays(day) })}
      </span>
      <CloseIcon className={styles.closeButton} onClick={closeDisclaimer} />
    </div>
  );
};

export default LineTreatmentDisclaimerComponent;
