import React from "react";
import PropTypes from "prop-types";

function TableComponent({ header, columns, rows, handleOpenModal }) {
  return (
    <>
      La tabla {header} {columns} {rows} {handleOpenModal}
    </>
  );
}

TableComponent.propTypes = {
  header: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  handleOpenModal: PropTypes.func,
};

export default TableComponent;
