import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { BUTTON } from "./atoms/button/buttonConstants";
import ButtonComponent from "./atoms/button/ButtonComponent";
import { BREAKPOINT_MD } from "../../utils/constants";

const AlertDialog = ({ buttonCloseText, buttonAcceptText, onSave, description, onClose, open, title, maxWidth }) => {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonComponent text={buttonCloseText} variant={BUTTON.VARIANT__SECONDARY_LIMIT_WITH} onClick={onClose} />
        {!!buttonAcceptText && !!onSave && (
          <ButtonComponent text={buttonAcceptText} variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH} onClick={onSave} />
        )}
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  buttonCloseText: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.object.isRequired,
  buttonAcceptText: PropTypes.string,
  onSave: PropTypes.func,
};

AlertDialog.defaultProps = {
  maxWidth: BREAKPOINT_MD,
  buttonAcceptText: "",
  onSave: undefined,
};

export default AlertDialog;
