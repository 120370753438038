import React from "react";
import PropTypes from "prop-types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { FormattedMessage } from "react-intl";

import {
  PHYTOSANITARY__PRODUCTS__NUM__REG,
  PHYTOSANITARY__PRODUCTS__OWNER,
  PHYTOSANITARY__PRODUCTS__FORM,
  PHYTOSANITARY__PRODUCTS__PLAGUES,
  PHYTOSANITARY__PRODUCTS__DATA__SHEET,
} from "../../../../translations/constants";
import CardWithButtonComponent from "../../../common/molecules/cardWithButton/CardWithButtonComponent";
import style from "./productsListComponent.module.css";

const ProductsListComponent = ({ products }) => {
  const handleOpenDatasheet = (event, url_datasheet) => {
    event.preventDefault();
    window.open(url_datasheet, "_blank");
  };

  return (
    <ul className={style.listContainer}>
      {products.map(({ form, id, name, num_reg, owner, plagues, url_datasheet }) => (
        <li key={id} className={style.productCard}>
          <CardWithButtonComponent
            titulo={name}
            data={[
              { metaTitle: <FormattedMessage id={PHYTOSANITARY__PRODUCTS__NUM__REG} />, metaValue: num_reg },
              { metaTitle: <FormattedMessage id={PHYTOSANITARY__PRODUCTS__OWNER} />, metaValue: owner },
              { metaTitle: <FormattedMessage id={PHYTOSANITARY__PRODUCTS__FORM} />, metaValue: form },
              {
                metaTitle: <FormattedMessage id={PHYTOSANITARY__PRODUCTS__PLAGUES} />,
                metaValue: plagues.map((plague) => `${plague.name} `),
              },
            ]}
            buttonIcon={<OpenInNewIcon />}
            buttonText={<FormattedMessage id={PHYTOSANITARY__PRODUCTS__DATA__SHEET} />}
            onClick={(event) => handleOpenDatasheet(event, url_datasheet)}
            disabled={!url_datasheet}
          />
        </li>
      ))}
    </ul>
  );
};

ProductsListComponent.propTypes = {
  product: PropTypes.object,
  name: PropTypes.string,
  num_reg: PropTypes.number,
  owner: PropTypes.string,
  plagues: PropTypes.array,
  handleOnClick: PropTypes.func,
  url_datasheet: PropTypes.string,
};

export default ProductsListComponent;
