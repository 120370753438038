import React, { useEffect, useState } from "react";

import SelectComponent from "../../../../common/atoms/select/SelectComponent";
import style from "./FilterBar.module.css";
import InputComponent from "../../../../common/atoms/input/InputComponent";
import { INPUT } from "../../../../common/atoms/input/inputConstants";
import {
  PARCELS_LIST__CELLARS_FILTER,
  PARCELS_LIST__FILTER_DEFAULT_OPTION_GRAPE_VARIETY,
  PARCELS_LIST__FILTER_DEFAULT_OPTION_ZONE,
  PARCELS_LIST__FILTER_TITLES,
  PARCELS_LIST__PARCEL_SEARCH_PLACEHOLDER,
} from "../../../../../translations/constants";
import TypographyComponent from "../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../common/atoms/typography/typographyConstants";
import { getCellarsService, getVarietiesGrapesService, getZonesService } from "../../../../../services/commonservices";
import { SELECT_FIELD_LABEL, SELECT_FIELD_VALUE } from "../maps/mapConstants";
import useCustomIntl from "../../../../../hook/cutomHook";
import { hasRole } from "../../../../../utils/keycloak";
import { ROLE_AGACAL_BASIC_USER } from "../../../../../utils/constants";

const FilterBar = ({
  handleFilterZone,
  handleFilterVarietyGrapes,
  selectedZone,
  selectedGrape,
  handleFilterCellars,
  selectedCellar,
  handleSearch,
}) => {
  const { formatMessage } = useCustomIntl();

  const [cellars, setCellars] = useState({});
  const [grapes, setGrapes] = useState({});
  const [zones, setZones] = useState({});

  const filters = [zones, grapes, cellars];

  const search = {
    id: "search-input",
    name: "search-input",
    disable: false,
    type: INPUT.TYPE_SEARCH,
    value: "",
    idTextPlaceholder: PARCELS_LIST__PARCEL_SEARCH_PLACEHOLDER,
  };

  const createObjetToSelect = (
    data,
    fieldLabel = SELECT_FIELD_LABEL,
    fieldValue = SELECT_FIELD_VALUE,
    handle,
    labelId,
    selectedOption,
    initialOption,
  ) => {
    return {
      data: data,
      fieldLabel: fieldLabel,
      fieldLValue: fieldValue,
      handleOnchange: handle,
      labelOption: formatMessage(labelId),
      defaultValue: selectedOption,
      initialOption: formatMessage(initialOption),
    };
  };

  const setZonesObjet = (zonesResponse) => {
    const zoneObjetToSelect = zonesResponse.data.map((zone) => {
      return { id: zone, name: zone };
    });
    setZones(
      createObjetToSelect(
        zoneObjetToSelect,
        SELECT_FIELD_LABEL,
        SELECT_FIELD_VALUE,
        handleFilterZone,
        PARCELS_LIST__FILTER_DEFAULT_OPTION_ZONE,
        selectedZone,
        PARCELS_LIST__FILTER_DEFAULT_OPTION_ZONE,
      ),
    );
  };

  const setGrapesObjet = (grapesResponse) => {
    const grapeObjetToSelect = grapesResponse.data.map((grape) => {
      return { id: grape, name: grape };
    });
    setGrapes(
      createObjetToSelect(
        grapeObjetToSelect,
        SELECT_FIELD_LABEL,
        SELECT_FIELD_VALUE,
        handleFilterVarietyGrapes,
        PARCELS_LIST__FILTER_DEFAULT_OPTION_GRAPE_VARIETY,
        selectedGrape,
        PARCELS_LIST__FILTER_DEFAULT_OPTION_GRAPE_VARIETY,
      ),
    );
  };

  const setCellarsObjet = (cellarsResponse) => {
    const cellarObjetToSelect = cellarsResponse.data.map((cellar) => {
      return { id: cellar.id, name: cellar.name };
    });
    setCellars(
      createObjetToSelect(
        cellarObjetToSelect,
        SELECT_FIELD_LABEL,
        SELECT_FIELD_VALUE,
        handleFilterCellars,
        PARCELS_LIST__CELLARS_FILTER,
        selectedCellar,
        PARCELS_LIST__CELLARS_FILTER,
      ),
    );
  };

  useEffect(() => {
    getZonesService(setZonesObjet);
    getVarietiesGrapesService(setGrapesObjet);
    !hasRole(ROLE_AGACAL_BASIC_USER) && getCellarsService(setCellarsObjet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newZone = { ...zones, defaultValue: selectedZone };
    setZones(newZone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone]);

  useEffect(() => {
    const newGrape = { ...grapes, defaultValue: selectedGrape };
    setGrapes(newGrape);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGrape]);

  useEffect(() => {
    const newCellar = { ...cellars, defaultValue: selectedCellar };
    setCellars(newCellar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCellar]);

  return (
    <section className={style.container}>
      <TypographyComponent variant={TEXT.VARIANT_H2} text={formatMessage(PARCELS_LIST__FILTER_TITLES)} />
      <div className={style.inputContainer}>
        <InputComponent data={search} onChange={handleSearch} />
        {filters?.map((item, index) =>
          !!item.data?.length ? (
            <SelectComponent
              data={item.data}
              fieldLabel={item.fieldLabel}
              fieldLValue={item.fieldLValue}
              handleOnchange={item.handleOnchange}
              labelOption={item.labelOption}
              defaultValue={item.defaultValue}
              key={item.data?.id || index}
              initialOption={true}
              initialValue={""}
              initialText={item.initialOption}
            />
          ) : null,
        )}
      </div>
    </section>
  );
};

export default FilterBar;
