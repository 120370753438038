import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import "ol/ol.css";
import "../map.module.css";
import {
    PARCELS_LIST__MAP_PLAGUES_FILTER
} from "../../../../../../translations/constants";
import { SELECT_FIELD_LABEL, SELECT_FIELD_VALUE } from "../mapConstants";
import Select from "../../../../../common/atoms/select/SelectComponent";

const FilterPlagueComponent = ({ plagues, getData, plotData }) => {
    const intl = useIntl();

    const [plagueSelected, setPlagueSelected] = useState("");
    let previousPlagueSelected = ""

    const handleFilterPlagues = (event) => {
        const idPlague = event.target.value;
        if (idPlague) {
            setPlagueSelected(idPlague);
            getData(idPlague);
            previousPlagueSelected = idPlague;
        } else {
            setPlagueSelected("");
            getData("");
            previousPlagueSelected = ""
        }            
    };

    useEffect(() => {
        if (plotData.length === 0) {
          setPlagueSelected(previousPlagueSelected);
          getData(previousPlagueSelected)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [plotData]);

    return (
        <Select
            data={plagues}
            fieldLabel={SELECT_FIELD_LABEL}
            fieldLValue={SELECT_FIELD_VALUE}
            handleOnchange={handleFilterPlagues}
            defaultValue={plagueSelected}
            initialOption={true}
            initialValue={""}
            initialText={intl.formatMessage({ id: PARCELS_LIST__MAP_PLAGUES_FILTER })}
        >
        </Select>
    )
};
export default FilterPlagueComponent;