export const riskLevel0 = (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="49.5" fill="black" stroke="black" />
  </svg>
);

export const riskLevel1 = (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="49" fill="url(#paint0_linear_302_118)" stroke="black" strokeWidth="2" />
    <defs>
      <linearGradient id="paint0_linear_302_118" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop offset="0.5" stopOpacity="0" />
        <stop offset="0.5" />
      </linearGradient>
    </defs>
  </svg>
);

export const riskLevel2 = (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="49" fill="url(#paint0_linear_302_119)" stroke="black" strokeWidth="2" />
    <defs>
      <linearGradient id="paint0_linear_302_119" x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
        <stop offset="0.8" stopOpacity="0" />
        <stop offset="0.8" />
      </linearGradient>
    </defs>
  </svg>
);

export const getCircleIcon = (fillId, offset) => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="45" fill={`url(#${fillId})`} stroke="black" strokeWidth="10" />
      <defs>
        <linearGradient id={fillId} x1="50" y1="0" x2="50" y2="100" gradientUnits="userSpaceOnUse">
          <stop offset={offset} stopOpacity="0" />
          <stop offset={offset} />
        </linearGradient>
      </defs>
    </svg>
  );
};
