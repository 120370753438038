import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  COMMON__ERROR_MESSAGE_USER_ALREADY_EXISTS,
  COMMON__USER,
  COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
  COMMON__VALIDATION_FIELD_ERROR_MESSAGE_NOT_VALID,
  COMMON__VALIDATION_FIELD_ERROR_PASSWORDS_MATCH,
  CREATE_USER_FORM__INPUT__EMAIL,
  CREATE_USER_FORM__TITLE,
  EDIT_USER_FORM__TITLE,
  USERS_MANAGEMENT__TITLE,
  COMMON__INPUT__USER_NAME,
  COMMON__INPUT__EMAIL,
  COMMON__INPUT__ROL,
} from "../../../translations/constants";
import CreateUserFormComponent from "./components/createUserForm/CreateUserFormComponent";
import { filterObject } from "../../../utils/utils";
import {
  deleteUserService,
  getUserCellarsService,
  getUserPlotsService,
  getUsersService,
  postCreateUserService,
  updateUserCellarsService,
  updateUserPlotsService,
} from "../../../services/commonservices";
import {
  AGACAL_API_USER,
  AGACAL_API_USER_CELLARS,
  AGACAL_API_USER_CHANGE_PASS,
  AGACAL_API_USER_PLOTS,
  ERROR_TYPE_EMAIL,
  ERROR_TYPE_OPTIONALITY,
  ERROR_TYPE_REQUIRED,
  HTTP_CODE_409, ROLE_AGACAL_BASIC_USER,
  ROLES_GENERAL_ADMIN,
} from "../../../utils/constants";
import { USER_SCHEMA, CREATE_USER_ERROR_FIELD, ADMIN_TABLE_ROWS_USERS } from "./manageUsersContants";
import withAuthorization from "../../common/auth/withAuthorization";
import AdministrationPageComponent from "../AdministrationPageComponent";
import { buildUrl, buildUrlWithParameters } from "../../../utils/axiosRequests";
import axios from "axios";
import keycloak from "../../../utils/keycloak";
import useCustomIntl from "../../../hook/cutomHook";

const ManageUsersComponent = () => {
  const intl = useIntl();
  const { formatMessage } = useCustomIntl();

  const [users, setUsers] = useState([]);

  const [createUser, setCreateUser] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(ROLE_AGACAL_BASIC_USER);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [selectedParcels, setSelectedParcels] = useState([]);
  const [selectedCellars, setSelectedCellars] = useState([]);

  const [user, setUser] = useState(null);
  const [userParcels, setUserParcels] = useState([]);
  const [userCellars, setUserCellars] = useState([]);

  const [displayCreateModal, setDisplayCreateModal] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [key, setKey] = useState(0);
  const [page, setPage] = useState(1);
  const [paginationParameters, setPaginationParameters] = useState({
    limit: ADMIN_TABLE_ROWS_USERS,
    offset: 0,
  });
  const [orderParameters, setOrderParemeters] = useState({});
  const [totalRows, setTotalRows] = useState(0);

  const handleSetUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSetRole = (e) => {
    setRole(e.target.value);
  };

  const handleSetPassword1 = (e) => {
    setPassword1(e.target.value);
  };

  const handleSetPassword2 = (e) => {
    setPassword2(e.target.value);
  };

  const handleSetSelectedParcels = (value) => {
    setSelectedParcels(value);
  };

  const handleSetSelectedCellars = (value) => {
    setSelectedCellars(value);
  };

  const handleOpenCreateModal = () => {
    setCreateUser(true);
    setDisplayCreateModal(!displayCreateModal);

    if (displayCreateModal) {
      setUsername("");
      setEmail("");
      setRole(ROLE_AGACAL_BASIC_USER);
      setPassword1("");
      setPassword2("");
      setSelectedParcels([]);
      setSelectedCellars([]);
      setDisplayCreateModal(false);
      setErrorMessage("");
      setDisplayError(false);
      setUser(null);
      setUserParcels([]);
      setUserCellars([]);
    }
  };

  const updateCellarsCallback = () => {
    handleGetUsers();
    handleOpenCreateModal();
  };

  const updatePlotsCallback = (r) => {
    const formattedCellars = selectedCellars.map((c) => c.id);
    updateUserCellarsService(r.data.id, formattedCellars, updateCellarsCallback);
  };

  const createUserCallback = (response) => {
    const formattedParcels = selectedParcels.map((p) => p.id);
    updateUserPlotsService(response.data.id, formattedParcels, () => updatePlotsCallback(response));
  };

  const createUserErrorCallback = (error) => {
    if (error.response.status === HTTP_CODE_409) {
      setErrorMessage(`*${intl.formatMessage({ id: COMMON__ERROR_MESSAGE_USER_ALREADY_EXISTS })}`);
      setDisplayError(true);
    }
  };

  const handleUpdateUser = () => {
    const promises = [];

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${!!keycloak && !!keycloak.token ? keycloak.token : undefined}`,
      },
    };

    if (user.role !== role) {
      const body = {
        role,
      };

      const url = buildUrl(AGACAL_API_USER, { user_id: user.id });
      promises.push(axios.patch(url, body, options));
    }

    if (password1 !== password2) {
      setErrorMessage(`*${intl.formatMessage({ id: COMMON__VALIDATION_FIELD_ERROR_PASSWORDS_MATCH })}`);
      setDisplayError(true);
    } else if (password1 && password2 && password1 === password2) {
      const parameters = {
        username,
        new_password: password1,
      };

      const url = buildUrlWithParameters(AGACAL_API_USER_CHANGE_PASS, parameters);
      promises.push(axios.post(url, {}, options));
    }

    if (JSON.stringify(userParcels) !== JSON.stringify(selectedParcels)) {
      const formattedParcels = selectedParcels.map((p) => p.id);

      const url = buildUrl(AGACAL_API_USER_PLOTS, { user_id: user.id });
      promises.push(axios.put(url, formattedParcels, options));
    }

    if (JSON.stringify(userCellars) !== JSON.stringify(selectedCellars)) {
      const formattedCellars = selectedCellars.map((c) => c.id);

      const url = buildUrl(AGACAL_API_USER_CELLARS, { user_id: user.id });
      promises.push(axios.put(url, formattedCellars, options));
    }

    Promise.all(promises)
      .then((response) => {
        getUsers().then((result) => {
          setUsers(result.users);
        });
        handleOpenCreateModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCreateUser = () => {
    if (password1 !== password2) {
      setErrorMessage(`*${intl.formatMessage({ id: COMMON__VALIDATION_FIELD_ERROR_PASSWORDS_MATCH })}`);
      setDisplayError(true);
      return;
    }

    const body = {
      username,
      email,
      role,
      password: password1,
    };

    const formattedBody = filterObject(body);

    USER_SCHEMA.validate(formattedBody)
      .then(() => {
        postCreateUserService(formattedBody, createUserCallback, createUserErrorCallback);
        setDisplayError(false);
        setErrorMessage("");
      })
      .catch((error) => {
        let message;

        if (error.type === ERROR_TYPE_EMAIL) {
          setDisplayError(true);
          message = `*${intl.formatMessage(
            { id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE_NOT_VALID },
            { field: intl.formatMessage({ id: CREATE_USER_FORM__INPUT__EMAIL }).toLowerCase() },
          )}`;
        }

        if (error.type === ERROR_TYPE_OPTIONALITY || error.type === ERROR_TYPE_REQUIRED) {
          message = `*${intl.formatMessage(
            {
              id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
            },
            { field: intl.formatMessage({ id: CREATE_USER_ERROR_FIELD[error.path] }).toLowerCase() },
          )}`;
        }

        if (!!message) {
          setErrorMessage(message);
          setDisplayError(true);
        }
      });
  };

  const getUsers = () => {
    return new Promise((resolve) => {
      getUsersService({ ...paginationParameters, ...orderParameters }, (result) => {
        resolve({ users: result.data, totalResults: result.headers["pagination-total-results"] });
      });
    });
  }

  const handleOrderParameters = (order) => {
    setOrderParemeters(order);
  };

  useEffect(() => {
    getUsers().then((result) => {
      setUsers(result.users);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderParameters]);

  useEffect(() => {
    setPaginationParameters({ limit: ADMIN_TABLE_ROWS_USERS, offset: ADMIN_TABLE_ROWS_USERS * (page - 1) });
    if (!!key) {
      setKey(key + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleGetUsers = () => {
    getUsers().then((result) => {
      setUsers(result.users);
      setTotalRows(parseInt(result.totalResults));
    });
  };

  useEffect(() => {
    if (!!key) {
      handleGetUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    handleGetUsers();
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (value) => {
    const newPage = value ? page + 1 : page - 1;
    setPage(newPage);
  };

  const cellarsCallback = (response) => {
    setSelectedCellars(response.data);

    setUserCellars(response.data);
    setCreateUser(false);
    setDisplayCreateModal(!displayCreateModal);
    setDisplayError(false);
    setErrorMessage("");
  };

  const plotsCallback = (response, id) => {
    setSelectedParcels(response.data);

    setUserParcels(response.data);
    getUserCellarsService(id, cellarsCallback);
  };

  const handleEditUser = (row) => {
    setUsername(row.username);
    setEmail(row.email);
    setRole(row.role);
    setUser(row);

    getUserPlotsService(row.id, (r) => plotsCallback(r, row.id));
  };

  const deleteUserCallback = () => {
    handleGetUsers();
  };

  const handleDeleteUser = (row) => {
    deleteUserService(row.id, deleteUserCallback);
  };

  const columns = [
    {
      title: formatMessage(COMMON__INPUT__USER_NAME),
      width: "minmax(100px,1fr)",
      field: "username",
      fieldOrder: "username_order",
    },
    {
      title: formatMessage(COMMON__INPUT__EMAIL),
      width: "minmax(150px,1.5fr)",
      field: "email",
      fieldOrder: "email_order",
    },
    {
      title: formatMessage(COMMON__INPUT__ROL),
      width: "minmax(100px,0.75fr)",
      field: "role",
      fieldOrder: "role_order",
    },
  ];

  return (
    <AdministrationPageComponent
      title={USERS_MANAGEMENT__TITLE}
      columns={columns}
      rows={users}
      rowsToShow={ADMIN_TABLE_ROWS_USERS}
      totalRows={totalRows}
      buttonText={CREATE_USER_FORM__TITLE}
      modalComponent={
        <CreateUserFormComponent
          username={username}
          handleSetUsername={handleSetUsername}
          email={email}
          handleSetEmail={handleSetEmail}
          role={role}
          handleSetRole={handleSetRole}
          password1={password1}
          handleSetPassword1={handleSetPassword1}
          password2={password2}
          handleSetPassword2={handleSetPassword2}
          selectedParcels={selectedParcels}
          handleSetSelectedParcels={handleSetSelectedParcels}
          selectedCellars={selectedCellars}
          handleSetSelectedCellars={handleSetSelectedCellars}
          errorMessage={errorMessage}
          displayError={displayError}
          handleCreateUser={createUser ? handleCreateUser : handleUpdateUser}
          createUser={createUser}
        />
      }
      displayCreateModal={displayCreateModal}
      handleOpenCreateModal={handleOpenCreateModal}
      handleEdit={handleEditUser}
      handleDelete={handleDeleteUser}
      createTitle={CREATE_USER_FORM__TITLE}
      editTitle={EDIT_USER_FORM__TITLE}
      deleteTitle={COMMON__USER}
      handleChangePage={handleChangePage}
      handleOrderParameters={handleOrderParameters}
    />
  );
};

export default withAuthorization(ROLES_GENERAL_ADMIN)(ManageUsersComponent);
