import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import style from "./ParcelLegend.module.css";
import LineComponent from "../../../../../../common/atoms/line/LineComponent";
import {
  CHART_PESTS__COLUMN__BORDER_COLOR__FOLIAR_WETTING,
  CHART_PESTS__COLUMN__BORDER_COLOR__RELATIVE_HUMIDITY,
  CHART_PESTS__COLUMN__BORDER_COLOR__INSOLATION,
  CHART_PESTS__COLUMN__BORDER_COLOR__MAX_TEMPERATURE,
  CHART_PESTS__COLUMN__BORDER_COLOR__MIN_TEMPERATURE,
  CHART_PESTS__COLUMN__BORDER_COLOR__PRECIPITATION,
  CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__AVG_TEMPERATURE,
} from "../chartPestsComponent/chartPestsConstants";
import {
  METEO__STATE,
  COLUMNS__COLOR,
  LINES__COLOR,
  COMMON__RISK,
  TABLE_OBSERVATION__LABEL__OBSERVATION,
  COMMON__PROTECTION,
  METEO__ICONS,
  LEGEND__PRECIPITATION,
  LEGEND__FOLIAR__WETTING,
  LEGEND__MIN__TEMP,
  LEGEND__MAX__TEMP,
  LEGEND__RELATIVE__HUMIDITY,
  LEGEND__INSOLATION,
  LEGEND__AVG__TEMP,
  TOOLTIP_OBSERVATION,
  OBSERVATION_TRUE,
  OBSERVATION_FALSE,
} from "../../../../../../../translations/constants";
import { DATA_WEATHER } from "../../../../../../../utils/constants";
import { getCircleIcon } from "../../../../../../../images/icons/icons";
import { FormattedMessage } from "react-intl";
import { RISK_PROTECTION_TABLE } from "../../../../../Constants";
import clsx from "clsx";

const ParcelLegendComponent = () => {
  return (
    <div className={style.container}>
      <section className={style.firstCol}>
        <section className={style.section}>
          <TypographyComponent text={<FormattedMessage id={METEO__STATE} />} variant={TEXT.VARIANT_H3} />
          <div className={style.firstSectionContainer}>
            <div>
              <TypographyComponent text={<FormattedMessage id={COLUMNS__COLOR} />} variant={TEXT.VARIANT_H4} />
              <ul>
                <li className={style.listElement}>
                  <LineComponent color={CHART_PESTS__COLUMN__BORDER_COLOR__PRECIPITATION} />
                  <TypographyComponent
                    text={<FormattedMessage id={LEGEND__PRECIPITATION} />}
                    variant={TEXT.VARIANT_P}
                  />
                </li>
                <li className={style.listElement}>
                  <LineComponent color={CHART_PESTS__COLUMN__BORDER_COLOR__FOLIAR_WETTING} />
                  <TypographyComponent
                    text={<FormattedMessage id={LEGEND__FOLIAR__WETTING} />}
                    variant={TEXT.VARIANT_P}
                  />
                </li>
              </ul>
            </div>
            <div>
              <TypographyComponent text={<FormattedMessage id={LINES__COLOR} />} variant={TEXT.VARIANT_H4} />
              <ul>
                <li className={style.listElement}>
                  <LineComponent color={CHART_PESTS__COLUMN__BORDER_COLOR__INSOLATION} />
                  <TypographyComponent text={<FormattedMessage id={LEGEND__INSOLATION} />} variant={TEXT.VARIANT_P} />
                </li>
                <li className={style.listElement}>
                  <LineComponent color={CHART_PESTS__COLUMN__BORDER_COLOR__MAX_TEMPERATURE} />
                  <TypographyComponent text={<FormattedMessage id={LEGEND__MAX__TEMP} />} variant={TEXT.VARIANT_P} />
                </li>
                <li className={style.listElement}>
                  <LineComponent color={CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__AVG_TEMPERATURE} />
                  <TypographyComponent text={<FormattedMessage id={LEGEND__AVG__TEMP} />} variant={TEXT.VARIANT_P} />
                </li>
                <li className={style.listElement}>
                  <LineComponent color={CHART_PESTS__COLUMN__BORDER_COLOR__MIN_TEMPERATURE} />
                  <TypographyComponent text={<FormattedMessage id={LEGEND__MIN__TEMP} />} variant={TEXT.VARIANT_P} />
                </li>
                <li className={style.listElement}>
                  <LineComponent color={CHART_PESTS__COLUMN__BORDER_COLOR__RELATIVE_HUMIDITY} />
                  <TypographyComponent
                    text={<FormattedMessage id={LEGEND__RELATIVE__HUMIDITY} />}
                    variant={TEXT.VARIANT_P}
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className={style.section}>
          <table className={style.riskTable}>
            <thead>
              <tr>
                <th className={style.emptyCell}></th>
                <th className={style.tableTitle} colSpan="3">
                  <TypographyComponent text={<FormattedMessage id={COMMON__PROTECTION} />} variant={TEXT.VARIANT_H4} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.tableBodyTitle}>
                  <TypographyComponent text={<FormattedMessage id={COMMON__RISK} />} variant={TEXT.VARIANT_H4} />
                </td>
                {RISK_PROTECTION_TABLE.protectionLevels.map((protectionLevel, index) => (
                  <td key={index}>
                    <TypographyComponent
                      text={<FormattedMessage id={protectionLevel.value} />}
                      variant={TEXT.VARIANT_P}
                    />
                  </td>
                ))}
              </tr>
              {RISK_PROTECTION_TABLE.riskLevels.map((riskLevel, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <TypographyComponent text={<FormattedMessage id={riskLevel.value} />} variant={TEXT.VARIANT_P} />
                  </td>
                  {RISK_PROTECTION_TABLE.protectionLevels.map((protectionLevel, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={clsx(style.iconContainer, style[`plagueDataCell-${protectionLevel.name}`])}
                    >
                      <div
                        className={clsx(style.iconContainerRiskLevelsLegend, style[`tableRiskIcon-${riskLevel.name}`])}
                      >
                        {getCircleIcon(`${riskLevel.name}`, protectionLevel.size)}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className={style.section}>
          <TypographyComponent
            text={<FormattedMessage id={TABLE_OBSERVATION__LABEL__OBSERVATION} />}
            variant={TEXT.VARIANT_H3}
          />
          <table className={style.riskTable}>
            <thead>
              <tr>
                <th className={style.tableTitle} colSpan="2">
                  <TypographyComponent text={<FormattedMessage id={TOOLTIP_OBSERVATION} />} variant={TEXT.VARIANT_H4} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style["plagueDataCell-bordered"]}>
                  <TypographyComponent text={<FormattedMessage id={OBSERVATION_TRUE} />} variant={TEXT.VARIANT_P} />
                </td>
                <td className={style["plagueDataCell-bordered"]}>
                  <TypographyComponent text={<FormattedMessage id={OBSERVATION_FALSE} />} variant={TEXT.VARIANT_P} />
                </td>
              </tr>
              <tr>
                <td
                  className={clsx(
                    style.iconContainer,
                    style["plagueDataCell-bordered"],
                    style["plagueDataCell-observed"],
                    style["td-observationTable"],
                  )}
                >
                  <div className={clsx(style.iconContainerRiskLevelsLegend)}>
                  </div>
                </td>
                <td className={clsx(style.iconContainer, style["plagueDataCell-bordered"], style["plagueDataCell-NONE"], style["td-observationTable"])}>
                  <div className={clsx(style.iconContainerRiskLevelsLegend)}>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </section>

      <section className={`${style.section} ${style.meteoSection}`}>
        <TypographyComponent text={<FormattedMessage id={METEO__ICONS} />} variant={TEXT.VARIANT_H3} />
        <ul className={style.meteoListContainer}>
          {Object.entries(DATA_WEATHER)
            .slice(1)
            .map(([key, value]) => (
              <li className={style.meteoListItem} key={key}>
                <img src={value.icon} alt={value.alt} />
                <TypographyComponent text={<FormattedMessage id={value.alt} />} variant={TEXT.VARIANT_P} />
              </li>
            ))}
        </ul>
      </section>
    </div>
  );
};

export default ParcelLegendComponent;
