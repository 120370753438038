import React from "react";
import styles from "./textField.module.css";

const TextFieldComponent = ({ register, label, sublabel, id, name, defaultValue, type, required, errorMessage }) => {
  return (
    <div>
      <label htmlFor={id}>{label}</label>
      {sublabel && <div className={styles.sublabel}>{sublabel}</div>}
      <input id={id} name={name} defaultValue={defaultValue} type={type} {...register(name, { required })} />
      {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
    </div>
  );
};

TextFieldComponent.defaultProps = {
  type: "text",
};

export default TextFieldComponent;
