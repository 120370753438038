import React from "react";
import { Chart } from "react-chartjs-2";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useIntl } from "react-intl";

import {
  CHART_PESTS__COLUMN__AXIS_ID__LEFT,
  CHART_PESTS__COLUMN__AXIS_ID__RIGHT,
  CHART_PESTS__COLUMN__BACKGROUND_COLOR__AVG_TEMPERATURE,
  CHART_PESTS__COLUMN__BACKGROUND_COLOR__FOLIAR_WETTING,
  CHART_PESTS__COLUMN__BACKGROUND_COLOR__INSOLATION,
  CHART_PESTS__COLUMN__BACKGROUND_COLOR__MAX_TEMPERATURE,
  CHART_PESTS__COLUMN__BACKGROUND_COLOR__MIN_TEMPERATURE,
  CHART_PESTS__COLUMN__BACKGROUND_COLOR__PRECIPITATION,
  CHART_PESTS__COLUMN__BACKGROUND_COLOR__RELATIVE_HUMIDITY,
  CHART_PESTS__COLUMN__BORDER_COLOR__AVG_TEMPERATURE,
  CHART_PESTS__COLUMN__BORDER_COLOR__FOLIAR_WETTING,
  CHART_PESTS__COLUMN__BORDER_COLOR__INSOLATION,
  CHART_PESTS__COLUMN__BORDER_COLOR__MAX_TEMPERATURE,
  CHART_PESTS__COLUMN__BORDER_COLOR__MIN_TEMPERATURE,
  CHART_PESTS__COLUMN__BORDER_COLOR__PRECIPITATION,
  CHART_PESTS__COLUMN__BORDER_COLOR__RELATIVE_HUMIDITY,
  // CHART_PESTS__COLUMN__FILL_COLOR_TEMPERATURE,
  CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__AVG_TEMPERATURE,
  CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__INSOLATION,
  CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__MAX_TEMPERATURE,
  CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__MIN_TEMPERATURE,
  CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__RELATIVE_HUMIDITY,
  CHART_PESTS__COLUMN_TYPE__BAR,
  CHART_PESTS__COLUMN_TYPE__LINE,
} from "./chartPestsConstants";
import {
  CHART_PESTS__COLUM__LABEL__AVG_TEMPERATURE,
  CHART_PESTS__COLUM__LABEL__FOLIAR_WETTING,
  CHART_PESTS__COLUM__LABEL__INSOLATION,
  CHART_PESTS__COLUM__LABEL__MAX_TEMPERATURE,
  CHART_PESTS__COLUM__LABEL__MIN_TEMPERATURE,
  CHART_PESTS__COLUM__LABEL__PRECIPITATION,
  CHART_PESTS__COLUM__LABEL__RELATIVE_HUMIDITY,
} from "../../../../../../../translations/constants";
import style from "./chartPests.module.css";

function ChartPestsComponent({ weatherData }) {
  const intl = useIntl();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    BarController,
    PointElement,
    LineElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    Filler,
  );

  const HOVER_BORDER_WIDTH = 2;

  const data = weatherData
    ? {
        labels: weatherData.map((item) => moment.unix(item.date).format("DD/MM/YY")),
        datasets: [
          //humedad relativa
          {
            type: CHART_PESTS__COLUMN_TYPE__LINE,
            label: intl.formatMessage({ id: CHART_PESTS__COLUM__LABEL__RELATIVE_HUMIDITY }),
            data: weatherData.map((item) => item.rel_humidity),
            backgroundColor: CHART_PESTS__COLUMN__BACKGROUND_COLOR__RELATIVE_HUMIDITY,
            borderColor: CHART_PESTS__COLUMN__BORDER_COLOR__RELATIVE_HUMIDITY,
            yAxisID: CHART_PESTS__COLUMN__AXIS_ID__RIGHT,
            tension: 0.3,
            hoverBorderColor: CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__RELATIVE_HUMIDITY,
            hoverBorderWidth: HOVER_BORDER_WIDTH,
            fill: false,
          },
          //insolacion
          {
            type: CHART_PESTS__COLUMN_TYPE__LINE,
            label: intl.formatMessage({ id: CHART_PESTS__COLUM__LABEL__INSOLATION }),
            data: weatherData.map((item) => item.insolation),
            backgroundColor: CHART_PESTS__COLUMN__BACKGROUND_COLOR__INSOLATION,
            borderColor: CHART_PESTS__COLUMN__BORDER_COLOR__INSOLATION,
            yAxisID: CHART_PESTS__COLUMN__AXIS_ID__RIGHT,
            tension: 0.3,
            hoverBorderColor: CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__INSOLATION,
            hoverBorderWidth: HOVER_BORDER_WIDTH,
            fill: false,
          },
          //temp max
          {
            type: CHART_PESTS__COLUMN_TYPE__LINE,
            label: intl.formatMessage({ id: CHART_PESTS__COLUM__LABEL__MAX_TEMPERATURE }),
            data: weatherData.map((item) => item.temp_max),
            pointBackgroundColor: CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__MAX_TEMPERATURE,
            backgroundColor: CHART_PESTS__COLUMN__BACKGROUND_COLOR__MAX_TEMPERATURE,
            borderColor: CHART_PESTS__COLUMN__BORDER_COLOR__MAX_TEMPERATURE,
            borderWidth: 0.5,
            yAxisID: CHART_PESTS__COLUMN__AXIS_ID__LEFT,
            tension: 0.3,
            hoverBorderColor: CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__MAX_TEMPERATURE,
            hoverBorderWidth: HOVER_BORDER_WIDTH,
            fill: "+2",
          },
          //temp med
          {
            type: CHART_PESTS__COLUMN_TYPE__LINE,
            label: intl.formatMessage({ id: CHART_PESTS__COLUM__LABEL__AVG_TEMPERATURE }),
            data: weatherData.map((item) => item.temp_avg),
            backgroundColor: CHART_PESTS__COLUMN__BACKGROUND_COLOR__AVG_TEMPERATURE,
            borderColor: CHART_PESTS__COLUMN__BORDER_COLOR__AVG_TEMPERATURE,
            yAxisID: CHART_PESTS__COLUMN__AXIS_ID__LEFT,
            tension: 0.3,
            hoverBorderColor: CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__AVG_TEMPERATURE,
            hoverBorderWidth: HOVER_BORDER_WIDTH,
            fill: false,
          },
          // temp min
          {
            type: CHART_PESTS__COLUMN_TYPE__LINE,
            label: intl.formatMessage({ id: CHART_PESTS__COLUM__LABEL__MIN_TEMPERATURE }),
            data: weatherData.map((item) => item.temp_min),
            backgroundColor: CHART_PESTS__COLUMN__BACKGROUND_COLOR__MIN_TEMPERATURE,
            borderColor: CHART_PESTS__COLUMN__BORDER_COLOR__MIN_TEMPERATURE,
            borderWidth: 0.5,
            yAxisID: CHART_PESTS__COLUMN__AXIS_ID__LEFT,
            tension: 0.3,
            hoverBorderColor: CHART_PESTS__COLUMN__HOVER_BORDER_COLOR__MIN_TEMPERATURE,
            hoverBorderWidth: HOVER_BORDER_WIDTH,
            fill: false,
          },
          //precipitaciones
          {
            type: CHART_PESTS__COLUMN_TYPE__BAR,
            label: intl.formatMessage({ id: CHART_PESTS__COLUM__LABEL__PRECIPITATION }),
            data: weatherData.map((item) => item.precipitation),
            backgroundColor: CHART_PESTS__COLUMN__BACKGROUND_COLOR__PRECIPITATION,
            borderColor: CHART_PESTS__COLUMN__BORDER_COLOR__PRECIPITATION,
            barThickness: 10,
            yAxisID: CHART_PESTS__COLUMN__AXIS_ID__LEFT,
            borderRadius: 100,
            fill: false,
          },
          //humectacion foliar
          {
            type: CHART_PESTS__COLUMN_TYPE__BAR,
            label: intl.formatMessage({ id: CHART_PESTS__COLUM__LABEL__FOLIAR_WETTING }),
            data: weatherData.map((item) => item.fol_humidity),
            backgroundColor: CHART_PESTS__COLUMN__BACKGROUND_COLOR__FOLIAR_WETTING,
            borderColor: CHART_PESTS__COLUMN__BORDER_COLOR__FOLIAR_WETTING,
            yAxisID: CHART_PESTS__COLUMN__AXIS_ID__LEFT,
            borderRadius: 100,
            barThickness: 10,
            fill: false,
          },
        ],
      }
    : { labels: [], datasets: [] };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: true,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      [CHART_PESTS__COLUMN__AXIS_ID__LEFT]: {
        beginAtZero: true,
        max: 50,
        ticks: {
          stepSize: 5,
        },
      },
      [CHART_PESTS__COLUMN__AXIS_ID__RIGHT]: {
        position: "right",
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 5,
        },
      },
    },
    hover: {
      mode: "dataset",
      intersect: true,
    },
  };

  return (
    <div>
      <div className={style.chartPests}>
        <Chart options={options} type={CHART_PESTS__COLUMN_TYPE__BAR} data={data} />
      </div>
    </div>
  );
}

export default ChartPestsComponent;
