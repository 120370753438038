import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "../../CreateParcelForm.module.css";
import TypographyComponent from "../../../../../../common/atoms/typography/TypographyComponent";
import { TEXT } from "../../../../../../common/atoms/typography/typographyConstants";
import SelectComponent from "../../../../../../common/atoms/select/SelectComponent";
import InputComponent from "../../../../../../common/atoms/input/InputComponent";
import { INPUT, INPUT_VARIANT_SECONDARY } from "../../../../../../common/atoms/input/inputConstants";
import {
  CREATE_PARCEL_FORM__INPUT__CONDUCTION,
  CREATE_PARCEL_FORM__INPUT__DENSITY,
  CREATE_PARCEL_FORM__INPUT__IRRIGATION,
  CREATE_PARCEL_FORM__INPUT__MAINTENANCE,
  CREATE_PARCEL_FORM__INPUT__PLANTATION_YEAR,
  CREATE_PARCEL_FORM__INPUT__VARIETY,
  CREATE_PARCEL_FORM__SECTION_PLANTATION,
} from "../../../../../../../translations/constants";
import { getVarietiesGrapesService } from "../../../../../../../services/commonservices";
import useCustomIntl from "../../../../../../../hook/cutomHook";
import { CONDUCTION_TYPES, IRRIGATION_TYPES, MAINTENANCE_TYPES } from "../../../../manageParcelsConstants";

const PlantationFormComponent = ({
  variety,
  handleSetVariety,
  conduction,
  handleSetConduction,
  maintenance,
  handleSetMaintenance,
  density,
  handleSetDensity,
  year,
  handleSetYear,
  irrigation,
  handleSetIrrigation,
  createParcel,
}) => {
  const { formatMessage } = useCustomIntl();

  const [varietyInput, setVarietyInput] = useState({
    id: 4,
    data: [],
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__VARIETY),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetVariety,
    initialOption: true,
    defaultValue: variety,
    defaultText: variety,
    required: true,
  });

  const varietiesCallback = (response) => {
    setVarietyInput({ ...varietyInput, data: response.data });
  };

  const getVarieties = () => {
    getVarietiesGrapesService(varietiesCallback);
  };

  useEffect(() => {
    getVarieties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    variety && setVarietyInput({ ...varietyInput, defaultValue: variety });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variety]);

  const conductionInput = {
    id: 5,
    data: CONDUCTION_TYPES,
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__CONDUCTION),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetConduction,
    initialOption: true,
    defaultValue: conduction,
    defaultText: conduction,
    disabled: !createParcel,
  };

  const maintenanceInput = {
    id: 6,
    data: MAINTENANCE_TYPES,
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__MAINTENANCE),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetMaintenance,
    initialOption: true,
    defaultValue: maintenance,
    defaultText: maintenance,
    disabled: !createParcel,
  };

  const densityInput = {
    id: 7,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__DENSITY),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__DENSITY),
    type: INPUT.TYPE_NUMBER,
    value: density,
    onChange: handleSetDensity,
    disabled: !createParcel,
  };

  const yearInput = {
    id: 8,
    name: formatMessage(CREATE_PARCEL_FORM__INPUT__PLANTATION_YEAR),
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__PLANTATION_YEAR),
    type: INPUT.TYPE_NUMBER,
    value: year,
    onChange: handleSetYear,
    disabled: !createParcel,
  };

  const irrigationInput = {
    id: 9,
    data: IRRIGATION_TYPES,
    label: formatMessage(CREATE_PARCEL_FORM__INPUT__IRRIGATION),
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetIrrigation,
    initialOption: true,
    defaultValue: irrigation,
    defaultText: irrigation,
    disabled: !createParcel,
  };

  return (
    <div className={styles.infoBlock}>
      <TypographyComponent text={formatMessage(CREATE_PARCEL_FORM__SECTION_PLANTATION)} variant={TEXT.VARIANT_H3} />
      <div className={styles.plantationInputs}>
        <SelectComponent
          data={varietyInput.data}
          label={varietyInput.label}
          fieldLabel={varietyInput.fieldLabel}
          fieldLValue={varietyInput.fieldLValue}
          variant={varietyInput.variant}
          handleOnchange={varietyInput.onChange}
          initialOption={varietyInput.initialOption}
          defaultValue={varietyInput.defaultValue}
          defaultText={varietyInput.defaultText}
          required={varietyInput.required}
          verticalPlacementLabel
        />
        <SelectComponent
          data={conductionInput.data}
          label={conductionInput.label}
          fieldLabel={conductionInput.fieldLabel}
          fieldLValue={conductionInput.fieldLValue}
          variant={conductionInput.variant}
          handleOnchange={conductionInput.onChange}
          initialOption={conductionInput.initialOption}
          defaultValue={conductionInput.defaultValue}
          defaultText={conductionInput.defaultText}
          verticalPlacementLabel
          disabled={conductionInput.disabled}
        />
        <SelectComponent
          data={maintenanceInput.data}
          label={maintenanceInput.label}
          fieldLabel={maintenanceInput.fieldLabel}
          fieldLValue={maintenanceInput.fieldLValue}
          variant={maintenanceInput.variant}
          handleOnchange={maintenanceInput.onChange}
          initialOption={maintenanceInput.initialOption}
          defaultValue={maintenanceInput.defaultValue}
          defaultText={maintenanceInput.defaultText}
          verticalPlacementLabel
          disabled={maintenanceInput.disabled}
        />
        <InputComponent data={densityInput} onChange={densityInput.onChange} value={densityInput.value} />
        <InputComponent data={yearInput} onChange={yearInput.onChange} value={yearInput.value} />
        <SelectComponent
          data={irrigationInput.data}
          label={irrigationInput.label}
          fieldLabel={irrigationInput.fieldLabel}
          fieldLValue={irrigationInput.fieldLValue}
          variant={irrigationInput.variant}
          handleOnchange={irrigationInput.onChange}
          initialOption={irrigationInput.initialOption}
          defaultValue={irrigationInput.defaultValue}
          defaultText={irrigationInput.defaultText}
          verticalPlacementLabel
          disabled={irrigationInput.disabled}
        />
      </div>
    </div>
  );
};

PlantationFormComponent.propTypes = {
  variety: PropTypes.string.isRequired,
  handleSetVariety: PropTypes.func.isRequired,
  conduction: PropTypes.string.isRequired,
  handleSetConduction: PropTypes.func.isRequired,
  maintenance: PropTypes.string.isRequired,
  handleSetMaintenance: PropTypes.func.isRequired,
  density: PropTypes.number.isRequired,
  handleSetDensity: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  handleSetYear: PropTypes.func.isRequired,
  irrigation: PropTypes.string.isRequired,
  handleSetIrrigation: PropTypes.func.isRequired,
};

export default PlantationFormComponent;
