import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";

import {
  APP_BASE_ROUTE,
  ROUTE_LANDING,
  ROUTE_PARCEL_DETAIL,
  ROUTE_PARCEL_SIMPLIFIED_INFORMATION,
  ROUTE_PARCELS,
  ROUTE_PHYTOSANITARY_PRODUCTS,
  ROUTE_WARNINGS,
  ROUTE_MANAGE_CELLARS,
  ROUTE_MANAGE_PARCELS,
  ROUTE_MANAGE_USERS,
  ROUTE_OTHER,
  ROUTE_MANAGE_WARNINGS,
  ROUTE_REGISTER,
} from "../../utils/routes";
import ErrorComponent from "../common/ErrorComponent";
import TopMenuComponent from "../common/molecules/topMenu/TopMenuComponent";
import FooterComponent from "../landing/FooterComponent";
import ParcelsComponent from "../pages/parcels/ParcelsComponent";
import ParcelDetailComponent from "../parcels/parcelDetail/ParcelDetailComponent";
import SimplifiedParcelComponent from "../parcels/simplifiedParcel/SimplifiedParcelComponent";
import PhytosanitaryProductsComponent from "../phytosanitaryProducts/PhytosanitaryProductsComponent";
import { COMMON__ERROR_404 } from "../../translations/constants";
import WarningsComponent from "../warnings/WarningsComponent";
import ManageCellarsComponent from "../administration/manageCellars/ManageCellarsComponent";
import ManageParcelsComponent from "../administration/manageParcels/ManageParcelsComponent";
import ManageUsersComponent from "../administration/manageUsers/ManageUsersComponent";
import ManageWarningsComponent from "../administration/manageWarnings/ManageWarningsComponent";
import styles from "./Routers.module.css";
import RegisterComponent from "../pages/register/RegisterComponent";
import { PUBLIC_WEB_AVAILABLE } from "../../utils/constants";

const Routers = ({ handleLanguageChanged }) => {
  return (
    <BrowserRouter basename={APP_BASE_ROUTE}>
      <TopMenuComponent handleLanguageChanged={handleLanguageChanged} />
      <div className={styles.content}>
        <Routes>
          <Route exact path={ROUTE_LANDING} element={<ParcelsComponent />} />
          <Route exact path={ROUTE_PARCELS} element={<ParcelsComponent />} />
          {PUBLIC_WEB_AVAILABLE && <Route exact path={ROUTE_REGISTER} element={<RegisterComponent />} />}
          <Route exact path={ROUTE_PARCEL_DETAIL} element={<ParcelDetailComponent />} />
          <Route exact path={ROUTE_PARCEL_SIMPLIFIED_INFORMATION} element={<SimplifiedParcelComponent />} />
          <Route exact path={ROUTE_PHYTOSANITARY_PRODUCTS} element={<PhytosanitaryProductsComponent />} />
          <Route exact path={ROUTE_WARNINGS} element={<WarningsComponent />} />
          <Route exact path={ROUTE_MANAGE_CELLARS} element={<ManageCellarsComponent />} />
          <Route exact path={ROUTE_MANAGE_PARCELS} element={<ManageParcelsComponent />} />
          <Route exact path={ROUTE_MANAGE_USERS} element={<ManageUsersComponent />} />
          <Route exact path={ROUTE_MANAGE_WARNINGS} element={<ManageWarningsComponent />} />
          <Route exact path={ROUTE_OTHER} element={<ErrorComponent error={COMMON__ERROR_404} />} />
        </Routes>
      </div>
      <FooterComponent />
    </BrowserRouter>
  );
};

Routers.propTypes = {
  handleLanguageChanged: PropTypes.func.isRequired,
};

export default Routers;
