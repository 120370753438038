import React from "react";
import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles((theme) => ({
  roundButton: {
    backgroundColor: "blue",
    border: "none",
    borderRadius: "50%",
    color: "white",
    cursor: "pointer",
    height: "50px",
    width: "50px",
    "& i": {
      fontSize: "1rem",
    },
  },
}));

const RoundButtonIconComponent = ({ icon, handleOnClick }) => {
  const classes = useStyles();
  return (
    <button className={classes.roundButton} onClick={handleOnClick}>
      <Icon baseClassName="fas" className="fa-plus-circle" />
    </button>
  );
};

export default RoundButtonIconComponent;
