import React from "react";
import { FormattedMessage } from "react-intl";

import AlertDialog from "./AlertDialog";
import { COMMON__RETURN, ERROR_BOUNDARY__DESCRIPTION, ERROR_BOUNDARY__TITLE } from "../../translations/constants";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, open: null };
    this.error = null;
  }

  static getDerivedStateFromError(error) {
    return { error: error.toString(), open: true };
  }

  onClose = () => {
    this.setState({ open: false });
    window.history.back();
    window.location.reload();
  };

  render() {
    if (this.state.error) {
      return (
        <div>
          <AlertDialog
            open={this.state.open}
            title={<FormattedMessage id={ERROR_BOUNDARY__TITLE} />}
            description={<FormattedMessage id={ERROR_BOUNDARY__DESCRIPTION} />}
            onClose={this.onClose}
            buttonCloseText={<FormattedMessage id={COMMON__RETURN} />}
          />
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
