import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CreateWarningForm.module.css";
import {
  COMMON__BUTTONS__SAVE,
  CREATE_WARNING_FORM_INPUT__LEVEL,
  CREATE_WARNING_FORM_INPUT__DESCRIPTION,
  CREATE_WARNING_FORM_INPUT__NAME,
  CREATE_WARNING_FORM_INPUT__PLOT_ID,
  WARNINGS__RISK_LEVEL_NONE,
  WARNINGS__RISK_LEVEL_LOW,
  WARNINGS__RISK_LEVEL_MEDIUM,
  WARNINGS__RISK_LEVEL_HIGH,
} from "../../../../translations/constants";
import { I18N_CONFIG } from "../../../../utils/constants";
import useCustomIntl from "../../../../hook/cutomHook";
import SelectComponent from "../../../common/atoms/select/SelectComponent";
import { INPUT, INPUT_VARIANT_SECONDARY } from "../../../common/atoms/input/inputConstants";
import ButtonComponent from "../../../common/atoms/button/ButtonComponent";
import TypographyComponent from "../../../common/atoms/typography/TypographyComponent";
import { BUTTON } from "../../../common/atoms/button/buttonConstants";
import InputComponent from "../../../common/atoms/input/InputComponent";
import TextAreaComponent from "../../../common/atoms/textArea/TextAreaComponent";
import {
  SELECT_FIELD__PLOT_NAME,
  SELECT_FIELD__PLOT_ID,
  SELECT_FIELD_WARNING_LEVELS_VALUE,
  SELECT_FIELD_WARNING_LEVELS_LABEL,
} from "../manageWarningsConstants";
import { HIGH, LOW, MEDIUM, NONE } from "../../../pages/parcels/components/maps/mapConstants";
import { useIntl } from "react-intl";

const CreateWarningFormComponent = ({
  plots,
  plot,
  handleSetPlot,
  name,
  handleSetName,
  description,
  handleSetDescription,
  level,
  handleSetLevel,
  handleSetTranslations,
  displayError,
  errorMessage,
  createWarning,
  handleCreateWarning,
}) => {
  const { formatMessage } = useCustomIntl();
  const intl = useIntl();
  const locale = localStorage.getItem('locale');
  const [translations] = useState([]);

  const handleSetNameOpt = (e) => {
    let translation = translations.find(translation => translation.language_code === e.target.id);
    if (translation) {
      translation.name = e.target.value;
    } else {
        let newTranslation = {
            language_code: e.target.id,
            name: e.target.value,
        };
        translations.push(newTranslation);
    }
  }

  const handleSetDescriptionOpt = (e) => {
    let translation = translations.find(translation => translation.language_code === e.target.id);
    if (translation) {
      translation.description = e.target.value;
    } else {
        let newTranslation = {
            language_code: e.target.id,
            description: e.target.value,
        };
        translations.push(newTranslation);
    }
  }

  const plotInput = {
    id: 40,
    data: plots,
    label: formatMessage(CREATE_WARNING_FORM_INPUT__PLOT_ID),
    fieldLabel: SELECT_FIELD__PLOT_NAME,
    fieldLValue: SELECT_FIELD__PLOT_ID,
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetPlot,
    initialOption: true,
    defaultValue: plot || "",
    disabled: !createWarning,
  };

  const WARNING_LEVELS = [
    { value: 0, label: formatMessage(WARNINGS__RISK_LEVEL_NONE), color: NONE },
    { value: 1, label: formatMessage(WARNINGS__RISK_LEVEL_LOW), color: LOW },
    { value: 2, label: formatMessage(WARNINGS__RISK_LEVEL_MEDIUM), color: MEDIUM },
    { value: 3, label: formatMessage(WARNINGS__RISK_LEVEL_HIGH), color: HIGH },
  ];

  const levelInput = {
    id: 41,
    data: WARNING_LEVELS,
    label: formatMessage(CREATE_WARNING_FORM_INPUT__LEVEL),
    fieldLValue: SELECT_FIELD_WARNING_LEVELS_VALUE,
    fieldLabel: SELECT_FIELD_WARNING_LEVELS_LABEL,
    variant: INPUT_VARIANT_SECONDARY,
    onChange: handleSetLevel,
    initialOption: true,
    defaultValue: level,
    disabled: !createWarning,
  };

  const nameInput = {
    id: 42,
    name: formatMessage(CREATE_WARNING_FORM_INPUT__NAME),
    label: formatMessage(CREATE_WARNING_FORM_INPUT__NAME),
    type: INPUT.TYPE_TEXT,
    value: name,
    onChange: handleSetName,
    required: true,
  };

  const descriptionInput = {
    id: 43,
    name: formatMessage(CREATE_WARNING_FORM_INPUT__DESCRIPTION),
    label: formatMessage(CREATE_WARNING_FORM_INPUT__DESCRIPTION),
    type: INPUT.TYPE_TEXTAREA,
    value: description,
    onChange: handleSetDescription,
    required: true,
  };

  const getOptionalNameInput = (languageCode) => {
    let nameInputOpt = {
      id: languageCode,
      name: formatMessage(CREATE_WARNING_FORM_INPUT__NAME),
      label: formatMessage(CREATE_WARNING_FORM_INPUT__NAME),
      type: INPUT.TYPE_TEXT,
      value: name,
      onChange: handleSetNameOpt,
      required: false,
    };
    return nameInputOpt;
  }

  const getOptionalDescriptionInput = (languageCode) => {
    let descriptionInputOpt = {
      id: languageCode,
      name: formatMessage(CREATE_WARNING_FORM_INPUT__DESCRIPTION),
      label: formatMessage(CREATE_WARNING_FORM_INPUT__DESCRIPTION),
      type: INPUT.TYPE_TEXTAREA,
      value: description,
      onChange: handleSetDescriptionOpt,
      required: false,
    };
    return descriptionInputOpt;
  }

  const handleSaveWarning = () => {
    Object.entries(I18N_CONFIG).map(([lang]) => {
      if (lang !== locale) {
        let translation = translations.find(translation => translation.language_code === lang);
        if (!translation) {
          let newTranslation = {
              language_code: lang,
              name: name,
              description: description,
          };
          translations.push(newTranslation);
        }
      }
      return translations
    });
    handleCreateWarning(translations);
  }

  return (
    <div className={styles.formContainer}>
      <div className={styles.formBlock}>
        <div className={styles.selectRow}>
          <SelectComponent
            data={plotInput.data}
            label={plotInput.label}
            fieldLabel={plotInput.fieldLabel}
            fieldLValue={plotInput.fieldLValue}
            variant={plotInput.variant}
            handleOnchange={plotInput.onChange}
            initialOption={plotInput.initialOption}
            defaultValue={plotInput.defaultValue}
            disabled={plotInput.disabled}
            verticalPlacementLabel
          />

          <SelectComponent
            data={levelInput.data}
            label={levelInput.label}
            fieldLabel={levelInput.fieldLabel}
            fieldLValue={levelInput.fieldLValue}
            variant={levelInput.variant}
            handleOnchange={levelInput.onChange}
            initialOption={levelInput.initialOption}
            defaultValue={levelInput.defaultValue}
            disabled={levelInput.disabled}
            verticalPlacementLabel
          />
        </div>
        <div className={styles.inputFieldsContainer}>
          {Object.entries(I18N_CONFIG).map(([lang, text]) => (
            <div key={lang}>
              <TypographyComponent text={intl.formatMessage({ id: text })} />
              <InputComponent data={lang === locale ? nameInput : getOptionalNameInput(lang)} onChange={lang === locale ? nameInput.onChange : handleSetNameOpt} value={name}/>
              <TextAreaComponent
                data={lang === locale ? descriptionInput : getOptionalDescriptionInput(lang)}
                onChange={lang === locale ? descriptionInput.onChange : handleSetDescriptionOpt}
                value={description}
              />
            </div>            
          ))}     
        </div>
      </div>
      {displayError && <TypographyComponent className={styles.errorMessage} text={errorMessage} />}
      <div className={styles.buttonContainer}>
        <ButtonComponent
          text={formatMessage(COMMON__BUTTONS__SAVE)}
          variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
          onClick={handleSaveWarning}
        />
      </div>
    </div>
  );
};

CreateWarningFormComponent.propTypes = {
  plot: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSetPlot: PropTypes.func.isRequired,
  name: PropTypes.string,
  handleSetName: PropTypes.func.isRequired,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleSetLevel: PropTypes.func.isRequired,
  description: PropTypes.string,
  handleSetDescription: PropTypes.func.isRequired,
};

export default CreateWarningFormComponent;
