import React, { useEffect, useState } from "react";
import withAuthorization from "../../common/auth/withAuthorization";
import { ERROR_TYPE_OPTIONALITY, ERROR_TYPE_REQUIRED, HTTP_CODE_409, ROLES_GENERAL_ADMIN } from "../../../utils/constants";
import { useIntl } from "react-intl";
import {
  COMMON_DAY,
  COMMON_RISK_LEVEL,
  COMMON__ERROR_MESSAGE_CREATE_WARNING,
  COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
  COMMON__WARNING,
  CREATE_WARNING_FORM_INPUT__DESCRIPTION,
  CREATE_WARNING_FORM_INPUT__NAME,
  CREATE_WARNING_FORM__TITLE,
  EDIT_WARNING_FORM__TITLE,
  TITLE_WARNINGS_PARCEL,
  WARNINGS_MANAGEMENT__TITLE,
  WARNINGS__RISK_LEVEL_HIGH,
  WARNINGS__RISK_LEVEL_LOW,
  WARNINGS__RISK_LEVEL_MEDIUM,
  WARNINGS__RISK_LEVEL_NONE,
} from "../../../translations/constants";
import {
  WARNING_UPDATE_SCHEMA,
  WARNING_ERROR_FIELD,
  WARNING_SCHEMA,
  ADMIN_TABLE_ROWS_WARNINGS,
} from "./manageWarningsConstants";
import {
  deleteWarningService,
  getPlotsService,
  getWarningsService,
  patchWarningService,
  postCreateWarningService,
} from "../../../services/commonservices";
import AdministrationPageComponent from "../AdministrationPageComponent";
import CreateWarningFormComponent from "./components/CreateWarningFormComponent";
import useCustomIntl from "../../../hook/cutomHook";
import { timestampToDays } from "../../../utils/utils";
import PointerComponent from "../../common/atoms/pointer/PointerComponent";
import style from "./ManageWarnings.module.css";
import { HIGH, LOW, MEDIUM, NONE } from "../../pages/parcels/components/maps/mapConstants";

const ManageWarningsComponent = () => {
  const intl = useIntl();
  const locale = localStorage.getItem('locale');
  const { formatMessage } = useCustomIntl();

  const [tableData, setTableData] = useState([]);
  const [plots, setPlots] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [displayError, setDisplayError] = useState(false);

  const [page, setPage] = useState(1);
  const [paginationParameters, setPaginationParameters] = useState({
    limit: ADMIN_TABLE_ROWS_WARNINGS,
    offset: 0,
  });
  const [orderParameters, setOrderParemeters] = useState({});
  const [totalRows, setTotalRows] = useState(0);

  /* CREATE MODAL */
  const [createWarning, setCreateWarning] = useState(true);
  const [displayCreateModal, setDisplayCreateModal] = useState(false);

  /* WARNING DATA */
  const [warningId, setWarningId] = useState(null);
  const [plot, setPlot] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [level, setLevel] = useState("");

  const handleSetPlot = (e) => {
    setPlot(e.target.value);
  };
  const handleSetName = (e) => {
    setName(e.target.value);
  };
  const handleSetDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleSetLevel = (e) => {
    setLevel(e.target.value);
  };

  const cleanFormValues = () => {
    setPlot(undefined);
    setName(undefined);
    setDescription(undefined);
    setLevel(undefined);
  };

  const handleOpenCreateModal = () => {
    cleanFormValues();
    setCreateWarning(true);
    setDisplayCreateModal(!displayCreateModal);
    setDisplayError(false);
    setErrorMessage("");
  };

  const serviceWarningCallback = () => getTableData();

  const getWarnings = () => {
    return new Promise((resolve, reject) => {
      const warningsCallback = (response) => {
        return resolve({ warnings: response.data, totalResults: response.headers["pagination-total-results"] });
      };
      const errorCallback = (error) => {
        reject(error);
      };
      getWarningsService({ ...paginationParameters, ...orderParameters }, warningsCallback, errorCallback, { "Accept-Language": locale });
    });
  };
  const getPlots = () => {
    return new Promise((resolve, reject) => {
      const plotsCallback = (response) => {
        resolve(response.data);
      };
      const errorCallback = (error) => {
        reject(error);
      };
      getPlotsService({ summary: true }, plotsCallback, errorCallback);
    });
  };

  const handleOrderParameters = (order) => {
    setOrderParemeters(order);
  };

  useEffect(() => {
    getTableData();
    getWarnings().then((result) => {
      setTotalRows(parseInt(result.totalResults));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderParameters]);

  const WARNING_LEVELS = [
    { value: 0, label: formatMessage(WARNINGS__RISK_LEVEL_NONE), color: NONE },
    { value: 1, label: formatMessage(WARNINGS__RISK_LEVEL_LOW), color: LOW },
    { value: 2, label: formatMessage(WARNINGS__RISK_LEVEL_MEDIUM), color: MEDIUM },
    { value: 3, label: formatMessage(WARNINGS__RISK_LEVEL_HIGH), color: HIGH },
  ];

  const createWarningErrorCallback = (error) => {
    if (error.response.status === HTTP_CODE_409) {
      setErrorMessage(`*${intl.formatMessage({ id: COMMON__ERROR_MESSAGE_CREATE_WARNING })}`);
      setDisplayError(true);
    }
  };

  const handleCreateWarning = (translations) => {
    const body = {
      plot_id: plot,
      name,
      description,
      translation: translations,
      level,
    };
    WARNING_SCHEMA.validate(body)
      .then(() => {
        postCreateWarningService(body, serviceWarningCallback, createWarningErrorCallback);
        setDisplayError(false);
        setErrorMessage("");
        setDisplayCreateModal(false);
      })
      .catch((error) => {
        if (error.type === ERROR_TYPE_REQUIRED || error.type === ERROR_TYPE_OPTIONALITY) {
          setErrorMessage(
            `*${intl.formatMessage(
              {
                id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
              },
              { field: intl.formatMessage({ id: WARNING_ERROR_FIELD[error.path] }).toLowerCase() },
            )}`,
          );
          setDisplayError(true);
        }
      });
  };

  const updateWarningErrorCallback = (error) => {
    console.error(error);
  };

  const handleUpdateWarning = () => {
    const body = {
      name,
      description,
    };
    WARNING_UPDATE_SCHEMA.validate(body)
      .then(() => {
        patchWarningService(warningId, body, serviceWarningCallback, updateWarningErrorCallback);
        setDisplayError(false);
        setErrorMessage("");
        setDisplayCreateModal(false);
      })
      .catch((error) => {
        if (error.type === ERROR_TYPE_OPTIONALITY || error.type === ERROR_TYPE_REQUIRED) {
          setErrorMessage(
            `*${intl.formatMessage(
              {
                id: COMMON__VALIDATION_FIELD_ERROR_MESSAGE,
              },
              { field: intl.formatMessage({ id: WARNING_ERROR_FIELD[error.path] }).toLowerCase() },
            )}`,
          );
          setDisplayError(true);
        }
      });
  };

  const handleEditWarning = (row) => {
    setWarningId(row.id);
    setPlot(row.plot_id || undefined);
    setName(row.name);
    setDescription(row.description);
    setLevel(row.level || undefined);

    setCreateWarning(false);
    setDisplayCreateModal(!displayCreateModal);
    setDisplayError(false);
    setErrorMessage("");
  };

  const handleDeleteWarning = (row) => {
    deleteWarningService(row.id, serviceWarningCallback, {});
  };

  const columns = [
    {
      title: formatMessage(COMMON_DAY),
      width: "minmax(65px,0.5fr)",
      field: "formatted_date",
      fieldOrder: "date_order",
    },
    {
      title: formatMessage(TITLE_WARNINGS_PARCEL),
      width: "minmax(100px,0.5fr)",
      field: "plot_name",
      fieldOrder: "plot_name_order",
    },
    {
      title: formatMessage(CREATE_WARNING_FORM_INPUT__NAME),
      width: "minmax(150px,1fr)",
      field: "name",
      fieldOrder: "name_order",
    },
    {
      title: formatMessage(COMMON_RISK_LEVEL),
      width: "minmax(130px,0.5fr)",
      field: "formatted_level",
      fieldOrder: "level_order",
    },
    {
      title: formatMessage(CREATE_WARNING_FORM_INPUT__DESCRIPTION),
      width: "minmax(150px,1fr)",
      field: "description",
      fieldOrder: "description_order",
    },
  ];

  const getTableData = () =>
    Promise.all([getWarnings(), getPlots()]).then(([warningsData, plotsData]) => {
      const combinedData = warningsData.warnings.map((warning) => {
        const formattedDate = timestampToDays(warning.date);
        const formattedLevel = WARNING_LEVELS.find((warningLevel) => warningLevel.value === warning.level)?.label;
        const addIconToLevelField = () => {
          const warningColor = WARNING_LEVELS.find((warningLevel) => warningLevel.value === warning.level)?.color;
          return (
            <span className={style.container_with_pointer}>
              <PointerComponent size={10} status={warningColor} />
              <span>{formattedLevel}</span>
            </span>
          );
        };
        setTotalRows(parseInt(warningsData.totalResults));
        const selectedPlot = plotsData.find((plot) => plot.id === warning.plot_id);
        return {
          ...warning,
          formatted_date: formattedDate,
          plot_name: selectedPlot ? selectedPlot.name : "",
          formatted_level: addIconToLevelField(),
        };
      });
      setTableData(combinedData);
      setPlots(plotsData);
    });

  useEffect(() => {
    setPaginationParameters({ limit: ADMIN_TABLE_ROWS_WARNINGS, offset: ADMIN_TABLE_ROWS_WARNINGS * (page - 1) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
      getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParameters, intl]);

  useEffect(() => {
    setTotalRows(totalRows);
  }, [totalRows]);

  const handleChangePage = (value) => {
    const newPage = value ? page + 1 : page - 1;
    setPage(newPage);
  };

  return (
    <AdministrationPageComponent
      title={WARNINGS_MANAGEMENT__TITLE}
      columns={columns}
      rows={tableData}
      rowsToShow={ADMIN_TABLE_ROWS_WARNINGS}
      totalRows={totalRows}
      buttonText={CREATE_WARNING_FORM__TITLE}
      modalComponent={
        <CreateWarningFormComponent
          plots={plots}
          plot={plot}
          handleSetPlot={handleSetPlot}
          name={name}
          handleSetName={handleSetName}
          description={description}
          handleSetDescription={handleSetDescription}
          level={level}
          handleSetLevel={handleSetLevel}
          createWarning={createWarning}
          handleCreateWarning={createWarning ? handleCreateWarning : handleUpdateWarning}
          displayError={displayError}
          errorMessage={errorMessage}
        />
      }
      displayCreateModal={displayCreateModal}
      handleOpenCreateModal={handleOpenCreateModal}
      handleEdit={handleEditWarning}
      handleDelete={handleDeleteWarning}
      createTitle={CREATE_WARNING_FORM__TITLE}
      editTitle={EDIT_WARNING_FORM__TITLE}
      deleteTitle={COMMON__WARNING}
      handleChangePage={handleChangePage}
      handleOrderParameters={handleOrderParameters}
    />
  );
};

export default withAuthorization(ROLES_GENERAL_ADMIN)(ManageWarningsComponent);
