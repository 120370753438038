import {
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_HIGH,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_LOW,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_MEDIUM,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_NONE,
  PROTECTION_HIGH,
  PROTECTION_LOW,
  PROTECTION_MEDIUM,
  WARNINGS__RISK_LEVEL_HIGH,
  WARNINGS__RISK_LEVEL_LOW,
  WARNINGS__RISK_LEVEL_MEDIUM,
} from "../../translations/constants";

export const listDataWeather = [
  {
    id: 1,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 2,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 3,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 4,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
  {
    id: 5,
    date: "01/01/23",
    icon: "sun",
    degrees: "14º",
    wind: "N 3m/s",
  },
];
export const HIGH = "HIGH";
export const MEDIUM = "MEDIUM";
export const LOW = "LOW";
export const NONE = "NONE";

export const HIGH_VALUE = 0;
export const MEDIUM_VALUE = 0;
export const LOW_VALUE = 0;
export const NONE_VALUE = 0;

export const SIZE_BY_STATUS = {
  [HIGH]: HIGH_VALUE,
  [MEDIUM]: MEDIUM_VALUE,
  [LOW]: LOW_VALUE,
  [NONE]: NONE_VALUE,
};

export const PLAGUE_RISKS = [HIGH, MEDIUM, LOW, NONE];
export const PLAGUE_RISKS_NAMES = [
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_HIGH,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_MEDIUM,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_LOW,
  PARCELS_LIST__MAP_LEGEND_RISK_LEVEL_NONE,
];

export const COLOR_RISKS = {
  [HIGH]: {
    color: "#ff001b",
    borderColor: "rgba(255, 0, 27, 0.5)",
  },
  [MEDIUM]: {
    color: "#ff8000",
    borderColor: "rgba(255, 128, 0, 0.5)",
  },
  [LOW]: {
    color: "#fcdb03",
    borderColor: "rgba(252, 219, 3, 0.5)",
  },
  [NONE]: {
    color: "#e8e3d7",
    borderColor: "rgba(140, 138, 133, 0.5)",
  },
  undefined: {
    color: "#689F38",
    borderColor: "rgba(104, 159, 56, 0.5)",
  },
};

export const RISK_PROTECTION_TABLE = {
  riskLevels: [
    { name: HIGH, value: WARNINGS__RISK_LEVEL_HIGH },
    { name: MEDIUM, value: WARNINGS__RISK_LEVEL_MEDIUM },
    { name: LOW, value: WARNINGS__RISK_LEVEL_LOW },
  ],
  protectionLevels: [
    { name: HIGH, value: PROTECTION_HIGH, size: HIGH_VALUE },
    { name: MEDIUM, value: PROTECTION_MEDIUM, size: MEDIUM_VALUE },
    { name: LOW, value: PROTECTION_LOW, size: LOW_VALUE },
  ],
};
